import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './components/others/AuthContext';

import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
  <AuthProvider>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </AuthProvider>,
);

reportWebVitals();
