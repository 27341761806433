import React from "react";
import HeaderTop from "./Headers/HeaderTop";
import HeaderBottom from "./Headers/HeaderBottom";
 

const Header = () => {
    return (
        <>
        <HeaderTop />
        <HeaderBottom />
        </>
    );
}

export default Header;
