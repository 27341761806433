import React, { useState, useEffect } from "react";

import { SplitUrl } from "../utils/othersFunction.js";

import Layout from "./Layout";
import PageWrapper from "../components/PageWrapper/SousRubriquesWrapper/PageWrapper";

export const Erreur = () => {
    return (
        <div className="Page z-1">
            <div className="Page_main md:max-w-[720px] mml:max-w-[991px] px-0 mx-auto mt-[2rem] bg-white">
                <div className="Page_main_content">
                    <div className="Page_main_content_title">
                        <h1>Erreur</h1>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const SousRubriqueViews = () => {

    return (
        <Layout>
        <div className="Page z-1">
          <div className="Page_main md:max-w-[720px] mml:max-w-[991px] px-0 mx-auto bg-white">
            <PageWrapper />
          </div>
        </div>
        </Layout>
    );
};

export const SousRubriquesList = () => {
    return  (
        <div className="Page z-1">
            <div className="Page_main md:max-w-[720px] mml:max-w-[991px] px-0 mx-auto mt-[2rem] bg-white">
                <div className="Page_main_content">
                    <div className="Page_main_content_title">
                        <h1>Sous Rubriques List</h1>  
                    </div>
                </div>
            </div>
        </div>
    );
};