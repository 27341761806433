import React, { useEffect, useState } from "react";
import { getAllWithCondition } from "../../../controllers/AllWithCondition";

const PageHead = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [updatedRubriques, setUpdatedRubriques] = useState([]);
    const [updatedSousRubriques, setUpdatedSousRubriques] = useState([]);

    useEffect(() => {
        // Recuperer l'url de la page
        const currentUrl = window.location.href;
        let name = null;

        // Split l'url pour récupérer le slug ou le nom de la page
        const splitUrl = currentUrl.split("/");
        if (splitUrl.length > 0) {
            name = splitUrl[splitUrl.length - 1];
        }
        if (name === null || name === "") {
            window.location.href = "/notfound";
        }

        const rubriqueOptions = {
            condition: { slug: name, is_active: true },
            projection: { name: 1, description: 1, slug: 1, is_active: 1 },
            populate: { path: 'id_file', select: { name: 1, url: 1 } },
            limit: 10,    
            withSousRubriques: 'true',         
          };
          
          const sousRubriqueOptions = {
            projection: { name: 1, description: 1, slug: 1, id_parent_categories: 1, is_active: 1 },
            populate: { path: 'id_file', select: { name: 1, url: 1 } },
            limit: 10,
          };
          
          const articleOptions = 'null'; 

          const fileOptions = "null"
 
        let formData = new FormData();
        formData.append('rubriqueOptions', JSON.stringify(rubriqueOptions));
        formData.append('sousRubriqueOptions', JSON.stringify(sousRubriqueOptions));
        formData.append('articleOptions', JSON.stringify(articleOptions));
        formData.append('fileOptions', JSON.stringify(fileOptions));

        getAllWithCondition(formData)
        .then((data) => {
            if (data !== null && data !== undefined && data !== {}) {
                setUpdatedRubriques(data.rubriques);
                setUpdatedSousRubriques(data.sousRubriques);
                setIsLoading(true);
            } 
        })


    }, [])

    return (
        isLoading ? (
            <>
                <div className="PW_head pb-8 pt-4 mb-8 border-b-4 border-solid border-gray-400">
                    <h2 className="Rubrique_title text-[2rem] sm:text-[4rem] uppercase tracking-tighter font-semibold">
                        {updatedRubriques.length > 0 ? updatedRubriques[0].name : ""}
                    </h2>
                    <div className="Sousrubrique mt-9">
                        <div className="SR_lists block overflow-x-auto">
                            {updatedSousRubriques && updatedSousRubriques.length > 0 ? (
                                <>
                                    {updatedSousRubriques.map((sousrubrique) => (
                                        <>
                                            <a href={`/sousrubriques/${sousrubrique.slug}`} className="bea inline-flex text-center border-[0.1rem] border-solid border-gray-400 text-gray-400 text-[1.2rem] justify-center items-center py-4 px-3 tracking-widest rounded uppercase mx-2 hover:bg-gray-400 hover:border-gray-800 hover:text-gray-200">
                                                {sousrubrique.name}
                                            </a>
                                        </>
                                    ))}
                                </>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                </div>
            </>
        ) : (
            <>
            </>
        )
    );
};

export default PageHead;
