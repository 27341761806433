import React, { useEffect, useRef, useState } from 'react';


import { CardsHeader } from '../cards/cards'
import { getAllWithCondition } from '../../controllers/AllWithCondition.js';

const othersFunction =  require('../../utils/othersFunction');

const HeaderBottom = () => {
    const [isLoading, setIsLoading] = useState(true);

    const [updatedRubriques, setUpdatedRubriques] = useState([]);
    const [updatedSousRubriques, setUpdatedSousRubriques] = useState([]);
    const [updatedarticles, setUpdatedArticles] = useState([]);

    const headerRef = useRef(null)


    useEffect(() => {
        const handleScroll = () => {
        const headerNav = headerRef.current;

        if (window.scrollY > 300 && window.innerWidth > 1025) {
            headerNav.classList.add('Scroll');
        } else if (window.scrollY < 400) {
            headerNav.classList.remove('Scroll');
        }
        };


        const handleDocumentClick = (event) => {
            const target = event.target;
            const isOverlay = target.classList.contains("overlay");
    
            if (isOverlay) {
            const subVisible = document.querySelectorAll(".Rubrique .Rubrique_sub:not(.hidden)")[0];
            const btnVisible = document.querySelectorAll(".Rubrique .Btn_dropdown.Active")[0];
            document.body.classList.remove("overflow-hidden");
            othersFunction.toggleElement(".overlay");
            othersFunction.toggleElement(subVisible);
            othersFunction.toggleClass(btnVisible, "Active");
            } else if (target.classList.contains("Btn_dropdown")) {
            const divsVisible = document.querySelectorAll(".Rubrique .Rubrique_sub:not(.hidden)")[0];
            const parentDiv = target.parentNode;
            const divToToggle = parentDiv.querySelector(".Rubrique_sub");
    
            if (divsVisible) {
                const parentDivVisible = divsVisible.parentNode;
                if (divToToggle.isEqualNode(divsVisible)) {
                    othersFunction.toggleClass(target, "Active");
                document.body.classList.remove("overflow-hidden");
                othersFunction.toggleElement(".overlay");
                othersFunction.toggleElement(divToToggle);
                } else {
                document.body.classList.add("overflow-hidden");
                othersFunction.toggleElement(divsVisible);
                othersFunction.toggleClass(parentDivVisible.querySelector(".Btn_dropdown"), "Active");
                othersFunction.toggleElement(divToToggle);
                othersFunction.toggleClass(target, "Active");
                }
            } else {
                othersFunction.toggleClass(target, "Active");
                document.body.classList.add("overflow-hidden");
                othersFunction.toggleElement(".overlay");
                othersFunction.toggleElement(parentDiv.querySelector(".Rubrique_sub"));
            }
            }
        };
    
        document.addEventListener('click', handleDocumentClick);
        window.addEventListener('scroll', handleScroll);

        return () => {
        document.removeEventListener('click', handleDocumentClick);
        window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {

        const rubriqueOptions = {
            projection: { name: 1, description: 1, slug: 1, is_active: 1 },
            populate: { path: 'id_file', select: { name: 1, url: 1 } },
            limit: 10,    
            withSousRubriques: 'true',         
          };
          
          const sousRubriqueOptions = {
            projection: { name: 1, description: 1, slug: 1, id_parent_categories: 1, is_active: 1 },
            populate: { path: 'id_file', select: { name: 1, url: 1 } },
            limit: 10,
          };
          
          const articleOptions = { 
            populate: { path: 'created_by', select: { username: 1, email: 1 } },
            limit: 5,
            skip: 0,
            projection: { title: 1, content: 1, slug: 1, created_at: 1, updated_at: 1, is_active: 1 },
            sort: { created_at: -1 },
          }; 

          const fileOptions = "null"
 
        let formData = new FormData();
        formData.append('rubriqueOptions', JSON.stringify(rubriqueOptions));
        formData.append('sousRubriqueOptions', JSON.stringify(sousRubriqueOptions));
        formData.append('articleOptions', JSON.stringify(articleOptions));
        formData.append('fileOptions', JSON.stringify(fileOptions));

        getAllWithCondition(formData)
        .then((data) => {
            if (data) {
                setUpdatedRubriques(data.rubriques);
                setUpdatedSousRubriques(data.sousRubriques);
                setUpdatedArticles(data.articles);
                setIsLoading(false);
            }
        })


    }, [])

  return ( 
    <>
        <div className="overlay hidden"></div>

        <div ref={headerRef} className="Header_nav w-full pt-[6rem] lg:p-0">
            <div className="Nav_wrapper bg-white border-t-[0.4rem] border-solid border-yellow-500 pt-0 shadow-sh h-[5.8rem] w-full text-black relative z-6">
                <div className="Nav_desk static overflow-x-hidden overflow-y-hidden py-0 flex h-full max-w-[115.2rem] w-full my-0 mx-auto">
                    <div className="Nav_rubriques flex justify-start items-center self-start overflow-y-hidden overflow-x-auto border-b-0 static w-full h-full m-0">
                        <div className="Home Rubrique relative bg-none">
                            <a href="/" className="w-full h-full">
                                <button className="p-[1.6rem] relative no-underline transition-all hover:bg-slate-50">
                                    <span className="w-[2.4rem] h-[2.4rem] bg-contain bg-center bg-no-repeat inline-block shrink-0"><i className="fa-sharp fa-solid fa-house"></i></span>
                                    <span className="hidden text-[1.6rem] leading-1.88">A la une</span>
                                    <span className="only self-center border-0 h-[1px] w-[1px] p-0 hidden whitespace-nowrap">back_home</span>
                                </button>
                            </a>
                        </div>

                        {isLoading ? (
                            <div className="Rubrique">
                                <p> ACTUALITES </p>
                            </div>
                        ) : (
                         <>
                            {updatedRubriques && updatedRubriques.length > 0 ? (
                                <>
                                    {updatedRubriques.map((updatedRubrique, index) => (
                                        <>
                                            {updatedRubrique.is_active ? (
                                                <div key={index} className="Rubrique">
                                                    <button className="Btn_dropdown text-[1.2rem] lg:text-[1.6rem] transition-all hover:bg-slate-100">{updatedRubrique.name}</button>
                                                    <div className="Rubrique_sub hidden">
                                                        <div className="Sub_wrapper">
                                                            <div className="Wrapper_list_link w-full sm:w-[21rem] pr-0 block">
                                                                <a href={`/rubriques/${updatedRubrique.slug}`} className="Link_princ uppercase text-[2.2rem]">{updatedRubrique.name}</a>
                                                                <ul className="block list-none m-0 p-0">
                                                                    {updatedSousRubriques && updatedSousRubriques.length > 0 && updatedRubrique.is_active ? (
                                                                        <>
                                                                            {updatedSousRubriques.map((updatedSousRubrique, index) => (
                                                                                <>
                                                                                    <div key={index}>
                                                                                        {updatedSousRubrique.id_parent_categories.includes(updatedRubrique._id) && updatedSousRubrique.is_active ? (
                                                                                            <li className="border-t border-solid border-gray-300 max-w-full sm:max-w-[27rem] w-auto">
                                                                                                <a href={`/sousrubriques/${updatedSousRubrique.slug}`} className="Link_item">{updatedSousRubrique.name}</a>
                                                                                            </li>
                                                                                        ) : (
                                                                                            <></>
                                                                                        )}
                                                                                    </div>
                                                                                </>
                                                                            ))}
                                                                        </>
                                                                    ) : (
                                                                        <></>
                                                                    )}
                                                                    
                                                                </ul>
                                                            </div>
                                                            <div className="Wrapper_container w-full hidden sm:block">
                                                                <div className="Container_wrapper flex flex-wrap justify-between content-center pl-[6.4rem]">                                            
                                                                    {updatedarticles && updatedarticles.length > 0 ? (
                                                                        <>
                                                                            {updatedarticles.map((updatedarticle, index) => (
                                                                                <>
                                                                                    {updatedarticle.id_categories.includes(updatedRubrique._id) && updatedarticle.is_active ? (
                                                                                        <>
                                                                                            <CardsHeader data={updatedarticle} />
                                                                                        </>
                                                                                    ) : (
                                                                                        <></>
                                                                                    )}
                                                                                </>
                                                                            ))}
                                                                        </>
                                                                    ) : (
                                                                        <div>
                                                                            <p> * * * </p>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <>
                                                </>
                                            )}
                                        </>
                                    ))}
                                </>
                                    ) : (
                                <div>
                                    <span> * * * </span>
                                </div>
                            )}

                         </>   
                        
                        )}

                    
                    </div>
                </div>
                <div className="Nav_mobile hidden bg-black h-10"></div>
            </div>
        </div>
    </>
  );
};

export default HeaderBottom;
