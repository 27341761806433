import React from 'react';
import { Route, Routes } from 'react-router-dom';

import AdminHome  from '../admin/adminHome';

import AdminArticles from '../admin/Articles/adminArticles';
import { ArticleEdit,  ArticleCreate } from '../admin/Articles/articlesEdit';


import AdminAllRubriques from '../admin/Rubriques/adminRubriques';

import RubriquesList from '../admin/Rubriques/RubriquesList';
import { RubriqueEdit, RubriqueCreate } from '../admin/Rubriques/RubriquesEdit';

import SousRubriquesList from '../admin/Rubriques/SousRubriquesList';
import { SousRubriqueEdit, SousRubriqueCreate } from '../admin/Rubriques/SousRubriquesEdit';

import AdminersList from '../admin/Adminers/AdminersList';
import { AdminerCreate, AdminerEdit } from '../admin/Adminers/AdminerEdit';




const AdminHomeRoute = () => {
    
    return (
        <Routes>
            <Route exact path="/*" element={<AdminHome />} />

            <Route exact path="/projects/list/*" element={<AdminArticles />} />
            <Route exact path="/projects/edit/*" element={<ArticleEdit />} />
            <Route exact path="/projects/create/*" element={<ArticleCreate />} />


            <Route exact path="/all_rubriques/list/*" element={<AdminAllRubriques />} />
            <Route exact path="/rubriques/list/*" element={<RubriquesList />} />
            <Route exact path="/sous_rubriques/list/*" element={<SousRubriquesList />} />
            <Route exact path="/team/list/*" element={<AdminersList />} />


            <Route exact path="/rubriques/edit/*" element={<RubriqueEdit />} />
            <Route exact path="/sous_rubriques/edit/*" element={<SousRubriqueEdit />} />
            <Route exact path="/team/edit/*" element={<AdminerEdit />} />

            <Route exact path="/rubriques/create/*" element={<RubriqueCreate />} />
            <Route exact path="/sous_rubriques/create/*" element={<SousRubriqueCreate />} />
            <Route exact path="/team/create/*" element={<AdminerCreate />} />

        </Routes>
    );
}

export default AdminHomeRoute;
