
import React, { useEffect, useState } from "react";

import { applyStyle } from "./styleCss";


export const popUp = (title, message, type, valeur, duration = 100000) => {
    const popup = document.createElement('div');
    popup.classList.add('popup');
    popup.classList.add(type);
  
    const popupContent = document.createElement('div');
    popupContent.classList.add('popup-content');
  
    const closeButton = document.createElement('button');
    closeButton.textContent = 'Fermer';
    closeButton.addEventListener('click', () => {
        document.body.removeChild(popup);
        document.body.removeChild(overlay);
    });

    const overlay = document.createElement('div');
    overlay.classList.add('overlay');
    overlay.addEventListener('click', () => {
        document.body.removeChild(popup);
        document.body.removeChild(overlay);
    });
  
    const titleElement = document.createElement('h3');
    titleElement.textContent = title;
  
    const messageElement = document.createElement('p');
    messageElement.textContent = message;
  
    popupContent.appendChild(closeButton);
    popupContent.appendChild(titleElement);
    popupContent.appendChild(messageElement);

    console.log("popupContent");

    popup.appendChild(popupContent);
    document.body.appendChild(popup);
  
    setTimeout(() => {
      document.body.removeChild(popup);
    }, duration);
};

export const Popup = (title, message, formHandle, valeur, place = "body", duration = 3000) => {
  const popupDiv = document.createElement('div');
  popupDiv.className = 'fixed inset-0 w-screen h-screen flex items-center justify-center z-[1000]';
  
  const backgroundDiv = document.createElement('div');
  backgroundDiv.className = 'fixed inset-0 bottom-0 bg-gray-500 bg-opacity-75 transition-opacity';
  
  const contentDiv = document.createElement('div');
  contentDiv.className = 'relative w-[50%] h-[40%] flex flex-col justify-center items-center bg-white rounded-lg p-6 transform transition-all shadow-lg';
  
  const messageParagraph = document.createElement('p');
  messageParagraph.className = 'text-center text-2xl my-4';
  messageParagraph.textContent = message;
  
  const optionsList = document.createElement('ul');
  optionsList.className = 'flex justify-center mt-4';
  
  const yesOption = document.createElement('li');
  yesOption.className = 'mr-2';
  const yesLink = document.createElement('a');
  yesLink.href = '#0';
  yesLink.className = 'bg-red-500 hover:bg-red-600 text-white  px-6 py-3 mx-4 rounded';
  yesLink.textContent = 'Yes';
  yesOption.appendChild(yesLink);
  optionsList.appendChild(yesOption);
  
  const noOption = document.createElement('li');
  const noLink = document.createElement('a');
  noLink.href = '#0';
  noLink.className = 'bg-gray-500 hover:bg-gray-600 text-white px-6 py-3 mt-2 mx-4 rounded';
  noLink.textContent = 'No';
  noOption.appendChild(noLink);
  optionsList.appendChild(noOption);
  
  const closeLink = document.createElement('a');
  closeLink.href = '#0';
  closeLink.className = 'cd-popup-close img-replace absolute top-5 right-5 flex justify-center items-center w-8 h-8 text-4xl text-gray-500 hover:text-red-500';
  closeLink.textContent = '×';
  
  contentDiv.appendChild(messageParagraph);
  contentDiv.appendChild(optionsList);
  contentDiv.appendChild(closeLink);
  
  popupDiv.appendChild(backgroundDiv);
  popupDiv.appendChild(contentDiv);

  document.querySelector("body").appendChild(popupDiv);

  setTimeout(() => {
    // Si l'utilisateur ne répond pas, fermer le popup et popupDiv est fils de body
    if (document.querySelector("body").contains(popupDiv)) {
      document.querySelector("body").removeChild(popupDiv);
    }
  }
  , duration);

  closeLink.addEventListener('click', () => {
    document.querySelector("body").removeChild(popupDiv);
  });

  noLink.addEventListener('click', () => {
    document.querySelector("body").removeChild(popupDiv);
  }
  , false);

  // Exécuter la fonction de callback si l'utilisateur clique sur le bouton "Oui"
  yesLink.addEventListener('click', () => {
    if(valeur){
      formHandle(valeur);
    }else{
      formHandle();
    }
    document.querySelector("body").removeChild(popupDiv);
  }
  , false);
    
};
  
export const Popup_bea = (titre, message, type, place = "body", timeOn = 3000) => {
  const preDefType = {
    success: {
      bg: 'bg-green-400',
      border: 'border-green-300',
      text: 'text-green-300',
      textBtn: 'text-green-500',
      icon: 'fa-regular fa-badge-check fa-bounce',
    },
    info: {
      bg: 'bg-blue-400',
      border: 'border-blue-300',
      text: 'text-blue-300',
      textBtn: 'text-blue-500',
      icon: 'fa-sharp fa-regular fa-circle-info fa-shake',
    },
    warning: {
      bg: 'bg-yellow-400',
      border: 'border-yellow-300',
      text: 'text-yellow-300',
      textBtn: 'text-yellow-500',
      icon: 'fa-regular fa-triangle-exclamation fa-beat',
    },
    danger: {
      bg: 'bg-red-400',
      border: 'border-red-300',
      text: 'text-red-300',
      textBtn: 'text-red-500',
      icon: 'fa-regular fa-circle-xmark fa-beat-fade',
    },
    primary: {
      bg: 'bg-indigo-400',
      border: 'border-indigo-300',
      text: 'text-indigo-300',
      textBtn: 'text-indigo-500',
      icon: 'fa-light fa-thumbs-up fa-bounce',
    },
  } 

  // Vérifier que le type est valide
  if (!preDefType[type]) {
    const { bg, border, text, textBtn, icon } = preDefType['info']; 
  } 

  // Récupérer les classes CSS correspondantes au type de popup
  const { bg, border, text, textBtn, icon } = preDefType[type];

  // Afficher le popup dans l'application
  const popupDiv = document.createElement('div');
  popupDiv.classList.add('Alert_Block', 'fixed', 'top-20', 'z-[100]');

  const popupContent = document.createElement('div');
  popupContent.classList.add('bea', 'alert', 'fade', 'alert-dismissible', 'text-left', bg, 'bg-opacity-5', text, border, 'shadow-lg', 'rounded', 'hover:bg-opacity-20', 'cursor-pointer');
  
  popupContent.style.width = '60%';
  // Le div doit être centré
  popupContent.style.left = '20%';
  // Le div doit être centré
  popupContent.style.right = '20%';

  const closeButton = document.createElement('button');
  closeButton.type = 'button';
  closeButton.classList.add('close', 'absolute', 'top-0', 'right-0', 'p-2', 'm-0', 'float-right', 'font-bold', 'text-2xl', textBtn);
  closeButton.dataset.dismiss = 'alert';
  
  const closeIcon = document.createElement('i');
  closeIcon.className = 'fa fa-times greencross';
  
  const popupIcon = document.createElement('i');
  popupIcon.className = `start-icon ${icon}`;
  
  const popupTitle = document.createElement('strong');
  popupTitle.className = 'font-semibold';
  popupTitle.textContent = titre;
  
  const popupMessage = document.createTextNode(` ${message}`);

  closeButton.appendChild(closeIcon);
  popupContent.appendChild(closeButton);
  popupContent.appendChild(popupIcon);
  popupContent.appendChild(popupTitle);
  popupContent.appendChild(popupMessage);
  popupDiv.appendChild(popupContent);
  
  document.querySelector(place).appendChild(popupDiv);

  // Fermer le popup après un certain temps
  setTimeout(() => {
    popupDiv.remove();
  }
  , timeOn);

  // Fermer le popup au clic sur la croix
  closeButton.addEventListener('click', () => {
    popupDiv.remove();
  });
};



