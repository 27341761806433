import React from "react";
import Layout from "./Layout";

import Spotlight from "../components/Spotlight";
import PageWrapper from "../components/PageWrapper/HomeWrapper/PageWrapper";

/**
 *
 * ? 1. Home Page
 * ! 2. Header, Layout, Footer
 * * 3. Afficher la liste des articles
 * TODO 4.
 */

const Home = () => {

  return (
    <Layout>
    <div className="Page z-1">
      <div className="Page_main md:max-w-[720px] mml:max-w-[991px] px-0 mx-auto mt-[2rem] bg-white">
        <Spotlight />
        <PageWrapper />
      </div>
    </div>
    </Layout>
  );
};

export default Home;
