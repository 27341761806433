import React, { useState, useEffect, useRef } from "react";

import Layout from "../Layout";
import { CardsAdminArticle } from "../../components/cards/cards";
import { addCustomStyleSheet } from '../../utils/othersFunction';
import { getAllArticles } from '../../controllers/articleControllers';

const AdminArticles = () => {
  const [articles, setArticles] = useState([]);
  const [isLoadingArticle, setIsLoadingArticle] = useState(true);
  const [loading, setLoading] = useState(false);
  const isLoadingMoreRef = useRef(false);

  useEffect(() => {
    addCustomStyleSheet('../../admin.css');
    setLoading(true);
  }, []);
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        isLoadingMoreRef.current = true; // Le chargement est en cours
        getAllArticles().then((data) => {
          if(data && data.length > 0){
            // Renverser l'ordre des articles
            data.reverse();
            setArticles(data);
            isLoadingMoreRef.current = false;          }
          });
          setIsLoadingArticle(false);
      } catch (error) {
        isLoadingMoreRef.current = false;
        setIsLoadingArticle(false);
        console.log('Une erreur s\'est produite lors de la récupération :', error);
      }
    };

    fetchData();

  }, [loading]);


  return (
    <Layout>
      <div className="absolute right-0 pt-[100px] pb-[50px] px-4 max-w-[calc(100%-260px)] w-full z-10">
        <div className="Articles mt-10">
          <h4 className="border-b-2 border-gray-500 pb-4 mb-8 text-2xl font-semibold">Articles</h4>
          {isLoadingArticle ? (
            <p className="text-[22px] px-4 py-8">Loading...</p>
          ) : (
            <>
              {articles && articles.length > 0 ? (
                <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-8">
                  {articles.map((article) => (
                    <div key={article._id}>
                      <CardsAdminArticle data={article} />
                    </div>
                  ))}
                </div>
              ) : (
                <p className="text-[22px] px-4 py-8">No articles found.</p>
              )}
            </>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default AdminArticles;
