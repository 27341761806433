import React, { useState, useEffect, useRef } from "react";

import Layout from "../Layout";
import { CardsAdminer } from "../../components/cards/cards";
import { addCustomStyleSheet } from '../../utils/othersFunction';
import { deleteAdminer, getAllAdminers, togleActiveAdminer } from '../../controllers/adminerControllers';
import { Popup, Popup_bea } from "../../components/others/popUp";

const AdminersList = () => {
  const [adminers, setAdminers] = useState([]);
  const [isLoadingAdminer, setIsLoadingAdminer] = useState(true);
  const [loading, setLoading] = useState(false);
  const isLoadingMoreRef = useRef(false);


  useEffect(() => {
    addCustomStyleSheet('../../admin.css');
    addCustomStyleSheet('../../admin2.css');
    setLoading(true);
  }, []);
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        isLoadingMoreRef.current = true; // Le chargement est en cours
        const adminers = await getAllAdminers();
        setAdminers(adminers)
        setIsLoadingAdminer(false);
      } catch (error) {
        console.log('Une erreur s\'est produite lors de la récupération :', error);
        setIsLoadingAdminer(false);
      }
    };

    fetchData();
  }, [loading]);

  const HandleOnDelete = (e) => {
    // Avoir l'id du composant à modifier
    const idActive = e.target.id;

    Popup( "Supprimer", "Voulez-vous vraiment supprimer l'admin ?", HandleYesDelete, idActive);
  };

  const HandleOnActive =  (e) => {
    // Avoir l'id du composant à modifier
    const idActive = e.target.id;

    Popup( "Supprimer", "Voulez-vous vraiment changer l'etat de l'admin ?", HandleYesActive, idActive);
  };

  const HandleYesDelete = async (id) => {
    // Supprimer le composant de la liste admniners
    try{
      // Supprimer le composant
      deleteAdminer(id).then((data) => {

        // Mettre à jour la liste des composants    
        if(data.error || data.message) {
          console.log('Erreur lors de la suppression de l\'admine');
          Popup_bea("Erreur [!]", "Erreur lors de la suppression de l'admine", "danger");
          window.location.href = "/admin/team/list";
        } 
        setLoading(!loading);
        Popup_bea("Succès [!]", "L'admin a été supprimé avec succès", "success");
      });

    }catch (error){
      console.log('Erreur lors de la suppression de l\'admine :', error);
    }
  }

  const HandleYesActive = async (id) => {
    // Supprimer le composant de la liste admniners
    try{
      // Desactiver le composant
      togleActiveAdminer(id).then((data) => {
        if(data.success) {
          setLoading(!loading);
          Popup_bea("Succès", "L'administration avec succès", "success");
        } else if (data.error) {
          Popup_bea("Erreur", "Erreur de changement d'etat ", "danger");
        }
      });
      

    }catch (error){
      console.log('Erreur :', error);
    }
  }

  return (
    <Layout>
      <div className="absolute right-0 pt-[100px] pb-[50px] px-4 max-w-[calc(100%-260px)] w-full z-10">
        <div className="Adminers mt-10">
          <h4 className="border-b-2 border-gray-500 pb-4 mb-8 text-2xl font-semibold">Adminers</h4>
          {isLoadingAdminer ? (
            <p className="text-[22px] px-4 py-8">Loading...</p>
          ) : (
            <>
              {adminers && adminers.length > 0 ? (
                <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-8">
                  {adminers.map((adminer) => (
                    <div key={adminer._id}>
                      <CardsAdminer data={adminer} onActive={HandleOnActive} onDelete={HandleOnDelete} />
                    </div>
                  ))}
                </div>
              ) : (
                <p className="text-[22px] px-4 py-8">No adminers found.</p>
              )}
            </>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default AdminersList;
