import React, { useState, useEffect, useRef } from "react";
import Layout from "../Layout";
import { Popup_bea } from "../../components/others/popUp";
import { addCustomStyleSheet } from '../../utils/othersFunction';
import { CardsAdminRubrique } from "../../components/cards/cards";
import { getAllRubriques } from '../../controllers/rubriqueControllers';

const AdminAllRubriques = () => {
  const [rubriques, setRubriques] = useState([]);
  const [isLoadingRubrique, setIsLoadingRubrique] = useState(true);
  const [loading, setLoading] = useState(false);
  const isLoadingMoreRef = useRef(false);

  useEffect(() => {
    addCustomStyleSheet('../../admin.css');
    addCustomStyleSheet('../../admin2.css');
    setLoading(true);
  }, []);
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        isLoadingMoreRef.current = true; // Le chargement est en cours
        const rubriques = await getAllRubriques();
        if (rubriques && rubriques.length > 0) {
            setRubriques(rubriques)  
        } else {
            Popup_bea("Rubriques [!]", "Erreur lors de la récupération des rubriques");
        } 
        setIsLoadingRubrique(false);
      } catch (error) {
        console.log('Une erreur s\'est produite lors de la récupération :', error);
        setIsLoadingRubrique(false);
      }
    };

    fetchData();
  }, [loading]);


  return (
    <Layout>
      <div className="absolute right-0 pt-[100px] pb-[50px] px-4 max-w-[calc(100%-260px)] w-full z-10">
        <div className="Rubriques mt-10">
          <h4 className="border-b-2 border-gray-500 pb-4 mb-8 text-2xl font-semibold">
            <a href="/admin/rubriques/list" className="hover:">Rubriques</a>  and <a href="/admin/sous_rubriques/">Sous Rubriques</a> </h4>
          {isLoadingRubrique ? (
            <p className="text-[22px] px-4 py-8">Loading...</p>
          ) : (
            <>
              {rubriques && rubriques.length > 0 ? (
                <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-8">
                  {rubriques.map((rubrique) => (
                    <div key={rubrique.id}>
                      <CardsAdminRubrique data={rubrique} />
                    </div>
                  ))}
                </div>
              ) : (
                <p className="text-[22px] px-4 py-8">No rubriques found.</p>
              )}
            </>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default AdminAllRubriques;
