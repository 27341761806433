import React, { useEffect, useState } from "react";
import { getAllWithCondition } from "../controllers/AllWithCondition";

const Footer = () => {
    const [rubriques, setRubriques] = useState([]);

    useEffect(() => {
        const rubriqueOptions = 'null'
        const articleOptions = 'null'
        const fileOptions = 'null'

        const sousRubriqueOptions = {
            condition: { is_active: true},
            populate: { path: 'id_file', select: { title: 1, created_at: 1, updated_at: 1, is_active: 1, is_top: 1, is_public: 1, entity: 1, id_entity: 1 } },
            limit: 5,
            skip: 0,
            projection: { name: 1, description: 1, slug: 1, created_at: 1, updated_at: 1, is_active: 1 },
            sort: { created_at: -1 },
        };
    
        let formData = new FormData();
        formData.append('rubriqueOptions', JSON.stringify(rubriqueOptions));
        formData.append('sousRubriqueOptions', JSON.stringify(sousRubriqueOptions));
        formData.append('articleOptions', JSON.stringify(articleOptions));
        formData.append('fileOptions', JSON.stringify(fileOptions));
    
        getAllWithCondition(formData)
            .then((data) => {
                if (data) {
                    setRubriques(data.sousRubriques);
                }
            })
    }, []);

    
    return (
        <footer className="lg:px-8 relative z-4 bg-[#3d3935] text-gray-300 py-12 px-6">
        <section className="my-0 m-auto max-w-[100rem] block">
            <div className="Footer_head flex justify-stretch flex-col md:flex-row">
                <div className="Head_section flex flex-wrap flex-col max-h-[40rem] min-w-[50%]">
                    <div className="Section_services w-1/2 pb-10">
                        <h3 className="Section_title pb-3 font-medium uppercase text[1.4rem] tracking-normal">Service la Ronde</h3>
                        <ul className="Section_links block list-none text-xl overflow-hidden relative pl-0 m-0">
                            <li className="Section_link leading-8 relative py-2">* INFORMER LE MONDE</li>
                            <li className="Section_link leading-8 relative py-2">* GESTION DES RESSOURCES</li>
                            <li className="Section_link leading-8 relative py-2">* GESTION INFORMATIQUE</li>
                        </ul>
                    </div>
                    <div className="Section_services w-1/2 pb-10">
                        <h3 className="Section_title pb-3 font-medium uppercase text[1.4rem] tracking-normal">Service des Partenaire</h3>
                        <ul className="Section_links block list-none text-xl overflow-hidden relative pl-0 m-0">
                            {/* <li className="Section_link leading-8 relative">ARUPE</li> */}
                            <li className="Section_link leading-8 relative">LA RONDE !</li>
                        </ul>
                    </div>
                    <div className="Section_services w-1/2 pb-10">
                        <h3 className="Section_title pb-3 font-medium uppercase text[1.4rem] tracking-normal">Rubriques</h3>
                        <ul className="Section_links block list-none text-xl overflow-hidden relative pl-0 m-0">
                            {rubriques && rubriques.length > 0 ? (
                                rubriques.map((rubrique, index) => (
                                    <li key={index} className="bea Section_link leading-8 relative text-gray-200 hover:text-white py-2 hover:underline">
                                        <a href={`/rubriques/${rubrique.slug}`}>{rubrique.name}</a>
                                    </li>
                                ))
                            ) : (
                                <> * * * </>
                            )}
                        </ul>
                    </div>
                </div>
                <div className="Section_Laronde text-white text-xl ">
                    <div className="Ft_part px-0 pb-10 m-0 ">
                        <p>Newsletters de La ronde</p>
                        <a href="/" className="mt-6 inline-flex w-full items-end leading-5 no-underline">
                            <span>
                                <i className="fa-sharp fa-regular fa-envelope"></i>
                                Recevoir les news du Monde Entier
                            </span>
                        </a>
                    </div>
                    <div className="Ft_part px-0 py-10 m-0 border-t border-solid border-gray-400">
                        <p>Plus sur La Ronde</p>
                        <a href="/" className="mt-6 inline-flex w-full items-end leading-5 no-underline">
                            <span>
                                <i className="fa-regular fa-folder"></i>
                                Archives
                            </span>
                        </a>
                        <a href="/users/abonnement" className="mt-6 inline-flex w-full items-end leading-5 no-underline">
                            <span>
                                <i className="fa-regular fa-user"></i>
                                S'abonner
                            </span>
                        </a>
                        <a href="/users/connexion" className="mt-6 inline-flex w-full items-end leading-5 no-underline">
                            <span>
                                <i className="fa-sharp fa-light fa-right-to-bracket"></i>
                                Se connecter
                            </span>
                        </a>
                        <a href="/" className="mt-6 inline-flex w-full items-end leading-5 no-underline">
                            <span>
                                <i className="fa-sharp fa-regular fa-envelope"></i>
                                Magazine du jour
                            </span>
                        </a>
                        <a href="/" className="mt-6 inline-flex w-full items-end leading-5 no-underline">
                            <span>
                                <i className="fa-regular fa-address-book"></i>
                                contacter La Ronde
                            </span>
                        </a>    
                    </div>
                </div>
            </div>
            <div className="Footer_body py-11 px-0 border-t-2 border-solid border-[#a4a9b4]">
                <section className="Foot_information mt-0">
                    <ul className="Footer_links block text-center w-auto h-auto list-none text-xl overflow-hidden relative pl-0 m-0">
                        <li className="relative inline-block pb-2 px-5 border-r border-solid border-gray-400">
                            <a href="/" className="pl-0 inline-flex w-full items-end leading-5 no-underline">
                                Mentions légales
                            </a>
                        </li>
                        <li className="relative inline-block pb-2 px-5 border-r border-solid border-gray-400">
                            <a href="/" className="pl-0 w-full items-end leading-5 no-underline">
                                Politique de confidentialité
                            </a>
                        </li>
                        <li className="relative inline-block pb-2 px-5 border-r border-solid border-gray-400">
                            <a href="/" className="pl-0 w-full items-end leading-5 no-underline">
                                Politique de cookies
                            </a>
                        </li>
                        <li className="relative inline-block pb-2 px-5 ">
                            <a href="/" className="pl-0 w-full items-end leading-5 no-underline">
                                Conditions d'utilisation
                            </a>
                        </li>

                    </ul>
                </section>
            </div>
            <div className="Footer_bottom flex justify-center items-center pt-10  border-t-2 border-solid border-[#a4a9b4]">
                <p className="Footer_title m-0 mr-12 text-white upp
                text-[1.4rem] relative uppercase tracking-normal">Suivez La Ronde</p>
                <section className="Foot_social mt-0 flex">
                    <ul className="Footer_links block md:flex flex-col md:flex-row w-auto h-auto list-none text-xl overflow-hidden relative pl-0 m-0">
                        <li className="relative pb-2">
                            <a href="/" className="pl-0 inline-flex w-full items-end leading-5 no-underline">
                                <span className="">
                                    <i className="fa-brands fa-facebook"></i>
                                    Facebook
                                </span>
                            </a>
                        </li>
                        <li className="md:ml-12 relative pb-2">
                            <a href="/" className="pl-0 inline-flex w-full items-end leading-5 no-underline">
                                <span className="">
                                    <i className="fa-brands fa-instagram"></i>
                                    Instagram
                                </span>
                            </a>
                        </li>
                        <li className="md:ml-12 relative pb-2">
                            <a href="/" className="pl-0 inline-flex w-full items-end leading-5 no-underline">
                                <span className="">
                                    <i className="fa-brands fa-youtube"></i>
                                    Youtbe
                                </span>
                            </a>
                        </li>
                    </ul>
                </section>
            </div>
        </section>
        <section>
            <div className="flex justify-center items-center pt-10">
                <p className="m-0 mr-12 text-white  border-t-2 border-solid border-gray-500 text-[1.2rem] pt-2 relative uppercase tracking-normal">© 2023 La Ronde </p>
            </div>
        </section>
    </footer>
    );
}


export default Footer;