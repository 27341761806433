import React from 'react';
import { Route, Routes } from 'react-router-dom';
import {Erreur, SousRubriqueViews, SousRubriquesList} from '../views/SousRubriqueViews.js';


const SousRubriquesRoute = () => {
  return (
    <Routes>
        <Route exact path="/Erreur" element={<Erreur />} />
        <Route exact path="/*" element={<SousRubriqueViews />} />
        <Route exact path="/" element={<SousRubriquesList />} />
    </Routes>
  );
};

export default SousRubriquesRoute;