import axios from 'axios';
import { API_NAME } from '../utils/constants';

const Services = require('../services/Services');

const EntityName = 'users';
const API_URL = API_NAME;

export const createUser = async (formData) => {

  const user = await Services.createEntity(EntityName, formData);
  if (user) {
    return user;
  }
  return {
    error: "Erreur lors de la création [!]"
  };
};

export const getUser = async (formData) => {
  const user = await axios.post(`${API_URL}/${EntityName}/connexion`, formData);
  if (user.data) {
    return user.data;
  }
  return {
    error: "Erreur lors de la récupération [!]"
  };

};

export const getLimiteUser = async (limit, tri, entity) => {
  if (!entity || entity === '' || entity === undefined) {
    entity = EntityName;
  }
  try {
    const users = await Services.getLimitedEntities(entity, limit, tri);
    if (users && users.length > 0) {
      return users;
    } else {
      console.log('Erreur lors de la récupération des users');
      return {};
    }
  } catch (error) {
    console.log('Erreur lors de la récupération des users :', error);
    return {};
  }
};

export const updateItems = async (req, res) => {

};
  
export const deleteUser = async (req, res) => {

};
  
  
export const getAllUsers = async() => {
  try{
    const users = await Services.getEntity(EntityName);
    if (users && users.length > 0) {
      return users;
    } else {
      console.log('Erreur lors de la récupération des users');
      return {};
    }
  }catch(error){
    console.log('Erreur lors de la récupération des users :', error);
  }
};
  

// Ajoutez d'autres fonctions pour gérer les opérations liées aux users
// par exemple : updateUser, deleteUser, getAllUsers, etc.
