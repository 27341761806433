const models = require('../models/Models');
const Services = require('../services/Services');

const EntityName = 'categories';

export const createRubrique = async (formeData) => {
  try {
    const rubrique = await Services.createEntity(EntityName, formeData);

    if (rubrique) {
      return rubrique;
    } else {
      console.log('Erreur lors de la création de l\'rubrique');
      return { 
        error: "Erreur lors de la création de l'rubrique"
      };
    }
  } catch (error) {
    console.log('Erreur lors de la création de l\'rubrique :', error);
    return {
      error: "Erreur lors de la création de l'rubrique"
    };
  }
};

export const getRubrique = async (rubriqueId) => {
  try {
    const rubrique = await Services.getEntity(EntityName, rubriqueId);
    if (rubrique) {
      return rubrique;
    } else {
      console.log('Erreur lors de la récupération de l\'rubrique');
      return {};
    }
  } catch (error) {
      console.log('Erreur lors de la récupération de l\'rubrique :', error);
    return {};
  }
};

export const getLimiteRubrique = async (limit, tri) => {
  try {
    const rubriques = await Services.getLimitedEntities(EntityName, limit, tri);
    if (rubriques && rubriques.length > 0) {
      return rubriques;
    } else {
      console.log('Erreur lors de la récupération des rubriques');
      return {};
    }
  } catch (error) {
    console.log('Erreur lors de la récupération des rubriques :', error);
    return {};
  }
};

export const updateRubrique = async (formData) => {
  try {
    const rubrique = await Services.updateEntity(EntityName, formData);
    if (rubrique) {
      return rubrique;
    } else {
      console.log('Erreur lors de la mise à jour de l\'rubrique');
      return {};
    }
  } catch (error) {
    console.log('Erreur lors de la mise à jour de l\'rubrique :', error);
    return {};
  }
};
  
export const deleteRubrique = async (id) => {
  try {
    const { category } = await Services.deleteEntity(EntityName, id);
    return category;
  } catch (error) {
      console.log('Erreur lors de la suppression de l\'rubrique :', error);
    return {};
  }
};
  

export const getAllRubriques = async() => {
  try{
    const rubriques = await Services.getAllEntities(EntityName);
    if (rubriques && rubriques.length > 0) {
      return rubriques;
    } else {
      console.log('Erreur lors de la récupération des rubriques');
      return {};
    }
  }catch(error){
    console.log('Erreur lors de la récupération des rubriques :', error);
  }
};

export const deleteImgRubrique = async (id, title, p) => {
  try{
    // Envoyer les données vers le services
    const response = await Services.deleteImgEntity(id, title, p);
    //Effectuez le traitement souhaité avec la response ici
    if(response){
      return response;
    } else {
      return {};
    }
  } catch (error){
    console.error("Une erreur s\'est produite lors de la suppression de l'image ", error);
  }
};

export const sendImgRubrique = async (file) => {
  try {
    // Envoyer les données vers le services
    const response = await Services.sendImgEntity(file);
    // Effectuez le traitement souhaité avec le fichier ici
    if (response) {
      return response;
    }else{
      return {};
    }
  } catch (error) {
    console.error('Une erreur s\'est produite lors du traitement de l\'image', error);
  }
};

export const updateImgRubrique = async (files) => {
  try{
    // Envoyer les données vers le services
    const response = await Services.updateImgEntity(files);
    //Effectuez le traitement souhaité avec la response ici
    if(response){
      return response;
    } else {
      return {};
    }
  } catch (error){
    console.error("Une erreur s\'est produite lors de la mise à jour de l'image ", error);
  }
};
  

// Ajoutez d'autres fonctions pour gérer les opérations liées aux rubriques
// par exemple : updateRubrique, deleteRubrique, getAllRubriques, etc.
