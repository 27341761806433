import React, { useEffect, useState } from "react";

const PageHead = () => {

    const [url, setUrl] = useState("");

    useEffect(() => {
        // Recuperer l'url de la page
        const currentUrl = window.location.href;
        let name = null;

        // Split l'url pour récupérer le slug ou le nom de la page
        const splitUrl = currentUrl.split("/");
        if (splitUrl.length > 0) {
            name = splitUrl[splitUrl.length - 1];
        }
        if (name === null || name === "") {
            window.location.href = "/notfound";
        }

        setUrl(name);

    }, [])

    return (
        <div className="PW_head pb-8 mb-8 mt-8 border-b-4 border-solid border-gray-400">
            <h2 className="Rubrique_title text-[2rem] sm:text-[4rem] uppercase tracking-tighter font-semibold">{url}</h2>
        </div>
    );
};

export default PageHead;
