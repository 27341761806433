import React, {  useContext, useState, useEffect } from "react";

import Layout from "./LayoutUser";
import { API_NAME } from "../utils/constants";
import { fetchData } from "../utils/apiUtils";
import { Popup_bea } from "../components/others/popUp";
import { applyCss } from "../components/others/styleCss";
import { AuthContext } from "../components/others/AuthContext";


import presse from "../assets/kiosque.jpg";
import presse2 from "../assets/getty.jpg";
import { verifyEmail, verifyPassword } from "../utils/stringUtils";
import { createUser, getUser } from "../controllers/userController";


export const Connexion = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const { login, loginAdmin } = useContext(AuthContext);



  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const { email, password } = formData; // Extraire les propriétés de l'objet formData

      // Si les champs sont vides
      if (!email || !password) {
        const email = document.getElementById("email");
        const password = document.getElementById("password");
        Popup_bea("[!]", "Veuillez remplir tous les champs", "danger");
        applyCss(email, ["text-red-500", 'border-red-500']);
        applyCss(password, ["text-red-500", 'border-red-500']);
        return;
      } else {
        if(verifyEmail(formData.email) === false){
          const email = document.getElementById("email");
          const password = document.getElementById("password");
          Popup_bea("Email[!]", "Veuillez entrer des données valides", "danger");
          applyCss(email, ["text-red-500", 'border-red-500']);
          applyCss(password, ["text-red-500", 'border-red-500']);

          return;
        }else{
          getUser(formData)
          .then((response) => {
              if(response.message || response.error){
                if(response.message === "Utilisateur non trouvé"){
                  // Afficher un message d'erreur à l'utilisateur
                  Popup_bea("Erreur[!]", "Utilisateur non trouvé", "danger");
                }else{
                  // Afficher un message d'erreur à l'utilisateur
                  Popup_bea("Erreur[!]", `Mot de passe ou Email incorrect  ${response.message}`, "danger");
                }
              }else{
                if(response.isAdmin && response.isAdmin === true){
                  // Créer un cookie pour l'utilisateur
                  loginAdmin(response)
                  // Rediriger l'utilisateur vers la page d'acueil
                  window.location.href = "/admin?login=true";
                }else{
                  login(response.username)
                  // Rediriger l'utilisateur vers la page d'acueil
                  window.location.href = "/?login=true";
                }
              }
          })
        }
      }
    } catch (error) {
        console.log("error survenue")
      // Gérer les erreurs de requête
      console.error(error);
      // ...
      // Faites quelque chose en cas d'erreur, par exemple, afficher un message d'erreur à l'utilisateur
      // ...
    }
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    // Mettre la couleur du texte en bleu et la bordure en bleu
    event.target.classList.remove("text-red-500");
    event.target.classList.remove("border-red-500");
    applyCss(event.target, ["text-blue-500", 'border-blue-500']);
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const [scriptLoaded, setScriptLoaded] = useState(false);

  useEffect(() => {
    
    const script = document.createElement('script');
    script.src = "https://cdn.tailwindcss.com";

    script.addEventListener('load', () => {
      setScriptLoaded(true);
    });

    document.head.appendChild(script);

    return () => {
      // Supprimez le script lorsque le composant est démonté
      document.head.removeChild(script);
    };
  }, []);

  if (!scriptLoaded) {
    return null; // Ou un indicateur de chargement si vous le souhaitez
  }
  
  return (
    <Layout>
    <div className="bg-white">
      <section className="border-red-500 bg-gray-50 min-h-screen min-w-screen flex items-center justify-center">
        <div className="max-h-[90%] max-w-[90%] sm:max-w-[75%] sm:max-h-[75%] mml:max-w-[60%] mml:max-h-[60%] bg-gray-100 py-5 md:p-5 flex rounded-2xl shadow-lg">
          <div className="md:w-1/2 p-16">
            <h2 className="text-2xl font-bold text-gray-700">Connexion</h2>
            <p className="text-sm mt-4 text-gray-700">
              Si vous avez un compte, veuillez vous connecter
            </p>
            <form
              action=""
              className="flex flex-col gap-4 items-center mt-8"
              onSubmit={handleSubmit}
            >
              <input
                id="email"
                type="email"
                className="p-2 rounded-xl border w-full"
                name="email"
                placeholder="Entrer votre email"
                value={formData.email}
                onChange={handleChange}
              />
              <div className="relative w-full">
                <input
                  id="password"
                  type="password"
                  className="w-full p-2 rounded-xl border"
                  name="password"
                  placeholder="Entrer votre mot de passe"
                  value={formData.password}
                  onChange={handleChange}
                />
              </div>
              <div className="flex items-start w-full">
                <button className="bg-yellow-500 rounded-xl py-2 text-white max-w-full px-10  hover:scale-105 duration-300">
                  Se connecter
                </button>
              </div>
            </form>

            <div className="mt-7 grid grid-cols-3 items-center text-gray-500">
                <hr className="border-gray-500" />
                <p className="text-center text-sm">OR</p>
                <hr className="border-gray-500" />
              </div>

              <button className="bg-white border py-2 w-full rounded-xl mt-5 flex justify-center items-center text-sm hover:scale-105 duration-300">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25px"
                  height="25px"
                  fill="currentColor"
                  className="bi bi-google mr-3"
                  viewBox="0 0 16 16"
                >
                  <path d="M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z" />
                </svg>
                S'identifier avec Google
              </button>

              <div className=" mt-1 text-xs border-b py-6 border-gray-400">
                <a href="">Mot de passe oublié..</a>
              </div>

              <div className="text-[1rem] flex justify-between items-center mt-3">
                <p>Si vous n'avez pas de compte ?</p>
                <button className="py-2 px-5 ml-3 bg-white border rounded-xl hover:scale-110 duration-300 border-yellow-500  ">
                  <a href="/users/abonnement">Inscription </a>
                </button>
              </div>
          </div>
          <div className="w-1/2 md:flex hidden overflow-hidden rounded-2xl">
              <img
                src={presse2}
                className="rounded-2xl"
                alt="page img"
              />
            </div>
        </div>
      </section>
    </div>
    </Layout>
  );
};

export const Abonnement = () => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        password: "",
        confirmPassword: "",
    });
    const [scriptLoaded, setScriptLoaded] = useState(false);
    const { authenticated, login } = useContext(AuthContext);

    if(authenticated){
      window.location.href = "/";
    }

    const handleSubmit = async (event) => {
    event.preventDefault();
      try {
        if(formData.name === "" || formData.email === "" || formData.password === "" || formData.confirmPassword === ""){
          const name = document.getElementById("name");
          const email = document.getElementById("email");
          const password = document.getElementById("password");
          const confirmPassword = document.getElementById("confirmPassword");
          Popup_bea("[!]", "Veuillez remplir tous les champs", "danger");
          applyCss(name, ["text-red-500", 'border-red-500']);
          applyCss(email, ["text-red-500", 'border-red-500']);
          applyCss(password, ["text-red-500", 'border-red-500']);
          applyCss(confirmPassword, ["text-red-500", 'border-red-500']);
          return;
        }else{
          if(formData.password !== formData.confirmPassword){
            const password = document.getElementById("password");
            const confirmPassword = document.getElementById("confirmPassword");
            Popup_bea("Mot de passe[!]", "Les mots de passe ne correspondent pas", "danger");
            applyCss(password, ["text-red-500", 'border-red-500']);
            applyCss(confirmPassword, ["text-red-500", 'border-red-500']);
            return;
          }
          if(verifyPassword(formData.password) === false || verifyPassword(formData.confirmPassword) === false){
            const password = document.getElementById("password");
            const confirmPassword = document.getElementById("confirmPassword");
            Popup_bea("Mot de passe[!]", "Les mots de passe doivent contenir au moins 5 caractères, au moins une lettre minuscule, au moins une lettre majuscule, au moins un chiffre, au moins un caractère spécial", "danger");
            applyCss(password, ["text-red-500", 'border-red-500']);
            applyCss(confirmPassword, ["text-red-500", 'border-red-500']);
            return;
          }
          if(verifyEmail(formData.email) === false){
            const email = document.getElementById("email");
            Popup_bea("Email[!]", "Veuillez entrer un email valide", "danger");
            applyCss(email, ["text-red-500", 'border-red-500']);
            return;
          }

          // Envoyer les données d'abonnement vers votre API
          if(formData.password === formData.confirmPassword){
            createUser(formData)
            .then((response) => {
          
              if(response.message || response.error){
                if (response.message === "Cet utilisateur existe déjà"){
                  // Afficher un message d'erreur à l'utilisateur
                  Popup_bea("Erreur[!]", "Cet utilisateur existe déjà", "danger");
                } else if(response.message === "Erreur lors de la création de l'utilisateur"){
                  // Afficher un message d'erreur à l'utilisateur
                  Popup_bea("Erreur[!]", "Erreur lors de la création de l'utilisateur", "danger");
                }else if (response.message === "Utilisateur créé avec succès"){
                  // Rediriger l'utilisateur vers la page d'acueil
                  window.location.href = "/?create=true";
                }else if (response.message === "Email déjà utilisé [!]"){
                  Popup_bea("Erreur[!]", "Erreur lors de la création de l'utilisateur", "danger");
                }else{
                  // Afficher un message d'erreur à l'utilisateur
                  Popup_bea("Erreur[!]", "Erreur lors de la création de l'utilisateur", "danger");
                }
              }else{
                login(formData.name)
                // Rediriger l'utilisateur vers la page d'acueil
                window.location.href = "/?create=true";
              }
            })
        }
        }

      } catch (error) {
        console.error("Une erreur est survenue", error);
      }
    };

    const handleChange = (event) => {
      const { name, value } = event.target;
      // Mettre la couleur du texte en bleu et la bordure en bleu
      event.target.classList.remove("text-red-500");
      event.target.classList.remove("border-red-500");
      applyCss(event.target, ["text-blue-500", 'border-blue-500']);
      setFormData((prevState) => ({
          ...prevState,
          [name]: value,
      }));
    };


    useEffect(() => {
      const script = document.createElement('script');
      script.src = "https://cdn.tailwindcss.com";
  
      script.addEventListener('load', () => {
        setScriptLoaded(true);
      });
  
      document.head.appendChild(script);
  
      return () => {
        // Supprimez le script lorsque le composant est démonté
        document.head.removeChild(script);
      };
    }, []);
  
    if (!scriptLoaded) {
      return null; // Ou un indicateur de chargement si vous le souhaitez
    }
  

  return (
    <Layout > 
    <div className="bg-white">
        <section className="border-red-500 bg-gray-50 min-h-screen min-w-screen flex items-center justify-center">
            <div className="max-h-[90%] max-w-[90%] sm:max-w-[75%] sm:max-h-[75%] mml:max-w-[60%] mml:max-h-[60%] bg-gray-100 py-5 md:p-5 flex rounded-2xl shadow-lg">
                <div className="w-1/2 md:flex hidden overflow-hidden rounded-2xl">
                    <img
                    src={presse}
                    className="rounded-2xl h-[560px]"
                    alt="page img"
                    height="560px"
                    width="420px"
                    />
                </div>
                <div className="md:w-1/2 p-16 ">
                    <h2 className="text-2xl font-bold text-gray-700">Abonnement</h2>
                    <p className="text-sm mt-4 text-gray-700">
                    Si vous n'avez pas encore de compte, veuillez vous abonner
                    </p>
                    <form
                    action=""
                    className="flex flex-col gap-4 items-center mt-8"
                    onSubmit={handleSubmit}
                    >
                    <input
                        type="text"
                        className="p-2 rounded-xl border w-full"
                        name="name"
                        id="name"
                        placeholder="Entrer votre nom"
                        value={formData.name}
                        onChange={handleChange}
                    />
                    <input
                        id="email"
                        type="email"
                        className="p-2 rounded-xl border w-full"
                        name="email"
                        placeholder="Entrer votre email"
                        value={formData.email}
                        onChange={handleChange}
                    />
                    <div className="relative w-full">
                        <input
                        id="password"
                        type="password"
                        className="w-full p-2 rounded-xl border"
                        name="password"
                        placeholder="Entrer votre mot de passe"
                        value={formData.password}
                        onChange={handleChange}
                        />
                    </div>
                    <div className="relative w-full">
                        <input
                        id="confirmPassword"
                        type="password"
                        className="w-full p-2 rounded-xl border"
                        name="confirmPassword"
                        placeholder="Confirmer votre mot de passe"
                        value={formData.confirmPassword}
                        onChange={handleChange}
                        />
                    </div>
                    <div className="flex items-start w-full">
                        <button className="bg-yellow-500 rounded-xl py-2 text-white max-w-full px-10  hover:scale-105 duration-300">
                        Abonnement
                        </button>
                    </div>
                    </form>

                    <div className="mt-7 grid grid-cols-3 items-center text-gray-500">
                    <hr className="border-gray-500" />
                    <p className="text-center text-sm">OU</p>
                    <hr className="border-gray-500" />
                    </div>

                    <button className="bg-white border py-2 w-full rounded-xl mt-5 flex justify-center items-center text-sm hover:scale-105 duration-300">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25px"
                        height="25px"
                        fill="currentColor"
                        className="bi bi-google mr-3"
                        viewBox="0 0 16 16"
                    >
                        <path d="M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z" />
                    </svg>
                    S'identifier avec Google
                    </button>

                    <div className="text-[1rem] flex justify-between items-center mt-3">
                    <p>Vous avez déjà un compte ?</p>
                    <button className="py-2 px-5 ml-3 bg-white border rounded-xl hover:scale-110 duration-300 border-yellow-500  ">
                        <a href="/users/connexion">Identifiez-vous </a>
                    </button>
                    </div>
                </div>
            </div>
        </section>
    </div>
    </Layout>
  );
};

