import React, { useContext, useState, useEffect } from "react";

import Layout from "./Layout";
import { CardsAdminer, CardsAdminRubrique, CardsAdminArticle, CardsAdminStat } from "../components/cards/cards";

import { addCustomStyleSheet, parseToDate } from '../utils/othersFunction';

import { getLimiteUser } from "../controllers/userController";
import { AuthContext } from '../components/others/AuthContext';
import { getLimiteArticle } from '../controllers/articleControllers';
import { getLimiteRubrique } from '../controllers/rubriqueControllers';


const AdminHome = () => {
    const [articles, setArticles] = useState([]);
    const [rubriques, setRubriques] = useState([]);
    const [admins , setAdmins] = useState([]);
    const [isLoadingArticle, setIsLoadingArticle] = useState(true);
    const [isLoadingRubrique, setIsLoadingRubrique] = useState(true);
    const [isLoadingAdmin, setIsLoadingAdmin] = useState(true);

    const { authenticated, username, role, logoutAdmin, loginAdmin } = useContext(AuthContext);
    useEffect(() => {
        addCustomStyleSheet('../admin.css');
        addCustomStyleSheet('../admin2.css');
        addCustomStyleSheet('../../admin.css');
        addCustomStyleSheet('../../admin2.css');
    }, []);

    
    useEffect(() => {
            
        const fetchData = async () => {
            try {
                getLimiteArticle(8, 'desc').then((data) => {
                  setArticles(data); 
                }).finally(() => {
                  setIsLoadingArticle(false);
                });

                getLimiteRubrique().then((data) => {
                  setRubriques(data); 
                }).finally(() => {
                  setIsLoadingRubrique(false);
                });
                
                getLimiteUser(6, 'asc', 'users/admins').then((data) => {
                    setAdmins(data);
                }).finally(() => {
                    setIsLoadingAdmin(false);
                });

            } catch (error) {
                console.log('Une erreur s\'est produite lors de la récupération :', error);
            }
        };

        fetchData();
    }, []);

    const date = new Date(); // Date actuelle

    // Utilisation de méthodes pour obtenir les parties de la date
    const day = date.getDate();
    const month = date.getMonth() + 1; // Les mois commencent à 0, donc on ajoute 1
    const year = date.getFullYear();

    // Formater la date avec des options personnalisées (format "dd/mm/yyyy")
    const formattedDate = `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;


  return (
    <Layout >
        <div className="absolute right-0 py-[100px] px-4 max-w-[calc(100%-260px)] w-full z-10">
            <div className="Hi flex justify-between mt-4 mb-8 pr-4 pb-8 border-b border-solid ">
                <span className="block text-xl font-bold">Hi welcome again <span className="uppercase">{`${username}`}</span></span>
                <span className="block text-lg">{`${formattedDate}`}</span>
            </div>
            <div className="Stats">
                <h4 className="border-b-2 border-gray-500 pb-4 mb-8 text-2xl font-semibold"><a href="">Stats</a></h4>
                <div className="flex justify-between text-black">
                    <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-8">
                        <CardsAdminStat />
                        <CardsAdminStat />
                        <CardsAdminStat />
                    </div>            
                </div>
            </div>

            <div className="Articles mt-10">
                <h4 className="border-b-2 border-gray-500 pb-4 mb-8 text-2xl font-semibold">
                    <a href="/admin/projects/list">Articles</a>
                    </h4>
                {isLoadingArticle ? (
                    <p className="text-[22px] px-4 py-8">Loading...</p>
                ) : (
                    <>
                        {articles && articles.length > 0 ? (
                            <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-8">
                                {articles.map((article) => (
                                    <div key={article._id}>
                                        <CardsAdminArticle data={article} />
                                    </div>
                                ))}
                            </div>
                            ) : (
                            <p className="text-[22px] px-4 py-8">No articles found.</p>
                        )}
                    </>
                )}
            </div>
            
            <div className="Rubriques mt-10">
                <h4 className="border-b-2 border-gray-500 pb-4 mb-8 text-2xl font-semibold">
                    <a href="/admin/all_rubriques/list">Rubriques</a>
                </h4>
                {isLoadingRubrique ? (
                        <p className="text-[22px] px-4 py-8">Loading...</p>
                    ) : (
                    <>
                        {rubriques && rubriques.length > 0 ? (
                            <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-8">
                                {rubriques.map((rubrique) => (
                                    <div key={rubrique._id}>
                                        <CardsAdminRubrique data={rubrique} />
                                    </div>
                                ))}
                            </div>
                            ) : (
                            <p className="text-[22px] px-4 py-8">No rubriques found.</p>
                        )}
                    </>
                    )}
            </div>

            <div className="Admin mt-10">
                <h4 className="border-b-2 border-gray-500 pb-4 mb-8 text-2xl font-semibold">
                    <a href="/admin/admins/list">Admins</a>
                </h4>
                {isLoadingAdmin ? (
                    <p className="text-[22px] px-4 py-8">Loading...</p>
                    ) : (
                        <>
                            {admins && admins.length > 0 ? (
                                <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-8">
                                    {admins.map((admin) => (
                                        <div key={admin._id}>
                                            <CardsAdminer data={admin} />
                                        </div>
                                    ))}
                                </div>
                                ) : (
                                <p className="text-[22px] px-4 py-8">No admins found.</p>
                            )}
                        </>
                    )}   
            </div>
        </div>
    </ Layout>
  );
};


export default AdminHome;