import React, { useEffect, useState } from "react";

import { SousRubriques, Listing } from "../wrapperRubriques/sousrubriques";
import { getAllWithCondition } from "../../../controllers/AllWithCondition";

const PageBody = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [updatedRubriques, setUpdatedRubriques] = useState([]);
    const [updatedArticles, setUpdatedArticles] = useState([]);

    useEffect(() => {
        // Récuperer l'url de la page
        const currentUrl = window.location.href;
        let rubrique = null;

        // Split l'url pour récupérer le slug ou le nom de la page
        const splitUrl = currentUrl.split("/");
        if (splitUrl.length > 0) {
            rubrique = splitUrl[splitUrl.length - 1];
        }
        if (rubrique === null || rubrique === "") {
            window.location.href = "/notfound";
        }

        const rubriqueOptions = {
            condition : { slug: rubrique },
            populate: { path: 'id_file', select: { title: 1, created_at: 1, updated_at: 1, is_active: 1, is_top: 1, is_public: 1, entity: 1, id_entity: 1 } },
            limit: 1,
            skip: 0,
            projection: { name: 1, description: 1, slug: 1, created_at: 1, updated_at: 1, is_active: 1 },
            sort: { created_at: -1 },
        }
     
        const sousRubriqueOptions = {
            populate: { path: 'id_file', select: { title: 1, created_at: 1, updated_at: 1, is_active: 1, is_top: 1, is_public: 1, entity: 1, id_entity: 1 } },
            limit: 10,
            skip: 0,
            projection: { name: 1, description: 1, slug: 1, created_at: 1, updated_at: 1, is_active: 1 },
            sort: { created_at: -1 },
        };
    
        const articleOptions = {
            populate: { path: 'id_categories', select: { name: 1, description: 1, slug: 1, is_active: 1, id_parent_categories: 1 } },
            limit: 10,
            skip: 0,
            projection: { title: 1, content: 1, slug: 1, created_at: 1, updated_at: 1, is_active: 1 },
            sort: { created_at: -1 },
        };
    
        const fileOptions = 'null'
    
        let formData = new FormData();
        formData.append('rubriqueOptions', JSON.stringify(rubriqueOptions));
        formData.append('sousRubriqueOptions', JSON.stringify(sousRubriqueOptions));
        formData.append('articleOptions', JSON.stringify(articleOptions));
        formData.append('fileOptions', JSON.stringify(fileOptions));
    
        getAllWithCondition(formData)
            .then((data) => {
                if (data !== null && data !== undefined && data !== {}) {
                    setUpdatedArticles(data.articles);
                    setUpdatedRubriques(data.rubriques[0]);
                    setIsLoading(false);
                }
            }
            )
    }, []);

    return (
        isLoading && updatedRubriques.is_active ? (
            <></>
        ) : (
            <div className="Page_body">
                <SousRubriques nameSousRubrique={updatedRubriques.name} />
                {updatedArticles && updatedArticles.length > 0 ? (
                    <Listing articles={updatedArticles.slice(8, updatedArticles.length)} />
                ) : (
                    <></>
                )}
            </div>
        )
    );
}

export default PageBody;