// models/User.js
class User {
    constructor(username, password, email, created_at) {
      this.username = username;
      this.password = password;
      this.email = email;
      this.created_at = created_at;
    }
  }
  
  // models/Admin.js
  class Admin {
    constructor(active, description, id_user) {
      this.active = active;
      this.description = description;
      this.id_user = id_user;
    }
  }
  
  // models/Article.js
  class Article {
    constructor(title, content, category_id, description, is_premium, created_at, created_by) {
      this.title = title;
      this.content = content;
      this.category_id = category_id;
      this.description = description;
      this.is_premium = is_premium;
      this.created_at = created_at;
      this.created_by = created_by;
    }
  }
  
  // models/Category.js
  class Category {
    constructor(name, parent_category_id, description, created_at) {
      this.name = name;
      this.parent_category_id = parent_category_id;
      this.description = description;
      this.created_at = created_at;
    }
  }
  
  // models/Tag.js
  class Tag {
    constructor(name, parent_tag_id, category_id, description, created_at) {
      this.name = name;
      this.parent_tag_id = parent_tag_id;
      this.category_id = category_id;
      this.description = description;
      this.created_at = created_at;
    }
  }
  
  // models/File.js
  class File {
    constructor(title, description, file_url, file_type, created_at, updated_at) {
      this.title = title;
      this.description = description;
      this.file_url = file_url;
      this.file_type = file_type;
      this.created_at = created_at;
      this.updated_at = updated_at;
    }
  }
  
  export { User, Admin, Article, Category, Tag, File };
  