export const truncateString = (str, maxLength) => {
 
  };
  
  export const capitalizeString = (str) => {

  };

  export const sliceString = (str, start,end) => {
    if(str === undefined || str === null || str === "") return "";
    if(start === undefined || start === null || start === "") start = 0;
    if(end === undefined || end === null || end === "") end = 50;

    // Il faut couper la chaîne de caractères à l'espace le plus proche de la limite et ajouter "..." à la fin
    // Enlever les balises HTML
    str = new DOMParser().parseFromString(str, 'text/html').documentElement.innerText;

    if(str.length > end){
      let newStr = str.slice(start, end);
      let lastSpace = newStr.lastIndexOf(" " );
      newStr = newStr.slice(0, lastSpace);
      newStr += " ...";
      return newStr;
    } else{
      return str;
    }
  };

  export const deleteDoubleItem = (array) => {
    if(array === undefined || array === null || array === "") return [];
    let newArray = [];
    array.forEach((item) => {
      if(!newArray.includes(item)){
        newArray.push(item);
      }
    });
    return newArray;
  };
  
  export const verifyEmail = (email) => {
    if(email === undefined || email === null || email === "") return false;
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return regex.test(email);
  };
  
  export const verifyPassword = (password) => {
    if(password === undefined || password === null || password === "") return false;
    // Au moins 5 caractères, au moins une lettre minuscule, au moins une lettre majuscule, au moins un chiffre, au moins un caractère spécial
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!?@#$%^&*])[A-Za-z\d!?@#$%^&*]{5,}$/;
    return regex.test(password);
  };


  // Autres fonctions utilitaires pour la manipulation des chaînes de caractères
  // ...
  