import React, { useState, useEffect, useRef } from "react";


import Layout from "../Layout";
import { CardsFiles } from "../../components/cards/cards";
import { Popup, Popup_bea } from "../../components/others/popUp";
import { applyCss } from "../../components/others/styleCss";
import { Input, Radio, Select } from "../../components/forms/forms";
import { addCustomStyleSheet, fileType, parseToDate, titleToSlug } from '../../utils/othersFunction';
import { createSousRubrique, deleteImgSousRubrique, deleteSousRubrique, getSousRubrique, sendImgSousRubrique, updateImgSousRubrique, updateSousRubrique } from '../../controllers/sousRubriqueControllers';
import { getAllRubriques } from "../../controllers/rubriqueControllers";


export const SousRubriqueCreate = () => {

    const [updatedTitle, setUpdatedTitle] = useState("");
    const [updatedSlug, setUpdatedSlug] = useState("");
    const [updatedDescription, setUpdatedDescription] = useState("");
    const [updatedDate, setUpdatedDate] = useState(parseToDate(""));
    const [updatedStatus, setUpdatedStatus] = useState(false);

    const [updateRubriques, setUpdateRubriques] = useState([]);
    const [updatedRubrique, setUpdatedRubrique] = useState([]);

    const isLoadingMoreRef = useRef(false);
    const [isLoadingSousRubrique, seIsLoadingSousRubrique] = useState(true);

  // Chargement du css
  useEffect(() => {
      addCustomStyleSheet('../../../admin.css');
      addCustomStyleSheet('../../../admin2.css');
      setUpdatedDate(new Date(updatedDate).toISOString().split('T')[0]);
  }, []);

  
  // Chargement de toute les Rubriques
  useEffect(() => {
    const fetchData = async () => {
        try {
            isLoadingMoreRef.current = true;

            getAllRubriques().then((data) => {
              if(data){
                // Vérifier si c'est une rubrique
                data = data.filter((rubrique) => rubrique.id_parent_categories.length === 0);
                setUpdateRubriques(data);
              }
            });
            seIsLoadingSousRubrique(false);
        } catch (error) {
            console.log('Une erreur s\'est produite lors de la récupération :', error);
            
        }
      };

    fetchData();
  }, []);

    /** Handle appler pour sauvegarder la sousRubrique */
  const handleForm = (event) => {
    event.preventDefault();
    const myTartget = event.target;
    // Afficher tous ce qu'il y a dans le formulaire
    const name = myTartget[0];
    const slug = myTartget[1];
    const description = myTartget[2];
    const date = myTartget[4];
    const status = myTartget[5];
    const id_parent_categories = myTartget[3];

    console.log(updatedRubrique)

    // Si le formulaire contient des champs vides
    if(name.value === "" && slug.value === "" && description.value === "" && date.value === "" && status.value === "" && updatedRubrique.length === 0){
        name.placeholder = 'No Name';
        slug.placeholder = 'No Slug';
        applyCss(name, ["text-red-500", 'border-red-500']);
        applyCss(slug, ["text-red-500", 'border-red-500']);
        applyCss(description, ["text-red-500", 'border-red-500']);
        applyCss(date, ["text-red-500", 'border-red-500']);
        applyCss(status, ["text-red-500", 'border-red-500']);
        applyCss(id_parent_categories, ["text-red-500", 'border-red-500']);
    } 
    if(name.value === ""){
        name.placeholder = 'No Name';
        applyCss(name, ["text-red-500", 'border-red-500']);
    } 
    if(slug.value === ""){
        slug.placeholder = 'No Slug';
        applyCss(slug, ["text-red-500", 'border-red-500']);
    }
    if(description.value === ""){
        description.placeholder = 'No Description';
        applyCss(description, ["text-red-500", 'border-red-500']);
    }
    if(date.value === ""){
        applyCss(date, ["text-red-500", 'border-red-500']);
    }
    if(updatedRubrique.length === 0){
        applyCss(id_parent_categories, ["text-red-500", 'border-red-500']);
    }

    // Si tout les champs sont remplis
    if(name.value !== "" && slug.value !== "" && description.value !== "" && date.value !== "" && updatedRubrique.length !== 0){
        // Créez une instance de FormData pour envoyer les données du formulaire
        const formData = new FormData();
        formData.append('title', updatedTitle);
        formData.append('slug', updatedSlug);
        formData.append('description', updatedDescription);
        formData.append('date', updatedDate);
        formData.append('status', updatedStatus);
        formData.append('entity', 'sousRubriques');
        // Passer le tableau des rubriques à FormData
        updatedRubrique.forEach((id, index) => {
            formData.append(`id_parent_categories[${index}]`, id);
        });

        try {
            // Appelez votre service pour envoyer les données
            createSousRubrique(formData).then((data) => {
                // Si data comtient un attribut error ou message
                if(data.error || data.message || data === {}){
                    let message = data.error ? data.error : data.message;
                    // Afficher le message d'erreur
                    Popup_bea("Attention [!]",message , "danger");
                }else{  
                    Popup_bea("Bien [!]", "Modifier", "success")
                    
                    // Rediriger vers la page de modification de la sousRubrique
                    window.location.href = `/admin/sous_rubriques/edit/${data.slug}`;
                }
                // Remettre à zéro le formulaire
                myTartget.reset();
            });
            //   console.log('Image envoyée avec succès');
            //   Effectuez les actions souhaitées après l'envoi de l'image
        } catch (error) {
            console.error('Une erreur s\'est produite lors de l\'envoi de l\'image', error);
            //   Gérez l'erreur comme vous le souhaitez
        }
    }

  };

  const handleRubriques = (e) => {
    // console.log(e.target.selectedOptions)
    // Ajout de l'id_parent_categories dans le tableau, si il existe déjà on le supprime
    if(updatedRubrique.includes(e.target.value)){
      setUpdatedRubrique(updatedRubrique.filter((id) => id !== e.target.value));
    }else{
      setUpdatedRubrique([...updatedRubrique, e.target.value]);
    }
  }

  return (
    <Layout>
      <div className="ItMain absolute right-0 pt-[100px] pb-[150px] px-4 max-w-[calc(100%-260px)] w-full z-10">
          <div className="SousRubriques mt-10">
          {isLoadingSousRubrique ? (
              <p className="text-[22px] px-4 py-8">Loading... i think not rubrique found</p>
            ) : (
              <>
                <div className="flex w-full justify-between border-b-2 border-gray-500 pb-4 mb-8">
                  <h4 className="text-2xl font-semibold flex items-center">Création Sous Rubriques</h4>
                </div>
                <div className="flex flex-row md:flex-col justify-between relative">
                  <div className="max-w-[55%] w-full">
                    <form className="w-full" onSubmit={handleForm}>
                      <div className="flex flex-col justify-between min-w-full">
                        <Input type="text" name="name" value={updatedTitle} label="Name" onChange={(e) => setUpdatedTitle(e.target.value)} />

                        <Input type="text" name="slug" value={updatedSlug} label="Slug" onChange={(e) => setUpdatedSlug(e.target.value)} />

                        <Input type="text" name="description" value={updatedDescription} label="Description" onChange={(e) => setUpdatedDescription(e.target.value)}  />

                        <Select label="Rubrique" name="id_parent_categories" options={updateRubriques} value={updatedRubrique} onChange={handleRubriques} />

                        <Input type="date" name="date" value={updatedDate} label="Date" onChange={(e) => setUpdatedDate(e.target.value)} />

                        <Radio name="status" options={["true" ,"false"]} value={updatedStatus} label="Activation" onChange={(e) => setUpdatedStatus(e.target.value)} />
                      </div>
                      <div className="absolute right-0 left-0">
                        <button type="submit" className="bea my-8 p-4 block uppercase border hover:border-yellow-400 hover:text-yellow-400">
                          Enregistrer 
                        </button>
                      </div>
                    </form>
                  </div>
                  <div className="max-w-[43%] w-full" >

                  </div>
                </div>
              </>
            )}
          </div>
      </div>
    </Layout>
  );
  
}


export const SousRubriqueEdit = () => {
    const isLoadingMoreRef = useRef(false);
    const [isLoadingSousRubrique, setIsLoadingSousRubrique] = useState(true);
    const [loading, setLoading] = useState(false);
    const [sousRubrique, setSousRubrique] = useState({});

    const [updatedTitle, setUpdatedTitle] = useState("");
    const [updatedSlug, setUpdatedSlug] = useState("");
    const [updatedDescription, setUpdatedDescription] = useState("");
    const [updatedDate, setUpdatedDate] = useState(parseToDate(""));
    const [updatedStatus, setUpdatedStatus] = useState(false);
    const [updateFiles, setUpdateFiles] = useState([]);
    const [updatedIdParentCategories, setUpdatedIdParentCategories] = useState([]);

    const [description, setDescription] = useState("");
    const [file, setFile] = useState(null);

    const [updateRubriques, setUpdateRubriques] = useState([]);


  // Chargement du css
  useEffect(() => {
      addCustomStyleSheet('../../../admin.css');
      addCustomStyleSheet('../../../admin2.css');
      setLoading(true);
    }, []);

  // Récupération de l'sousRubrique
  useEffect(() => {
      const currentUrl = window.location.href;
      const idSousRubrique = currentUrl.split('/').pop();
      const fetchData = async () => {
          try {
              isLoadingMoreRef.current = true;
              getAllRubriques().then((data) => {
                // Vérifier si c'est une rubrique
                data = data.filter((rubrique) => rubrique.id_parent_categories.length === 0);
                setUpdateRubriques(data);
              });

              getSousRubrique(idSousRubrique).then((data) => {
                  
                    setSousRubrique(data);
                    setUpdatedTitle(data.title);
                    setUpdatedSlug(data.slug ? data.slug : titleToSlug(data.title));
                    setUpdatedDescription(data.description);
                    setUpdatedDate(parseToDate(data.created_at, 'yyyy-MM-dd'));
                    setUpdatedStatus(data.is_active);
                    setUpdateFiles(data.files);
                    setUpdatedIdParentCategories(data.id_parent_categories);
                    
                    setIsLoadingSousRubrique(false);
                    isLoadingMoreRef.current = false;
              }).finally(() => {
              });
          } catch (error) {
            console.log('Une erreur s\'est produite lors de la récupération :', error);
          }
        };
      
      fetchData();
  }, [loading]);


  /** Handle appler pour sauvegarder la sousRubrique */
  const handleForm = (event) => {
    event.preventDefault();

    const myTartget = event.target;
    // Afficher tous ce qu'il y a dans le formulaire
    const name = myTartget[0];
    const slug = myTartget[1];
    const description = myTartget[2];
    const date = myTartget[4];
    const status = myTartget[5];
    const id_parent_categories = myTartget[3];

    // Si le formulaire contient des champs vides
    if(name.value === "" && slug.value === "" && description.value === "" && date.value === "" && status.value === "" && updatedIdParentCategories.length === 0){
        name.placeholder = 'No Name';
        slug.placeholder = 'No Slug';
        applyCss(name, ["text-red-500", 'border-red-500']);
        applyCss(slug, ["text-red-500", 'border-red-500']);
        applyCss(description, ["text-red-500", 'border-red-500']);
        applyCss(date, ["text-red-500", 'border-red-500']);
        applyCss(status, ["text-red-500", 'border-red-500']);
    } 
    if(name.value === ""){
        name.placeholder = 'No Name';
        applyCss(name, ["text-red-500", 'border-red-500']);
    } 
    if(slug.value === ""){
        slug.placeholder = 'No Slug';
        applyCss(slug, ["text-red-500", 'border-red-500']);
    }
    if(description.value === ""){
        description.placeholder = 'No Description';
        applyCss(description, ["text-red-500", 'border-red-500']);
    }
    if(date.value === ""){
        applyCss(date, ["text-red-500", 'border-red-500']);
    }
    if(updatedIdParentCategories.length === 0){
        applyCss(id_parent_categories, ["text-red-500", 'border-red-500']);
    }

    // Si tout les champs sont remplis
    if(name.value !== "" && slug.value !== "" && description.value !== "" && date.value !== "" && updatedIdParentCategories.length !== 0){
        // Créez une instance de FormData pour envoyer les données du formulaire
        const formData = new FormData();
        formData.append('id', sousRubrique.id);
        formData.append('title', updatedTitle);
        formData.append('slug', updatedSlug);
        formData.append('description', updatedDescription);
        formData.append('date', updatedDate);
        formData.append('status', updatedStatus);
        formData.append('entity', 'sousRubriques');
        // Passer le tableau des rubriques à FormData
        updatedIdParentCategories.forEach((id, index) => {
            formData.append(`id_parent_categories[${index}]`, id);
        });

        try {
            // Appelez votre service pour envoyer les données
            updateSousRubrique(formData).then((data) => {
                // Si data comtient un attribut error ou message
                if(data.error || data.message || data === {}){
                    let message = data.error ? data.error : data.message;
                    // Afficher le message d'erreur
                    Popup_bea("Attention [!]",message , "danger");
                }else{  
                    // Changer le text de l'url
                    window.history.pushState({}, null, `/admin/sous_rubriques/edit/${data.slug}`);
                    Popup_bea("Bien [!]", "Modifier", "success")
                    
                    // Mettre à jour les données de l'image
                    setSousRubrique(data);
                }
                // Remettre à zéro le formulaire
                myTartget.reset();
            });
            //   console.log('Image envoyée avec succès');
            //   Effectuez les actions souhaitées après l'envoi de l'image
        } catch (error) {
            console.error('Une erreur s\'est produite lors de l\'envoi de l\'image', error);
            //   Gérez l'erreur comme vous le souhaitez
        }
    }

  };

  /** Handle appler pour sauvegarder un fichier */
  const handleFormImage = async (event) => {
    event.preventDefault();
    const myTartget = event.target;

    // Si le formulaire contient des données
    if(myTartget[0].files.length === 0 && myTartget[1].value === "" ){
      console.log('No image and no description')
      document.querySelector('.image_name').innerText = 'No image';
      document.querySelector('.parent_container').classList.add('text-red-500');
      document.querySelector('.parent_container').classList.add('border-red-500');
      document.querySelector('.description').placeholder = 'No Description';
      document.querySelector('.description').classList.add('text-red-500');
      document.querySelector('.description').classList.add('border-red-500');
    }else if(myTartget[0].files.length === 0){
      document.querySelector('.image_name').innerText = 'No image';
      document.querySelector('.parent_container').classList.add('text-red-500');
      document.querySelector('.parent_container').classList.add('border-red-500');
    } else if(myTartget[1].value === ""){
      document.querySelector('.description').placeholder = 'No Description';
      document.querySelector('.description').classList.add('border-red-500');
      document.querySelector('.description').classList.add('text-red-500');
    } else {

      // Créez une instance de FormData pour envoyer les données du formulaire
      const formData = new FormData();
      formData.append('id', sousRubrique.id);
      formData.append('description', description);
      formData.append('entity', 'sousRubriques');
      formData.append('dateEntity', updatedDate);
      formData.append('image', file);
      
      try {
        // Appelez votre service pour envoyer l'image
        sendImgSousRubrique(formData).then((data) => {
          // Si data comtient un attribut error ou message
          if(data.error || data.message){
            let message = data.error ? data.error : data.message;
            // Afficher le message d'erreur
            Popup_bea("Attention [!]",message , "danger");
          }else{  
            const {savedFile} = data
            // Ajouter l'image dans la liste des images
            setUpdateFiles([...updateFiles, savedFile]);
            Popup_bea("Enregistrer [!]", "Le fichier a bien été enregistré", "success")
          }
          // Remettre à zéro le formulaire
          myTartget.reset();
          document.querySelector('.image_name').innerText = "Insert image";

          // 
        });
        //   console.log('Image envoyée avec succès');
        //   Effectuez les actions souhaitées après l'envoi de l'image
      } catch (error) {
        console.error('Une erreur s\'est produite lors de l\'envoi de l\'image', error);
      //   Gérez l'erreur comme vous le souhaitez
      }
    }
  };
    
  /** Handle appeler quand on insert un fichier */
  const handleChangeInput = (e) => {      
      if(e.target.files.length > 0){
        let image_name = e.target.files[0].name;
        // Si le fichier est un fichier texte ou docx
        if(fileType(image_name) === 'image'){
          document.querySelector('.parent_container').classList.remove('text-red-500');
          document.querySelector('.parent_container').classList.remove('border-red-500');
          document.querySelector('.parent_container').classList.add('text-blue-500');
          document.querySelector('.parent_container').classList.add('border-blue-500');
          document.querySelector('.image_name').innerText = image_name;
          document.querySelector('#submitImg').disabled = false;
          const file = e.target.files[0];
          setFile(file);
        }else {
            document.querySelector('.image_name').innerText = 'Just image';
            document.querySelector('.parent_container').classList.add('text-red-500');
            document.querySelector('.parent_container').classList.add('border-red-500');
            document.querySelector('#submitImg').disabled = true;

        }
      }    
  }

  /*  Mettre à jour la description */
  const handleChangeDescription = (e) => {
    e.target.classList.remove('text-red-500');
    e.target.classList.remove('border-red-500');
    e.target.classList.add('text-blue-500');
    e.target.classList.add('border-blue-500');
    setDescription(e.target.value);
  }

  /*  Mettre à jour les infos des images */
  const imageChangStatus = (e) => {
    e.preventDefault();
    const myTartget = e.target;
    const description = myTartget[0];
    const is_public = myTartget[1];
    const is_top = myTartget[2]
    const deleteFile = myTartget[3];
    const id_data = myTartget[4];
    const title = myTartget[5];

    const formData = new FormData();
    formData.append('id', id_data.value);
    formData.append('entity', 'sousRubriques');
    formData.append('title', title.value);
    formData.append('description', description.value);
    formData.append('is_public', is_public.value);
    formData.append('is_top', is_top.value);
    formData.append('deleteFile', deleteFile.value);

    // si deleteFile est coché
    if(deleteFile.value === "true"){
      try {
        // Appelez votre service pour envoyer l'image
        deleteImgSousRubrique( id_data.value, title.value, is_public.value).then((imgDelete) => {
          // Si data comtient un attribut error ou message
          if(imgDelete.error){
            let message = imgDelete.error ? imgDelete.error : imgDelete.message;
            Popup_bea("Attention [!]",message , "danger");
          }else{  
            // Mettre à jour les images
            // Supprimer l'image de la liste des images
            console.log(imgDelete);
            const newFiles = updateFiles.filter((file) => file._id !== imgDelete._id);
            setUpdateFiles(newFiles);
            Popup_bea("Bien [!]", "Suppression reussis", "success")
          }
        });
        //   console.log('Image envoyée avec succès');
        //   Effectuez les actions souhaitées après l'envoi de l'image
      } catch (error) {
        console.error('Une erreur s\'est produite lors de l\'envoi de l\'image', error);
      //   Gérez l'erreur comme vous le souhaitez
      }
    } else {
      if(description.value === ""){
        description.placeholder = 'No Description';
        description.classList.add('text-red-500');
        description.classList.add('border-red-500');
      } else {
        // Mettre le donneés dans un objet 
        updateImgSousRubrique(formData).then((data) => {
          // Si data comtient un attribut error ou message
          if(data.error){
            let message = data.error ? data.error : data.message;
            // Afficher le message d'erreur
            Popup_bea("Attention [!]",message , "danger");
          }else{  
            // Mettre à jour les données de l'image
            const newFiles = updateFiles.map((file) => {
              if(file._id === data._id){
                return data;
              }else{
                return file;
              }
            });
            setUpdateFiles(newFiles);
            Popup_bea("Bien [!]", "Modifier", "success")
          }
          // Remettre à zéro le formulaire
          myTartget.reset();
          document.querySelector(".drop_down_modif").classList.add("hidden");
        });
      }
    }
  }

  /** Handle appeler quand on clique sur le bouton supprimer */
  const handleDelete = (e) => {
    e.preventDefault();
    // Appele d'un composant popup
    Popup( "Supprimer", "Voulez-vous vraiment supprimer cette sous rubrique ?", handleYesDelete);
  }


  /** Handle appeler pour supprimer la sousRubrique */
  const handleYesDelete = () => {
    // Réccupérer l'id de la sousRubrique
    const idSousRubrique = sousRubrique.id;

    // Appeler le service pour supprimer la sousRubrique
    try {
      // Appelez votre service pour envoyer les données
      deleteSousRubrique(idSousRubrique).then((data) => {
        // Si data comtient un attribut error ou message
        if(data.error || data.message){
          let message = data.error ? data.error : data.message;
          // Afficher le message d'erreur
          Popup_bea("Attention [!]",message , "danger");
        }else{  
          // Changer le text de l'url
          window.history.pushState({}, null, `/admin/sous_rubriques/list`);
          Popup_bea("Bien [!]", "SousRubrique Supprimer", "success")
          setTimeout(() => {
            window.location.href = "/admin/sous_rubriques/list";
          }, 2000);
        }
      });
    } catch (error) {
      console.error('Une erreur s\'est produite lors de l\'envoi de l\'image', error);
    //   Gérez l'erreur comme vous le souhaitez
    }
  }

  // Handle pour la modification de valeur select (rubrique)
  const handleRubriques = (e) => {
    // console.log(e.target.selectedOptions)
    console.log(e.target.value)
    // Ajout de l'id_parent_categories dans le tableau, si il existe déjà on le supprime
    if(updatedIdParentCategories.includes(e.target.value)){
      setUpdatedIdParentCategories(updatedIdParentCategories.filter((id) => id !== e.target.value));
    }else{
      setUpdatedIdParentCategories([...updatedIdParentCategories, e.target.value]);
    }
  }


  return (
    <Layout>
      <div className="ItMain absolute right-0 pt-[100px] pb-[150px] px-4 max-w-[calc(100%-260px)] w-full z-10">
          <div className="SousRubriques mt-10">
          {isLoadingSousRubrique ? (
              <p className="text-[22px] px-4 py-8">Loading...</p>
            ) : (
              <> 
                {sousRubrique && Object.keys(sousRubrique).length > 0 ? (
                  <>
                    <div className="flex w-full justify-between border-b-2 border-gray-500 pb-4 mb-8">
                      <h4 className="text-2xl font-semibold flex items-center">{sousRubrique.title ? sousRubrique.title : sousRubrique.name}</h4>
                      <button className="bea p-2 block rounded-xl bg-red-200 border-red-200 text-white uppercase border hover:border-red-500 hover:bg-red-500 hover:font-extrabold" onClick={handleDelete}>
                        Supprimer 
                      </button>
                    </div>
                    <div className="flex flex-row md:flex-col justify-between relative">
                      <div className="max-w-[55%] w-full">
                        <form className="w-full" onSubmit={handleForm}>
                          <div className="flex flex-col justify-between min-w-full">
                            <Input type="text" name="name" value={updatedTitle} label="Name" onChange={(e) => setUpdatedTitle(e.target.value)} />

                            <Input type="text" name="slug" value={updatedSlug} label="Slug" onChange={(e) => setUpdatedSlug(e.target.value)} />

                            <Input type="text" name="description" value={updatedDescription} label="Description" onChange={(e) => setUpdatedDescription(e.target.value)}  />

                            <Select label="Rubrique" name="id_parent_categories" options={updateRubriques} value={updatedIdParentCategories} onChange={handleRubriques} />

                            <Input type="date" name="date" value={updatedDate} label="Date" onChange={(e) => setUpdatedDate(e.target.value)} />

                            <Radio name="status" options={["true" ,"false"]} value={updatedStatus} label="Activation" onChange={(e) => setUpdatedStatus(e.target.value)} />
                          </div>
                          <div className="absolute right-0 left-0">
                            <button type="submit" className="bea my-8 p-4 block uppercase border hover:border-yellow-400 hover:text-yellow-400">
                              Enregistrer 
                            </button>
                          </div>
                        </form>
                      </div>
                      <div className="max-w-[43%] w-full" >
                            <div className="header_file flex flex-col mb-4 justify-between">
                              <h4 className="w-full py-4 flex items-center"> Ajouter un fichier</h4>
                              <form className="w-full" onSubmit={handleFormImage} encType="multipart/form-data" >
                                <div className="flex flex-row justify-between items-center">
                                  <div className="max-w-[40%] w-full h-full">
                                    <div className="parent_container p-2 max-w-[100%] w-full cursor-pointer rounded-xl bg-gray-100 border-dashed border-2 border-gray-400 flex justify-center items-center relative">
                                        <input type="file" id="input-file" className="absolute inset-0 opacity-0 cursor-pointer" name="input-file" onChange={handleChangeInput}/>
                                        <div className="flex justify-center items-center max-w-full">
                                            <div className="mr-2">
                                                <svg className="upload-icon bi bi-cloud-arrow-up-fill" xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" viewBox="0 0 16 16">
                                                <path d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2zm2.354 5.146a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2z"/>
                                                </svg>
                                            </div>
                                            <div>
                                                <p className="text-gray-500 mb-1 image_name ">Insérer une photo</p>
                                            </div>
                                        </div>
                                    </div>
                                  </div>
                                  <input type="text" className="description max-w-[45%] w-full p-2 block border border-blue-400 rounded-lg" id="description" name="description" placeholder="Description" onChange={handleChangeDescription} />
                                  <div className="max-w-[10%] w-full my-2 p-2 block uppercase border hover:border-green-400 hover:text-green-400 cursor-pointer rounded-lg relative items-center justify-center text-center">
                                    <i className="fa-regular fa-paper-plane"></i>
                                    <input type="submit" className="absolute w-full h-full left-0 top-0" value={""} id="submitImg" />
                                  </div>
                                </div>
                              </form>
                            </div>
                            <div className="body_file max-h-[450px] w-full overflow-y-auto pr-4">
                              <h4 className="w-full py-2 flex items-center"> Lien des fichiers</h4>
                              {updateFiles && updateFiles.length > 0 ? (
                                <>
                                  {updateFiles.map((file) => (
                                    <div key={file._id} >
                                      <CardsFiles data={file} onChange={imageChangStatus} />
                                    </div>
                                  ))}
                                </>
                              ) : (
                                <p className="text-[22px] px-4 py-8">Aucun fichier trouvé</p>
                              )}

                            </div>
                      </div>
                    </div>
                  </>
                  ) : (
                  <p className="text-[22px] px-4 py-8">Aucun sousRubrique trouvé</p>
              )}
            </>
            )}
          </div>
      </div>
    </Layout>
  );
}

