// Layout.js
import React from 'react';
import Header from './Layout/Header';
import Footer from './Layout/Footer';

const Layout = ({ children }) => {


  // Supprimer les contenus de la balise style
  const removeStyle = () => {
    const style = document.querySelector('style');
    if (style) {
      style.innerHTML = '';
    }
  };

  removeStyle();


  return (
    <>
      <Header />
      <>
        {children}
      </>
      <Footer />
    </>
  );
};

export default Layout;
