import React from "react";

import PageHeader from "./PageHeader";
import PageBody from "./PageBody";

const PageWrapper = () => {
    return (
        <div className="Page_wrapper py-[20px] px-[10px]">
            <PageHeader />
            <PageBody />
        </div>
    );
}

export default PageWrapper;