import React, { useEffect } from 'react';
import Cookies from 'js-cookie';

const AuthContext = React.createContext();

const AuthProvider = ({ children }) => {
  // État pour suivre l'état de l'authentification
  const [authenticated, setAuthenticated] = React.useState(false);
  const [username, setUsername] = React.useState('');
  const [role, setRole] = React.useState('');
  const [id, setId] = React.useState('');

  // Fonction pour enregistrer les informations d'authentification dans les cookies
  const login = (username) => {
    // Vérifier si il y a déjà des informations d'authentification dans les cookies
    if (Cookies.get('authenticated') === 'true') {
      // Si oui, tous les supprimer
      Cookies.remove('authenticated');
      Cookies.remove('username');
      Cookies.remove('role');
    } 

    Cookies.set('authenticated', 'true', { expires: 1 });
    Cookies.set('username', username, { expires: 1 });
    setAuthenticated(true);
    setUsername(username);
  };

  // Fonction pour supprimer les informations d'authentification des cookies
  const logout = () => {
    Cookies.remove('authenticated');
    Cookies.remove('username');
    Cookies.remove('role');
    Cookies.remove('id')
    setAuthenticated(false);
    setUsername('');
  };

  // Fonction pour enregistrer les infomations d'authentification pour un admin
  const loginAdmin = (user) => {
    // Vérifier si il y a déjà des informations d'authentification dans les cookies
    if (Cookies.get('authenticated') === 'true') {
      // Si oui, tous les supprimer
      Cookies.remove('authenticated');
      Cookies.remove('username');
      Cookies.remove('role');
    }
    Cookies.set('authenticated', 'true', { expires: 1 });
    Cookies.set('username', user.username, { expires: 1 });
    Cookies.set('role', user.is_super_admin, { expires: 1 });
    Cookies.set('id', user.id, { expires: 1 });
    setId(user.id);
    setAuthenticated(true);
    setRole(user.is_super_admin);
    setUsername(user.username);
  };

  // Fonction pour supprimer les informations d'authentification des cookies
  const logoutAdmin = () => {
    Cookies.remove('authenticated');
    Cookies.remove('username');
    Cookies.remove('role');
    Cookies.remove('id');
    setAuthenticated(false);
    setUsername('');
    setRole('');
    setId('');
  };


  // Lire les cookies une fois lors du montage initial du composant
  useEffect(() => {
    const isAuthenticated = Cookies.get('authenticated');
    const savedUsername = Cookies.get('username');
    if (isAuthenticated === 'true' && savedUsername) {
      setAuthenticated(true);
      setUsername(savedUsername);
    }
  }, []);

  return (
    <AuthContext.Provider value={{ authenticated, username, login, logout, loginAdmin, logoutAdmin, id, role }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
