import React, { useEffect, useState } from "react";

import { deleteDoubleItem } from "../utils/othersFunction";
import { getAllWithCondition } from "../controllers/AllWithCondition";

const Spotlight = () => {

    const [isLoading, setIsLoading] = useState(true);

    const [sousRubriquesAll, setSousRubriquesAll] = useState([]); 

    const tableau = [];
    
    useEffect(() => {

        const rubriqueOptions = "null"
          
        const sousRubriqueOptions = "null"
        
        const articleOptions = { 
        condition: { id_categories: { $ne: [] } },
        populate: { path: 'id_categories', select: { name: 1, description: 1, slug: 1, is_active: 1, id_parent_categories: 1 } },
        limit: 20,
        skip: 0,
        projection: { title: 1, content: 1, slug: 1, created_at: 1, updated_at: 1, is_active: 1 },
        sort: { created_at: -1 },
        }; 

        const fileOptions = "null"

        let formData = new FormData();
        formData.append('rubriqueOptions', JSON.stringify(rubriqueOptions));
        formData.append('sousRubriqueOptions', JSON.stringify(sousRubriqueOptions));
        formData.append('articleOptions', JSON.stringify(articleOptions));
        formData.append('fileOptions', JSON.stringify(fileOptions));

        getAllWithCondition(formData)
        .then((data) => {
            if (data && data.articles && data.articles.length > 0) {
                data.articles.forEach((item) => {
                    item.id_categories.forEach((categorie) => {
                        if(categorie.id_parent_categories.length > 0 && categorie.is_active){
                            // Ajouter dans le tableau des sous-rubriques si elle n'y est pas déjà
                            if (!sousRubriquesAll.includes(categorie)) {
                                setSousRubriquesAll((sousRubriquesAll) => [...sousRubriquesAll, categorie]);
                            }
                        }
                    });
                });
        
                setIsLoading(false);
            }

        })

    }, []);

    return (
    <>
        {isLoading ? (
            <p></p>
        ) : (
            <>
                <div className="Spotlight p-0 m-0 bg-slate-100">
                    <div className="Spotlight_container">
                        <div className="Spotlight_center flex items-center border-solid border-b border-b-gray-300 lg:border-b-gray-400 border-t-4 border-red-500 text-[13px] sm:text-[16px]">
                            <div className="Spoltight_header py-0 px-[4px]">
                                <h2 className="SH_badge bg-red-500 text-gray-200 font-semibold py-[3px] px-[10px] uppercase">A la une</h2>
                            </div> 
                            {sousRubriquesAll && sousRubriquesAll.length > 0 ? (
                                <div>
                                    
                                    <div className="Spotlight_list flex">
                                        {sousRubriquesAll.slice(0, 3).map((item, index) => (
                                            <div key={index}>
                                                {item.is_active && !tableau.includes(item.name) ? (
                                                    <div className="SL_item border-l border-solid border-gray-300">
                                                        <a href={`/sousrubriques/${item.slug}`} className="block font-semibold py-[8px] px-[15px] no-underline text-gray-700 transition-all">{item.name}</a>
                                                        <span className="hidden">{tableau.push(item.name)}</span>
                                                    </div>
                                                ) : (
                                                    <></>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ) : (
                                <p></p>
                            )}
                        </div>
                    </div>
                </div>
            </>
        )}
    </>
    );
}

export default Spotlight;