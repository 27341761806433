import React, { useState, useEffect, useRef } from "react";
import Layout from "../Layout";
import { CardsAdminRubrique } from "../../components/cards/cards";
import { addCustomStyleSheet } from '../../utils/othersFunction';
import { getAllRubriques } from '../../controllers/rubriqueControllers';
import { Popup_bea } from "../../components/others/popUp";

const SousRubriquesList = () => {
  const [rubriques, setRubriques] = useState([]);
  const [isLoadingRubrique, setIsLoadingRubrique] = useState(true);
  const [loading, setLoading] = useState(false);
  const isLoadingMoreRef = useRef(false);

  useEffect(() => {
    addCustomStyleSheet('../../admin.css');
    addCustomStyleSheet('../../admin2.css');
    setLoading(true);
  }, []);
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        isLoadingMoreRef.current = true; // Le chargement est en cours
        const rubriques = await getAllRubriques();
        if (rubriques && rubriques.length > 0) {
            let rubriquesReturn = [];
            rubriques.forEach(rubrique => {
                // Si l'attribut id_parent_categories de rubrique n'est pas supérieur à 0 càd que c'est une rubrique
                if(!(rubrique.id_parent_categories.length === 0)){
                    rubriquesReturn.push(rubrique)
                }
            });
            setRubriques(rubriquesReturn)
        } else {
            Popup_bea("Rubriques [!]", "Erreur lors de la récupération des rubriques", "error");
        } 
        setIsLoadingRubrique(false);
      } catch (error) {
        console.log('Une erreur s\'est produite lors de la récupération :', error);
        setIsLoadingRubrique(false);
      }
    };

    fetchData();
  }, [loading]);


  return (
    <Layout>
      <div className="absolute right-0 pt-[100px] pb-[50px] px-4 max-w-[calc(100%-260px)] w-full z-10">
        <div className="Rubriques mt-10">
          <h4 className="border-b-2 border-gray-500 pb-4 mb-8 text-2xl font-semibold">Sous Rubriques</h4>
          {isLoadingRubrique ? (
            <p className="text-[22px] px-4 py-8">Loading...</p>
          ) : (
            <>
              {rubriques && rubriques.length > 0 ? (
                <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-8">
                  {rubriques.map((rubrique) => (
                    <div key={rubrique._id}>
                      <CardsAdminRubrique data={rubrique} />
                    </div>
                  ))}
                </div>
              ) : (
                <p className="text-[22px] px-4 py-8">No sous rubriques found.</p>
              )}
            </>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default SousRubriquesList;
