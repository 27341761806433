import axios from 'axios';

export const fetchData = async (url) => {
  const completeUrl = `http://${url}`;
  try {
    const response = await axios.get(completeUrl);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};


export const postData = async (url, data) => {
  const completeUrl = `http://${url}`;
  try {
    const response = await axios.post(completeUrl, data);
    return response.data.message;
  } catch (error) {
    // Returner l'erreur
    return error.response.data.message;
  }
};

export const putData = async (url, data) => {
  try {
    const response = await axios.put(url, data);
    return response.data;
  } catch (error) {
    console.error('Error putting data:', error);
    throw error;
  }
};

export const deleteData = async (url) => {
  try {
    const response = await axios.delete(url);
    return response.data;
  } catch (error) {
    console.error('Error deleting data:', error);
    throw error;
  }
};
// Fonction utilitaire pour gérer les erreurs de réponse
export const handleErrorResponse = (error) => {
  if (error.response) {
    // Le serveur a répondu avec un code d'erreur (par exemple, 400, 404, 500)
    console.error('API Error:', error.response.data);
    console.error('Status Code:', error.response.status);
  } else if (error.request) {
    // La requête a été envoyée mais aucune réponse n'a été reçue
    console.error('No response received:', error.request);
  } else {
    // Une erreur s'est produite lors de la configuration de la requête
    console.error('Request error:', error.message);
  }
  throw error;
};

// Autres fonctions utilitaires pour les appels API
// ...

export default {
  fetchData,
  postData,
  putData,
  deleteData,
  handleErrorResponse,
  // ...
};
