import React from "react";

import Rubriques from "../wrapperRubriques/rubriques";
import { SousRubriques } from "../wrapperRubriques/sousrubriques";

const PageBodyHome = () => {
    return (
        <div className="Page_body">
            <Rubriques />
            <SousRubriques />
        </div>
    );
}

export default PageBodyHome;