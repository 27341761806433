import React from "react";

import PageHeader from "./PageHeader";
import PageBody from "./PageBody";
import PageHead from "./PageHead";

const PageWrapper = ( {data} ) => {

    return (
        <div className="Page_wrapper pb-[20px] px-[10px]">
            <PageHead />
            <PageHeader />
            <PageBody />
        </div> 
    );
}

export default PageWrapper;