import React, { useEffect, useState } from "react";

import { Helmet } from 'react-helmet';

import { getArticle } from "../../../controllers/articleControllers";
import RenderHtmlString from "../../others/RenderHtmlString";
import { getFirstParagraph, parseToDate } from "../../../utils/othersFunction";
import { getImgForAffiche } from "../../../controllers/fileController";
import { API_NAME } from "../../../utils/constants";
import { sliceString } from "../../../utils/stringUtils";

const PHContent = () => {

    const [metatLoaded, setMetaLoaded] = useState(false);

    const [loading, setLoading] = useState(false);
    const [updatedArticle, setUpdatedArticle] = useState({});
    const [updatedAuteur, setUpdatedAuteur] = useState({})

    const [theRubrique, setTheRubrique] = useState("");
    const [theSousRubrique, setTheSousRubrique] = useState("");


    const [theDate, setDate] = useState("");
    const [theUpdateDate, setUpdateDate] = useState("");

    const [firstParagraph, setFirstParagraph] = useState("");
    const [firstParagraphAffiche, setFirstParagraphAffiche] = useState("");


    const [fileUrl, setFileUrl] = useState("");
    const [fileDescription, setFileDescription] = useState("");


    useEffect(() => {
        // Récuperer l'url de la page
        const currentUrl = window.location.href;
        let name = null;

        // Split l'url pour récupérer le slug ou le nom de la page
        const splitUrl = currentUrl.split("/");
        if (splitUrl.length > 0) {
            name = splitUrl[splitUrl.length - 1];
        }
        if (name === null || name === "") {
            window.location.href = "/notfound";
        }
	
        getArticle(name)
            .then((res) => {
                if (res) {
                    if (res.files && res.files.length > 0) {
                        // eslint-disable-next-line array-callback-return
                        res.files.map((file, index) => {
                            if (file.is_top) {
                                getImgForAffiche(file.id_entity, file.title)
                                    .then((res) => {
                                        if (res) {
                                            setFileUrl(res.filePath);
                                            setMetaLoaded(true);
                                        }
                                    })

                                setFileDescription(file.description);
                            }
                        })
                    }


                    // Tirer le premier paragraphe
                    let paragr = "";
                    if(res.content !== undefined || res.content !== null){
                        paragr = getFirstParagraph(res.content);
                        paragr = paragr.replace(/<[^>]*>?/gm, '');
                    }
                    setFirstParagraphAffiche(paragr);
                    setLoading(true)
                    setUpdatedArticle(res);
                    // supprimer les propriétés meta de la page

                } else {
                    window.location.href = "/notfound";
                }
            })
    }, []);

    useEffect(() => {
        if (updatedArticle.Categories && updatedArticle.Categories.length > 0) {
            // eslint-disable-next-line array-callback-return
            updatedArticle.Categories.map((category) => {
                if (category.id_parent_categories.length === 0) {
                    setTheRubrique(category);
                } else {
                    setTheSousRubrique(category);
                }
            })
        }
        if (updatedArticle.content) {
            setFirstParagraph(getFirstParagraph(updatedArticle.content));
        }

        updatedArticle.created_at ? setDate(parseToDate(updatedArticle.created_at, 'dd-mm..-yyyy')) : setDate("");

        updatedArticle.updated_at ? setUpdateDate(parseToDate(updatedArticle.updated_at, 'dd-mm..-yyyy')) : setUpdateDate("");

        setUpdatedArticle(updatedArticle)
        setUpdatedAuteur(updatedArticle.author)
    }, [updatedArticle]);

    
    return (
        loading ? (
            <>
                <div className="PH_content w-full mb-[20px] px-15px max-w-full">

                    <>
                        {metatLoaded ? (

                            <Helmet>
                                
                                <title>
                                    {sliceString(updatedArticle.title, 0, 50)}
                                </title>
                                <meta name="language" content="French" />

                                <meta name="og:title" content={sliceString(updatedArticle.title, 0, 50)} />
                                <meta name="og:description" content={sliceString(firstParagraphAffiche, 0, 160)} />
                                <meta name="og:image" content={`${API_NAME}/${fileUrl}`} />
                                <meta name="og:url" content={window.location.href} />
                                <meta name="og:type" content="article" />
                                <meta name="og:site_name" content="La Ronde" />

                                <meta name="twitter:card" content="summary_large_image" />
                                <meta name="twitter:title" content={sliceString(updatedArticle.title, 0, 50)} />
                                <meta name="twitter:description" content={sliceString(firstParagraph, 0, 160)} />
                                <meta name="twitter:image" content={`${API_NAME}/${fileUrl}`} />
                                <meta name="twitter:url" content={window.location.href} />
                                <meta name="twitter:site" content="@LaRonde" />
                                <meta name="twitter:creator" content="@LaRonde" />


                                <meta name="description" content={sliceString(firstParagraph, 0, 160)} />
                                <meta name="image" content={`${API_NAME}/${fileUrl}`} />
                                <meta name="url" content={window.location.href} />
                                <meta name="subject" content={updatedArticle.title} />
                                <meta name="keywords" content={updatedArticle.title} />
                                <meta name="author" content={updatedAuteur ? updatedAuteur.username : " "} />
                                <meta name="category" content={theRubrique.name} />

                                <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
                                <meta name="robots" content="index, follow" />
                                <meta name="googlebot" content="index, follow" />
                                <meta name="google" content="nositelinkssearchbox" />
                                <meta name="coverage" content="Worldwide" />
                                <meta name="revisit-after" content="7 days" />
                                <meta name="generator" content="ReactJS" />
                                <meta name="rating" content="general" />
                                <meta name="identifier-URL" content={window.location.href} />
                                <meta name="distribution" content="Global" />
                                <meta name="rating" content="General" />
                                {/* Ajoutez d'autres balises meta au besoin */}
                            </Helmet>
                        ) : (
                            <></>
                        )}
                    </>

                    <div className="Content_wrapper">
                        <ul className="Content_categorises tracking-tight flex flex-wrap text-[#717b8e] text-[1.5rem] items-center uppercase list-none font-normal mb-6 la">
                            <li className="bea Content_categoris flex leading-5 hover:text-black">
                                <a href={`/rubriques/${theRubrique.slug ? theRubrique.slug : theRubrique.name}`} className="mr-2">{theRubrique.name}</a>
                            </li>
                            <li className="bea Content_categoris flex leading-5 hover:text-black">
                                <a href={`/sousrubriques/${theSousRubrique.slug ? theSousRubrique.slug : theSousRubrique.name}`} className="mr-2">{theSousRubrique.name}</a>
                            </li>
                        </ul>
                        <div className="Content_head max-w-[85%]">
                            <h1 className="Content_title leading-[1.2] font-bold mt-4 text-[2.7rem]">
                                {updatedArticle.title}
                            </h1>
                            <div className="Content_desc text-2xl leading-[1.4] tracking-normal mt-5 font-medium">
                                {updatedArticle.content ? (
                                    <RenderHtmlString htmlString={firstParagraph} />
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                        <div className="Content_author text-[1.4rem] mt-[1.2rem] text-[#717b8e]">
                            <div className="m-0 p-0 flex">
                                <span className="w-[60rem]">Par
                                    <span className="Author_link m-2">{updatedAuteur ? updatedAuteur.username : " "}</span>
                                </span>
                            </div>
                        </div>
                        <div className="Content_date mt-[1.2rem] inline-block text-[1.4rem] text-[#717b8e]">
                            <span>
                                {`Publié le ${theDate}`}
                                <span>
                                    {updatedArticle.updated_at ? (
                                        <span> - Modifié le {`${theUpdateDate}`} </span>
                                    ) : (
                                        <></>
                                    )}
                                </span>
                            </span>
                        </div>
                        <div className="Content_status mt-[1.2rem] text-[1.4rem] text-[#717b8e]">
                            {updatedArticle.is_premium ? (
                                <span className="border-[0.03rem] border-solid border-gray-400 overflow-hidden py-2 pr-4">
                                    <span className="badge before:content-['LR'] before:h-full before:border-0 before:py-[0.8rem] before:px-[0.4rem] before:text-white"></span>
                                    Article premium
                                </span>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                </div>
            </>
        ) : (
            <>
            </>
        ))
}

export default PHContent;
