import React from "react";
import { SlideWidget } from "../../cards/cards";

const PHSlide = () => {

    return (
        <div className="PH_side justify-center flex flex-col md:flex-row items-center mml:flex-col mml:self-start  w-full mml:w-[35%] px-10">
            <SlideWidget />
        </div>
    );
}

export default PHSlide;