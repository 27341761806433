import React from "react";
import PHContent from "./PHContent";
import PHSlide from "./PHSlide";

const PageHeaderHome = () => {
    return (
        <div className="Page_header mb-[20px] last:mb-[10px] flex flex-wrap">
            <PHContent />
            <PHSlide />
        </div>
    );
}

export default PageHeaderHome;
