import React from 'react';
import { Route, Routes } from 'react-router-dom';
import {Connexion, Abonnement} from '../views/UsersViews';



const UsersRoute = () => {
  return (
    <Routes>
        <Route exact path="/connexion" element={<Connexion />} />
        <Route exact path="/abonnement" element={<Abonnement />} />
    </Routes>
  );
};

export default UsersRoute;
