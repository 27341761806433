import React, { useEffect, useState } from "react";
import { CardsImgTwo, SlideWidget } from "../../cards/cards";
import { getAllWithCondition } from "../../../controllers/AllWithCondition";

const PHSlide = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [updatedArticle, setUpdatedArticle] = useState({});
    const [updatedFile, setUpdatedFile] = useState({});

    useEffect(() => {
        // Récupérer les articles avec is_active = true et is_premium = true
        const rubriqueOptions = "null"
        const sousRubriqueOptions = "null"
        const articleOptions = {
            condition: { is_active: true, is_premium: true },
            populate: { path: 'id_categories', select: { name: 1, description: 1, slug: 1, is_active: 1, id_parent_categories: 1 } },
            limit: 1,
            skip: 0,
            projection: { title: 1, content: 1, slug: 1, created_at: 1, updated_at: 1, is_active: 1 },
            sort: { created_at: -1 },
        };

        const fileOptions = {
            limit: 1,
            skip: 0,
            projection: { title: 1, created_at: 1, updated_at: 1, is_active: 1, is_top: 1, is_public: 1, entity: 1, id_entity: 1 },
            sort: { created_at: -1 },
            with: "articles"
        }

        let formData = new FormData();
        formData.append('rubriqueOptions', JSON.stringify(rubriqueOptions));
        formData.append('sousRubriqueOptions', JSON.stringify(sousRubriqueOptions));
        formData.append('articleOptions', JSON.stringify(articleOptions));
        formData.append('fileOptions', JSON.stringify(fileOptions));


        getAllWithCondition(formData) 
            .then((data) => {
                if (data) {
                    setUpdatedArticle(data.articles);
                    if(data.files && data.files.length > 0) {
                        data.files.forEach((item) => {
                            if (item.id_entity === data.articles[0]._id) {
                                setUpdatedFile(item);
                            }
                        });
                    }
                    setIsLoading(false);
                }
                
            })
    }, []);

    return (
        <div className="PH_side justify-center flex flex-col md:flex-row items-center mml:flex-col mml:self-start  w-full mml:w-[35%] px-10">
            <SlideWidget />
            {isLoading ? (
                <></>
            ) : (
                <>
                    {updatedArticle && updatedArticle.length > 0 ? (
                        <CardsImgTwo data={updatedArticle[0]} file={updatedFile} />
                    ) : (
                        <></>
                    )}
                </>
            )}
        </div>
    );
}

export default PHSlide;