import React, { useEffect, useState } from "react";

import handleImageError from "../../utils/handleImageError";
import { deleteDoubleItem, sliceString } from "../../utils/stringUtils";

import { Checkbox } from "../forms/forms";
import { getImgBy, getImgForAffiche } from "../../controllers/fileController";
import { parseToDate } from "../../utils/othersFunction";
import { API_NAME } from "../../utils/constants";


export const CardsText = ({ key, data }) => {
  return (
    <div key={key} className="card w-full relative inline-block pl-[15px] mr-8">
        <div className="item vertical">
            <a href={`/articles/${data.slug ? data.slug : data.title}`} className="flex">
                <div className="item_header border border-solid border-y-gray-400 self-start max-w-[100px] md:max-w-[150px] flex-0">
                </div>
                <div className="item_body">
                    <h2 className="item_title font-thin mb-[10px] text-[18px] leading-none">
                        {data.is_premium ? (
                          <span className="badge"></span>
                        ) : (
                          <></>
                        )}
                        {data.title}
                    </h2>
                    <p className="item_content text-left"></p>
                    <span className="item_tag font-semibold uppercase">
                        <span className="legend text-[14px]"></span>
                    </span>
                </div>
            </a>
        </div>
    </div>
  );
};

export const CardsTextThin = ({ data }) => {
  return (
    <div className="Teaser_item block relative py-4 mx-2 max-w-1/2 w-full overflow-hidden">
      <span className="badge"></span>
      <a href={`/articles/${data.slug ? data.slug : data.title}`} className="transition-all hover:text-[#61a0af]">
        {sliceString(data.title, 0,500)}
      </a>
    </div>
  );
};

export const CardsTextThinSec = ({ data }) => {
  return (
    <div className="card md:w-[44%] w-full relative inline-block mx-[15px]">
      <div className="item vertical">
          <a href={`/articles/${data.slug ? data.slug : data.title}`}  className="flex">
              <div className="item_header border border-solid border-y-gray-400 self-start max-w-[100px] md:max-w-[150px] flex-0">
              </div>
              <div className="item_body">
                  <h2 className="item_title font-thin mb-[10px] text-[18px] leading-none">
                      {data.is_premium ? (
                        <span className="badge"></span>
                      ) : (
                        <></>
                      )}
                      {sliceString(data.title, 0,500)}
                  </h2>
                  <p className="item_content text-left"></p>
                  <span className="item_tag font-semibold uppercase">
                      <span className="legend text-[14px]"></span>
                  </span>
              </div>
          </a>
      </div>
    </div>
  );
};

export const CardsTextThinTwo = ({ data }) => {
  return (
    <div className="card mml:w-1/2 w-full relative inline-block px-[15px]">
      <div className="item vertical">
          <a href={`/articles/${data.slug ? data.slug : data.title}`} className="flex">
              <div className="item_header border border-solid border-y-gray-400 self-start max-w-[100px] md:max-w-[150px] flex-0">
              </div>
              <div className="item_body">
                  <h2 className="item_title font-thin mb-[10px] text-[18px] leading-none">
                      {data.is_premium ? (
                        <span className="badge"></span>
                      ) : (
                        <></>
                      )}
                      {data.title}
                  </h2>
                  <p className="item_content text-left"></p>
                  <span className="item_tag font-semibold uppercase">
                      <span className="legend text-[14px]"></span>
                  </span>
              </div>
          </a>
      </div>
    </div>
  );
};

export const CardsTextBold = ({ data }) => {

  const [link, setLink] = useState("");

  useEffect(() => {
     data.slug ? setLink(`/articles/${data.slug}`) : setLink(`/articles/${data.title}`);
  }, []);
  
  return (
    <div className="card md:w-[44%] w-full relative inline-block mx-[15px]">
      <div className="item vertical">
        <a href={`${link}`} className="flex">
          <div className="item_header border border-solid border-y-gray-400 self-start max-w-[100px] md:max-w-[150px] flex-0"></div>
          <div className="item_body">
            <h2 className="item_title font-thin mb-[10px] text-[18px] leading-none">
            {data.is_premium ? (
                <span className="badge"></span>
              ) : (
                <></>
              )}
              {data.title}
            </h2>
            <p className="item_content text-left"></p>
            <span className="item_tag font-semibold uppercase">
              <span className="legend text-[14px]">
                {data.id_categories.length > 0 ? (
                    data.id_categories.map((item, index) => (
                      <span key={index} className="m-2">
                        - {item.name} -
                      </span>
                    ))
                  ) : (
                    <></>
                  )}
              </span>
            </span>
          </div>
        </a>
      </div>
    </div>
  );
};

export const CardsHeader = ({ data }) => {

  const [isLoading, setIsLoading] = useState(true);
  const [linkFile, setLinkFile] = useState("");

  useEffect(() => {
    if(data){
      getImgBy(data._id)
      .then((data) => {
        if (data && data.length > 0) {
          data.forEach((item) => {
            if (item.is_top) {
              getImgForAffiche(item.id_entity, item.title)
              .then((res) => {
                if (res) {
                    setLinkFile(`${API_NAME}/${res.filePath}`);
                  }
              })
            }
          });
        }
      })
      
      setIsLoading(false);
    }
  }, []);

  return ( 
    <div className="card w-1/2 relative inline-block px-[15px] mb-[30px]">
      {isLoading ? (
        <></>
      ) : (
        <div className="item">
            <a href={`/articles/${data.slug}`}>
                <div className="item_header max-w-[150px] max-h-[80px] md:max-w-[290px] md:max-h-[144px] overflow-hidden flex justify-center items-center">
                    <img src={`${linkFile}`} alt="" onError={handleImageError} width="288" height="144" className="w-full h-full"/>
                </div>
                <div className="item_body py-[10px]">
                    <span className="item_tag font-semibold uppercase">
                        <span className="legend text-[12px]"></span>
                    </span>
                    <h2 className="item_title mb-[10px] text-[14px] md:text-[20px] font-extrabold leading-none">
                      {data.is_premium ? (
                        <span className="badge"></span>
                      ) : (
                        <></>
                      )}
                        {data.title ? sliceString(data.title, 0, 100) : ""}
                    </h2>
                    <p className="item_content text-left text-[14px] hidden">
                        {data.content ? sliceString(data.content, 0, 100) : ""}
                    </p>
                </div>
            </a>
        </div>
      )}
    </div>
  );  
};

export const CardsImgOne = ({ data, file }) => {

  const [linkFile, setLinkFile] = useState("");
  const [link, setLink] = useState("");

  useEffect(() => {
    data.slug ? setLink(`/articles/${data.slug}`) : setLink(`/articles/${data.title}`);
  
    getImgBy(data._id)
    .then((data) => {
      if (data && data.length > 0) {
        data.forEach((item) => {
          if (item.is_top) {
            getImgForAffiche(item.id_entity, item.title)
            .then((res) => {
              if (res) {
                  setLinkFile(`${API_NAME}/${res.filePath}`);
                }
            })
          }
        });
      }
    })

  }, []);


  return (
    <div className="card w-full sm:w-1/2 relative inline-block px-[15px]">
      <div className="item">
        <a href={`${link}`}>
          <div className="item_header max-w-[288px] max-h-[144px] flex justify-center items-center overflow-hidden">
            <img
              src={`${linkFile}`}
              alt=""
              onError={handleImageError}
              width="288"
              height="144"
            />
          </div>
          <div className="item_body py-[10px]">
            <span className="item_tag font-semibold uppercase block mb-4">
              <span className="legend text-[14px]">
                {data.id_categories.length > 0 ? (
                  data.id_categories.map((item, index) => (
                    <span key={index} className="m-2">
                      - {item.name} -
                    </span>
                  ))
                ) : (
                  <></>
                )}
              </span>
            </span>
            <h2 className="item_title mb-[10px] text-[22px] font-extrabold leading-none">
              {data.is_premium ? (
                <span className="badge"></span>
              ) : (
                <></>
              )}
              {data.title}
            </h2>
            <p className="item_content text-left">
              {sliceString(data.content, 0, 300)}
            </p>
          </div>
        </a>
      </div>
    </div>
  );
};

export const CardsImgTwo = ({ data, file }) => {
  const [rubriques, setRubriques] = useState([]);
  const [link, setLink] = useState("");
  const [sousRubriques, setSousRubriques] = useState([]);

  useEffect(() => {
    const handleRubriques = () => {
      if(data.id_categories.length > 0){
        const myRubriques = data.id_categories
        // Parcourir l'objet rubriques
        myRubriques.forEach((item) => {
          if(item.id_parent_categories.length > 0){
            // Ajouter dans le tableau des sous-rubriques si elle n'y est pas déjà
            if (!sousRubriques.includes(item)) {
              setSousRubriques([item]);
            }
          } else if(item.id_parent_categories.length === 0) {
            // Ajouter dans le tableau des rubriques si elle n'y est pas déjà
            if (!rubriques.includes(item)) {
              setRubriques([item]);
            }
          }
        });
      }
    }


    handleRubriques();
  }, []);

  getImgBy(data._id)
  .then((data) => {
    if (data && data.length > 0) {
      data.forEach((item) => {
        if (item.is_top) {
          getImgForAffiche(item.id_entity, item.title)
          .then((res) => {
            if (res) {
                setLink(`${API_NAME}/${res.filePath}`);
              }
          })
        }
      });
    }
  })

  return (
    <div className="card h-full w-full sm:w-1/2 md:w-full relative px-[15px] flex flex-col mt-4 shadow-lg pb-8">
      <h3 className="text-center mb-6 text-[2rem] font-semibold tracking-normal uppercase">
        Article Far
      </h3>
      <div className="item">
        <a href={`/articles/${data.slug}`}>
          <div className="item_header">
            <img
              src={`${link}`}
              alt=""
              onError={handleImageError}
              width="288"
              height="144"
              className="w-full h-full"
            />
          </div>
          <div className="item_body relative max-w-[90%] mx-auto -mt-[21px] text-center">
            <span className="item_tag font-semibold uppercase">
              <span className="legend text-[14px] block z-20 bg-white">
                {rubriques.length > 0 ? (
                  rubriques.map((item, index) => (
                    <span key={index} className="m-2">
                      {item.name} 
                    </span>
                  ))
                ) : (
                  <></>
                )}
              </span>
              <span className="legend text-[14x] font-thin lowercase bg-white">
                {sousRubriques.length > 0 ? (
                  sousRubriques.map((item, index) => (
                    <span key={index} className="m-2">
                      {item.name} 
                    </span>
                  ))
                ) : (
                  <></>
                )}
              </span>
            </span>
            <h2 className="item_title mb-[10px] text-[22px] font-extrabold leading-none">
              {data.title}
            </h2>
            <p className="item_content">
              {sliceString(data.content, 0, 400)}
            </p>
          </div>
        </a>
      </div>
    </div>
  );
};

export const CardsImgTitle = ({ data }) => {
  const [link, setLink] = useState("");
  const [linkFile, setLinkFile] = useState("");

  const [date, setDate] = useState("");
  const [updateDate, setUpdateDate] = useState("");

  useEffect(() => {
    
    getImgBy(data._id)
      .then((data) => {
        if (data.length > 0) {
          data.forEach((item) => {
            if (item.is_top) {
              getImgForAffiche(item.id_entity, item.title)
              .then((res) => {
                if (res) {
                    setLinkFile(`${API_NAME}/${res.filePath}`);
                  }
              })
            }
          });
        }
      })


    data.slug ? setLink(`/articles/${data.slug}`) : setLink(`/articles/${data.title}`);

    data.created_at ? setDate(parseToDate(data.created_at, 'dd-mm..-yyyy')) : setDate("");

    data.updated_at ? setUpdateDate(parseToDate(data.updated_at, 'dd-mm..-yyyy')) : setUpdateDate("");
 
  }, []);


  return (
    <div className="item">
        <a href={`${link}`}>
            <div className="item_header float-left self-start max-w-[30%] md:min-w-[40%] mr-[15px] ">
                <img src={`${linkFile}`} alt="" onError={handleImageError} width="329" height="160" className="w-full h-full" />
            </div>
            <div className="item_body">
                <span className="item_tag font-semibold uppercase">
                    <span className="legend text-[14px]"></span>
                </span>
                <h2 className="item_title mb-[10px] text-[16px] mml:text-[20px] font-extrabold leading-none">
                    {data.is_premium ? (
                        <span className="badge"></span>
                      ) : (
                        <></>
                      )}
                      {data.title}
                </h2>
                <p className="item_content hidden md:block">
                    {sliceString(data.content, 0, 600)}
                </p>
            </div>
            <span className="text-[14px]">
              Publié le {`${date}`}, 
              {data.updated_at ? (
                <span> modifié le {`${updateDate}`}</span>
              ) : (
                <></>
              )}
            </span>
        </a>
    </div>
  );
};

export const CardsImgJustTitle = ({ data }) => {
  const [link, setLink] = useState("");
  const [linkFile, setLinkFile] = useState("");

  useEffect(() => {
    
    getImgBy(data._id)
      .then((data) => {
        if (data.length > 0) {
          data.forEach((item) => {
            if (item.is_top) {
              getImgForAffiche(item.id_entity, item.title)
              .then((res) => {
                if (res) {
                    setLinkFile(`${API_NAME}/${res.filePath}`);
                  }
              })
            }
          });
        }
      })


    data.slug ? setLink(`/articles/${data.slug}`) : setLink(`/articles/${data.title}`);

  }, []);
  return (
    <div className="card w-full relative inline-block px-[15px]">
        <div className="item">
            <a href={`${link}`}>
                <div className="item_header">
                    <img src={`${linkFile}`} alt="" onError={handleImageError} width="288" height="144" className="w-full h-full" />
                </div>
                <div className="item_body py-[10px]">
                    <span className="item_tag font-semibold uppercase">
                        <span className="legend text-[14px]"></span>
                    </span>
                    <h2 className="item_title mb-[10px] text-[16px] md:text-[18px] font-extrabold leading-none">
                        {data.is_premium ? (
                          <span className="badge"></span>
                        ) : (
                          <></>
                        )}
                        {data.title}
                    </h2>
                    <p className="item_content text-left">
                    </p>
                </div>
            </a>
        </div>
    </div>
  );
};

export const CardsImgLeft = ({ data }) => {

  const [link, setLink] = useState("");
  const [linkFile, setLinkFile] = useState("");

  useEffect(() => {
    
    getImgBy(data._id)
      .then((data) => {
        if (data.length > 0) {
          data.forEach((item) => {
            if (item.is_top) {
              getImgForAffiche(item.id_entity, item.title)
              .then((res) => {
                if (res) {
                    setLinkFile(`${API_NAME}/${res.filePath}`);
                  }
              })
            }
          });
        }
      })


    data.slug ? setLink(`/articles/${data.slug}`) : setLink(`/articles/${data.title}`);

  }, []);

  return (
    <div className="card w-full relative block ">
        <div className="item vertical">
            <a href={`${link}`} className="flex">
                <div className="item_header border border-solid border-y-gray-400 self-start max-w-[100px] md:max-w-[150px] max-h-[100px] flex-0 mr-[15px] flex justify-center items-center overflow-hidden">
                    <img src={`${linkFile}`} alt="" onError={handleImageError} width="135" height="90" className="w-full h-full" />
                </div>
                <div className="item_body">
                    <span className="item_tag font-semibold uppercase">
                        <span className="legend text-[14px]">
                          {data.id_categories.length > 0 ? (
                            data.id_categories.map((item, index) => (
                              <>
                              {item.id_parent_categories.length > 0 ? (
                                  <span key={index} className="m-2">
                                  - {item.name} - 
                                  </span>
                                ) : (
                                  <></>
                                )}
                              </>
                            ))
                          ) : (
                            <></>
                          )}
                        </span>
                    </span>
                    <h2 className="item_title mb-[10px] text-[16px] md:text-[18px] font-extrabold leading-none">
                        {data.is_premium ? (
                          <span className="badge"></span>
                        ) : (
                          <></>
                        )}
                        {data.title}
                    </h2>
                    <p className="item_content text-left"></p>
                </div>
            </a>
        </div>
    </div>
  );
};

export const CardsImgRight = ({ data }) => {
  const [link, setLink] = useState("");
  const [linkFile, setLinkFile] = useState("");

  const [date, setDate] = useState("");
  const [updateDate, setUpdateDate] = useState("");

  useEffect(() => {    
    getImgBy(data._id)
      .then((data) => {
        if (data.length > 0) {
          data.forEach((item) => {
            if (item.is_top) {
              getImgForAffiche(item.id_entity, item.title)
              .then((res) => {
                if (res) {
                    setLinkFile(`${API_NAME}/${res.filePath}`);
                  }
              })
            }
          });
        }
      })


    data.slug ? setLink(`/articles/${data.slug}`) : setLink(`/articles/${data.title}`);

    data.created_at ? setDate(parseToDate(data.created_at, 'dd-mm..-yyyy')) : setDate("");

    data.updated_at ? setUpdateDate(parseToDate(data.updated_at, 'dd-mm..-yyyy')) : setUpdateDate("");

  }, []);

  return (
    <div className="card w-full px-4 md:px-0 relative inline-block">
      <div className="item">
          <a href={`${link}`}>
              <div className="item_header float-right self-start max-w-[25%] md:min-w-[30%] ml-[15px] max-h-[110px] flex justify-center items-center overflow-hidden">
                  <img src={`${linkFile}`} alt="" onError={handleImageError} width="329" height="160" className="w-full h-full" />
              </div>
              <div className="item_body">
                  <span className="item_tag font-semibold uppercase">
                      <span className="legend text-[12px]"></span>
                  </span>
                  <h2 className="item_title mb-[10px] text-[18px] font-bold leading-none">
                        {data.is_premium ? (
                          <span className="badge"></span>
                        ) : (
                          <></>
                        )}
                        {data.title}
                  </h2>
                  <p className="item_content hidden sm:block text-xl sm:text-xl">
                      {sliceString(data.content, 0, 800)}
                  </p>
              </div>
              <span className="block text-[12px] mt-4">
                Publié le {`${date}`}, 
                {data.updated_at ? (
                  <span> Modifié le {`${updateDate}`}</span>
                ) : (
                  <></>
                )}
              </span>
          </a>
      </div>
    </div>
  );
};

export const SlideWidget = ({ data, configurations }) => {
  return (
    <div className="Side_widget justify-center flex flex-col items-center mml:block text-center mml:text-left mml:mt-10 w-full mml:w-fit bg-black text-white p-[30px]">
      <div className="self-center pl-0 mb-O">
        <a href="/" className="h-[3rem] w-[13.8rem]flex items-center justify-center">
          <div className="logo_laronde h-full w-full bg-laronde bg-contain bg-center bg-no-repeat inline-block text-black text-[60px] font-extrabold "></div>
          <span className="only h-[1px] hidden p-0 absolute w-[1px]">La Ronde</span>
        </a>
      </div>
      <h3 className="SW_title text-[24px] font-bold leading-none mb-2">
        LA RONDE, partout dans le monde
      </h3>
      <div className="SW_cover">
        <h5 className="my-8 mml:my-6 uppercase">Magazine du jour</h5>
        <a
          href="laronde.com"
          className="flex justify-center mml:block w-auto h-auto my-8 mml:my-4"
        >
          <img
            src=""
            alt=""
            onError={handleImageError}
            width="138"
            height="196"
          />
        </a>
      </div>
      <p className="SW_paragraph mt-6 text-gray-200 leading-none">
        Découvrez d'autres magazines de La Ronde.
      </p>
      <a
        href={`/users/abonnement`}
        className="block relative border border-solid border-gray-100 mt-8 w-1/2 md:w-full"
      >
        <span className="uppercase mr-10 sm:mr-0 ml-4">S'abonner</span>
        <span className="absolute right-0 w-[2.4rem] h-full bg-white bg-contain bg-center bg-no-repeat inline-block shrink-0 text-black text-center">
          <i className="fa-solid fa-right-long"></i>
        </span>
        <span className="hidden text-[1.6rem] leading-1.88">S'abonner</span>
        <span className="only self-center border-0 h-[1px] w-[1px] p-0 hidden whitespace-nowrap">
          Connexion
        </span>
      </a>
      <p className="text-gray-400 mt-10 text-center">
        <a href={`/`} className="no-underline">
          Lire les magazines !
        </a>
      </p>
    </div>
  );
};

export const SlideWidgetRubriques = ({ data, configurations }) => {
  return (
    <div className="Side_widget justify-center flex flex-col items-center mml:block text-center mml:text-left mml:mt-10 w-full mml:w-fit bg-slate-100 text-black border-t-[0.4rem] border-solid border-[#2a303b] pt-0 pb-8 px-8">
      <h3 className="SW_title text-[24px] font-bold leading-none tracking-tight mt-8">LA RONDE, magazine du Jour</h3>
      <div className="SW_cover">
          <h5 className="uppercase text-[1.5rem] tracking-normal">Bienvenue !</h5>
          <a href="/" className="flex justify-center mml:block w-auto h-auto mt-8 mml:myt4">
              <img src="" alt="" onError={handleImageError} width="138" height="196" />
          </a>
      </div>
      <p className="SW_paragraph mt-6 text-gray-200 bg-black leading-none w-full h-full py-4 px-10 rounded-md cursor-pointer flex items-center justify-center text-center">
          <a href="/">
              Découvrez d'autres magazines de La Ronde.
          </a>
      </p>
    </div>
  );
};

export const PlusLus = ({ data }) => {
  return (
    <div className="Side_widget justify-center flex flex-col items-center mml:block text-center mml:text-left mml:mt-10 w-full mml:w-fit bg-slate-100 text-black border-t-[0.4rem] border-solid border-[#2a303b] pt-0 pb-8 px-8">
      <h3 className="SW_title text-[24px] font-bold leading-none tracking-tight mt-8">LA RONDE, Encore plus</h3>
      <div className="SW_must">
          {data && data.length > 0 ? (
            data.map((item, index) => (
              <a key={index} href={`/articles/${item.slug ? item.slug : item.title}`} className="flex justify-center mml:block w-auto h-auto">
                <span>{index + 1}</span> - {item.title}
              </a>
            ))
          ) : (
            <> * * * </>
          )}
      </div>
    </div>
  );
};

export const CardsVideo = ({ data, configurations }) => {
  return (
    <div className="card w-full relative inline-block px-[15px] mb-[30px]">
        <div className="item">
            <a href="/">
                <div className="item_header relative">
                    <span className="play font-semibold text-[20px] absolute right-4 bottom-4 text-white"><i>10:10</i><i className="fa-sharp fa-solid fa-circle-play ml-2"></i></span>
                    <img src="im.avif" alt="" onError={handleImageError} width="288" height="144" className="w-full h-full" />
                </div>
                <div className="item_body py-[10px]">
                    <span className="item_tag font-extrabold uppercase">
                        <span className="legend text-[14px]">Politique</span>
                    </span>
                    <h2 className="item_title mb-[10px] text-[22px] font-semibold leading-none">
                        <span className="badge"></span>
                        "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Id, tempore est? Nisi"
                    </h2>
                    <p className="item_content text-left"></p>
                </div>
            </a>
        </div>
    </div>
  );
}

export const CardsVideoTwo = ({ data, configurations }) => {
  return (
    <div className="card w-full relative block px-[15px]">
        <div className="item vertical ">
            <a href="http://" className="flex">
                <div className="item_body">
                    <h2 className="item_title  mb-[10px] text-[16px] sm:text-[18px] font-semibold leading-none">
                        "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Id, tempore est? Nisi"
                    </h2>
                    <p className="item_content text-left"></p>
                    <span className="item_tag font-semibold uppercase">
                        <span className="legend text-[14px]"></span>
                    </span>
                </div>
                <div className="item_header border border-solid border-y-gray-400 self-start max-w-[100px] md:max-w-[150px] flex-0 ml-[15px] relative">
                    <span className="play font-semibold text-[14px] sm:text-[18px] absolute right-4 bottom-1 text-white"><i>10:10</i><i className="fa-sharp fa-solid fa-circle-play ml-2"></i></span>
                    <img src="im.avif" alt="" onError={handleImageError} width="135" height="90" className="w-full h-full" />
                </div>
            </a>
        </div>
    </div>
  );
}

export const CardsAudio = ({ data, configurations }) => {
  return (
      <div className="card w-full relative inline-block px-[15px] mb-[30px]">
        <div className="item">
            <a href="http://" className="flex sm:block">
                <div className="item_header self-start max-w-[30%] sm:max-w-[60%] mr-[15px] relative">
                    <span className="play font-semibold text-[14px] sm:text-[18px] absolute right-4 bottom-1 text-white"><i>10:10</i><i className="fa-solid fa-microphone ml-2"></i></span>
                    <img src="im.avif" alt="" onError={handleImageError} width="329" height="160" className="w-full h-full" />
                </div>
                <div className="item_body my-0 sm:my-10">
                    <span className="item_tag font-semibold uppercase">
                        <span className="legend text-[14px]"></span>
                    </span>
                    <h2 className="item_title mb-[10px] text-[16px] md:text-[20px] font-extrabold leading-none">
                        "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Id, tempore est? Nisi"
                    </h2>
                    <p className="item_content text-left"></p>
                </div>
            </a>
        </div>
      </div>
  );
}

export const CardsFiles = ({ key, data, onChange }) => {
  const [updateDescription, setUpdateDescription] = useState(data.description)
  const [updateCheckBoxP, setUpdateCheckBoxP] = useState(data.is_public)
  const [updateCheckBoxT, setUpdateCheckBoxT] = useState(data.is_top)
  const [updateDelete, setUpdateDelete] = useState(false)
  const [link, setLink] = useState("")

  const handleDescription = (e) => {
    e.preventDefault()

    e.target.classList.remove("border-red-500")
    e.target.classList.remove("text-red-500")
    e.target.classList.add("border-blue-500")
    e.target.classList.add("text-blue-500")
    setUpdateDescription(e.target.value)
  }
  const handleCheckBoxP = (e) => {
    setUpdateCheckBoxP(e.target.checked)
  }
  const handleCheckBoxT = (e) => {
    setUpdateCheckBoxT(e.target.checked)
  }
  const handleDeleteImage = (e) => {
    e.preventDefault()
    const btn = e.target
    const parentDuParent = btn.parentElement.parentElement
    const description = parentDuParent.querySelector("#description")
    const P = parentDuParent.querySelector("#P")
    const T = parentDuParent.querySelector("#T")
    
    // Si btn est egale a true
    if (btn.value === "true") {
      // Tout mettre en visible
      P.parentElement.classList.remove("hidden")
      T.parentElement.classList.remove("hidden")
      // Changer la description en un message de confirmation
      description.value = data.description
      description.classList.remove("border-red-500")
      description.classList.remove("text-red-500")
      description.classList.add("border-blue-500")
      description.classList.add("text-blue-500")
      description.classList.add("max-w-[55%]")
      //On peut modifier la description
      description.removeAttribute("disabled")
      description.value = ''
      btn.classList.remove("border-red-500")
      btn.classList.remove("text-red-500")
      btn.parentElement.classList.remove("mx-4")
      btn.classList.add("border-blue-500")
      btn.classList.add("text-blue-500")
      setUpdateDelete(false)
    } else {
      // Tout mettre en hidden
      P.parentElement.classList.add("hidden")
      T.parentElement.classList.add("hidden")
    
      // Changer la description en un message de confirmation
      description.value = "Voulez Vous Supprimer Ce Fichier ?"
      description.classList.remove("border-blue-500")
      description.classList.remove("text-blue-500")
      description.classList.remove("max-w-[55%]")
      //On ne peut pas modifier la description
      description.setAttribute("disabled", "disabled")
      description.classList.add("border-red-500")
      description.classList.add("mr-4")
      description.classList.add("text-red-500")
      btn.classList.add("border-red-500")
      btn.classList.add("text-red-500")
      btn.parentElement.classList.add("mx-4")
      // Donner la valeur a btn 
      setUpdateDelete(true)
    }

  }
  const handleCopieLink = async (e) => {  
    e.preventDefault()
    const btn = e.target
    const link = btn.parentElement.querySelector(".link_url")
    const message = btn.parentElement.querySelector(".link_text")

    // Recuperer les valeurs de deux span
    const linkValue = link.textContent
    try {
      // Copier le contenu dans le presse-papiers
      await navigator.clipboard.writeText(linkValue);

      // Afficher un message de confirmation sans supprimer le lien
      message.textContent = "Copié !";
      message.classList.add("bg-green-500");
      message.classList.add("text-white");
    } catch (error) {
      console.error("Une erreur s'est produite lors de la copie du lien :", error);
    }
  };
  const handleModifImage = (e) => {
    const btn = e.target
    // Remonter au parent du parent pour trouver une dive avec la classe CardsFiles
    const dropDownModif = btn.parentElement.parentElement.parentElement.parentElement.querySelector(".drop_down_modif")

    if(dropDownModif.classList.contains("hidden")) {
      // Creer l'effet scroll de l'element qui doit etre invisible      
      dropDownModif.classList.remove("hidden")
    } else {
      // Creer l'effet scroll de l'element qui doit etre visible avec l'attribut tansition
      dropDownModif.classList.add("hidden")
    }
  }

  useEffect(() => {
    getImgForAffiche(data.id_entity, data.title)
    .then((res) => {
      if (res) {
          setLink(`${API_NAME}/${res.filePath}`);
        }
    })
  }, [])


  return (
    <>
      <div className="CardsFiles flex flex-col items-center w-full bg-gray-200 rounded-lg p-2 my-2 mr-2">
        <div className="flex w-full">
          <div className={`max-w-[150px] max-h-[100px] w-full bg-gray-400 rounded-lg `} style={{ background: `url(${link})` }}>
          </div>
          <div className="w-full flex flex-col items-start mx-2">
            <h4 className="text-xl font-semibold">{data.title}</h4>
            <p className="text-sm">{data.description}</p>
            <div className="w-full flex justify-between items-center">
              <button onClick={handleCopieLink} className="bea copy_link p-2 leading-none rounded font-medium mt-3 bg-gray-400 hover:bg-green-300 hover:text-white text-xs uppercase" >
                <span className="link_url hidden">{data.file_url }</span>
                <span className="link_text">Copy link</span>
              </button>
              <button className="bea modif_drop p-2 leading-none rounded font-medium mt-3 bg-gray-400 hover:bg-blue-300 hover:text-white text-xs uppercase" onClick={handleModifImage} >Modifier</button>
            </div>
          </div>
        </div>      
        <div className="transition ease-in-out delay-150 w-full my-4 drop_down_modif hidden ">
          <form className="w-full flex justify-between items-center" onSubmit={onChange} >
            <input type="text" className="drop_desc max-w-[55%] w-full p-2 block border border-yellow-400 rounded-lg" id="description" name="description" placeholder={updateDescription} onChange={handleDescription} />
            <Checkbox label="P" value={updateCheckBoxP} onChange={handleCheckBoxP} name={"P"} />
            <Checkbox label="T" value={updateCheckBoxT} onChange={handleCheckBoxT} name={"T"} />
            <div className="bea max-w-[10%] h-full w-full my-2 p-2 block uppercase border border-solid border-gray-300 hover:border-red-500 hover:text-red-500 cursor-pointer rounded-lg relative items-center justify-center text-center hover:cursor-pointer">
              <i className="fa-solid fa-trash-can"></i>
              <input type="button" className="absolute w-full h-full left-0 top-0 opacity-0" onClick={handleDeleteImage} name="supprimer" value={updateDelete} />
            </div>
            <input type="hidden" name="id_data" value={data.id_entity} />
            <input type="hidden" name="title" value={data.title} />
            <div className="bea max-w-[10%] h-full w-full my-2 p-2 block uppercase border hover:border-green-400 hover:text-green-400 cursor-pointer rounded-lg relative items-center justify-center text-center bea">
              <i className="fa-regular fa-paper-plane"></i>
              <input type="submit" className="absolute w-full h-full left-0 top-0" value={""} />
            </div>
          </form>
        </div>
      </div>
    </>
  );
} 

export const CardsAudioTwo = ({ data, configurations }) => {
  return (
        <div className="card w-full relative inline-block px-[15px]">
          <div className="item">
              <a href="http://">
                  <div className="item_header self-start mr-[15px] relative">
                  </div>
                  <div className="item_body">
                      <span className="item_tag font-semibold uppercase inline">
                          <span className="legend text-[16px] md:text-[16px]">Politique</span>:
                      </span>
                      <h2 className="item_title mb-[10px] text-[15px] md:text-[18px] font-extrabold leading-none inline">
                          "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Id, tempore est? Nisi"
                      </h2>
                      <p className="item_content text-left"></p>
                      <span className="flex justify-between">
                          <span className="text-[14px]">Publié le 17 mai 2023 à 13h00</span><span>
                              <span className="play font-semibold text-[14px] sm:text-[16px] text-black"><i>10:10</i><i className="fa-solid fa-microphone ml-2"></i></span></span>
                      </span>
                  </div>
              </a>
          </div>
        </div>
  );
}

export const CardsAdminer = ( { key, data, onActive, onDelete, } ) => {

  const handleButtonClick = (event) => {
    const buttonRelative = event.currentTarget;    
    const parent = buttonRelative.parentNode;
    const children = parent.children;
    const divChild = children[1];
    if (divChild.classList.contains('hidden')) {
        divChild.classList.remove('hidden');
    } else {
        divChild.classList.add('hidden');
    }
    event.stopPropagation(); // Empêche la propagation du clic au document
  };


  return (
    <div key={key} className="w-full mx-auto bg-white shadow-lg rounded-sm border border-gray-200 ">
      <div className={`border border-solid ${data.active ? 'border-green-200' : 'border-red-200'}`}>
        <div className="flex flex-col h-full">
            <div className="flex-grow p-5">
                <div className="flex justify-between items-start">
                    <header>
                        <div className="flex mb-2">
                            <a className="relative inline-flex items-start mr-5" href="#0">
                                <div className="absolute top-0 right-0 -mr-2 bg-white rounded-full shadow" aria-hidden="true">
                                    <svg className="w-8 h-8 fill-current text-yellow-500" viewBox="0 0 32 32">
                                        <path d="M21 14.077a.75.75 0 01-.75-.75 1.5 1.5 0 00-1.5-1.5.75.75 0 110-1.5 1.5 1.5 0 001.5-1.5.75.75 0 111.5 0 1.5 1.5 0 001.5 1.5.75.75 0 010 1.5 1.5 1.5 0 00-1.5 1.5.75.75 0 01-.75.75zM14 24.077a1 1 0 01-1-1 4 4 0 00-4-4 1 1 0 110-2 4 4 0 004-4 1 1 0 012 0 4 4 0 004 4 1 1 0 010 2 4 4 0 00-4 4 1 1 0 01-1 1z" />
                                    </svg>
                                </div>
                                <img className="rounded-full" width="64" height="64" alt="User 01" />
                            </a>
                            <div className="mt-1 pr-1">
                                <a className="inline-flex text-gray-800 hover:text-gray-900" href="#0">
                                    <h2 className="text-xl leading-snug justify-center font-semibold">{data.user.username}</h2>
                                </a>
                                <div className="flex items-center"><span className="text-sm font-medium text-gray-400 -mt-0.5 mr-1">-&gt;</span> <span></span></div>
                            </div>
                        </div>
                    </header>
                    <div className="relative inline-flex flex-shrink-0">
                        <button onClick={handleButtonClick} className="text-gray-400 hover:text-gray-500 rounded-full focus:outline-none focus-within:ring-2">
                            <span className="sr-only">Menu</span>
                            <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32">
                                <circle cx="16" cy="16" r="2" />
                                <circle cx="10" cy="16" r="2" />
                                <circle cx="22" cy="16" r="2" />
                            </svg>
                        </button>
                        <div className="hidden origin-top-right z-10 absolute top-full right-0 min-w-[9rem] bg-white border border-gray-200 py-1.5 rounded shadow-lg overflow-hidden mt-1">
                            <ul>
                                <li>
                                    <button className="w-full font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3" onClick={onActive} id={data._id} >Desac</button>
                                </li>
                                <li>
                                    <a className="font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3" href={`/admin/team/edit${data._id}`} >Modif</a>
                                </li>
                                <li>
                                    <button className="w-full font-medium text-sm text-red-500 hover:text-red-600 flex py-1 px-3" onClick={onDelete} id={data._id} >Supp</button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="mt-2">
                    <div className="text-sm"> {sliceString(data.description, 0, 150)} </div>
                </div>
            </div>
            <div className="border-t border-gray-200">
                <div className="flex divide-x divide-gray-200r">
                    <a className="block flex-1 text-center text-sm text-indigo-500 hover:text-indigo-600 font-medium px-3 py-4" href="#0">
                        <div className="flex items-center justify-center">
                            <svg className="w-4 h-4 fill-current flex-shrink-0 mr-2" viewBox="0 0 16 16">
                                <path d="M8 0C3.6 0 0 3.1 0 7s3.6 7 8 7h.6l5.4 2v-4.4c1.2-1.2 2-2.8 2-4.6 0-3.9-3.6-7-8-7zm4 10.8v2.3L8.9 12H8c-3.3 0-6-2.2-6-5s2.7-5 6-5 6 2.2 6 5c0 2.2-2 3.8-2 3.8z" />
                            </svg>
                            <span>Send Email</span>
                        </div>
                    </a>
                    <a className="block flex-1 text-center text-sm text-gray-600 hover:text-gray-800 font-medium px-3 py-4 group" href={`/admin/team/edit/${data._id}`}>
                        <div className="flex items-center justify-center">
                            <svg className="w-4 h-4 fill-current text-gray-400 group-hover:text-gray-500 flex-shrink-0 mr-2" viewBox="0 0 16 16">
                                <path d="M11.7.3c-.4-.4-1-.4-1.4 0l-10 10c-.2.2-.3.4-.3.7v4c0 .6.4 1 1 1h4c.3 0 .5-.1.7-.3l10-10c.4-.4.4-1 0-1.4l-4-4zM4.6 14H2v-2.6l6-6L10.6 8l-6 6zM12 6.6L9.4 4 11 2.4 13.6 5 12 6.6z" />
                            </svg>
                            <span>Edit Profile</span>
                        </div>
                    </a>
                </div>
            </div>
        </div>
      </div>
    </div>
  );
}

export const CardsAdminRubrique = ( { key, data } ) => {
  let slug = data.name; let category = "";
  if(data.slug) {
    slug = data.slug;
  }

  category = data.id_parent_categories.length === 0 ? "rubriques" : "sous_rubriques";
  let url = "/admin/" + category + "/edit/" + slug;
  return (
    <a key={key} href={url} className="">
      <div className="card--4 flex items-center bg-white shadow-xl rounded-3xl p-4 hover:bg-gray-100">
          <div className="w-20 h-20">
          <img src="" alt="" className="rounded-2xl object-cover w-full h-full"  onError={handleImageError}/>
          </div>
          <div className="ml-4">
          <h2 className="text-lg text-gray-900 font-semibold capitalize">{data.name}</h2>
          <p className="text-gray-400 mt-1 mb-2">Sous titre</p>
          </div>
      </div>
    </a>
  )
}

export const CardsAdminArticle = ( { data } ) => {

  const [link, setLink] = useState("");
  const [linkFile, setLinkFile] = useState("");

  useEffect(() => {
    
    getImgBy(data._id)
      .then((data) => {
        if (data.length > 0) {
          data.forEach((item) => {
            if (item.is_top) {
              getImgForAffiche(data.id_entity, data.title)
              .then((res) => {
                if (res) {
                    setLinkFile(`${API_NAME}/${res.filePath}`);
                  }
              })
            }
          });
        }
      })


    data.slug ? setLink(`/admin/projects/edit/${data.slug}`) : setLink(`/admin/projects/edit/${data.title}`);

  }, []);
  

  return (
    <a  href={link} className="">
      <div className="w-auto h-50 overflow-hidden shadow-md flex card text-grey-darkest rounded-xl hover:bg-gray-100">
          <div 
            className="w-1/3 min-h-[100%] overflow-hidden bg-cover bg-no-repeat" 
            style={{backgroundImage: `url(${linkFile})`}}>
          </div>
          <div className="w-full flex flex-col">
              <div className="p-4 pb-0 flex-1">
                  <h3 className="font-light mb-1 text-grey-darkest">{sliceString(data.title, 0)}</h3>
                  {/* <div className="text-xs flex items-center mb-4">
                      <i className="fa-solid fa-layer-group"></i>
                      <span className="ml-4">
                          {data.category_id}
                      </span>
                  </div> */}
                  <div className="flex items-center mt-4">
                      <div className="text-xs">
                          <i className="fa-solid fa-file-lines"></i>
                          <span className="ml-4">
                              {sliceString(data.content, 0, 100)}
                          </span>
                      </div>
                  </div>
              </div>
              <div className="bg-grey-lighter p-3 flex items-center justify-between transition hover:bg-grey-light">
                  Voir Plus
                  <i className="fas fa-chevron-right"></i>
              </div>
          </div>
      </div>
    </a>
  );
}

export const CardsAdminStat = ( { key, data } ) => {
  return (
    <div className="w-auto bg-gray-50 shadow-xl p-4 rounded-xl">
      <div className="flex items-center">
          <span className="flex items-center justify-center w-6 h-6 rounded-full bg-pink-100">
              <svg className="w-4 h-4 stroke-current text-pink-600"  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
          </span>
          <span className="ml-2 text-sm font-medium text-gray-500">Followers</span>
      </div>
      <span className="block text-3xl font-semibold mt-4">0</span>
      <div className="flex text-xs mt-3 font-medium">
          <span className="text-green-500">+0%</span>
          <span className="ml-1 text-gray-500">last 0 days</span>
      </div>
    </div>
  )
}