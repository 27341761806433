import React, { useContext, useEffect, useState } from "react";

import { Head } from "../Wrapper/Head";
import { fileType } from "../../utils/othersFunction";
import handleImageError from "../../utils/handleImageError";
import { AuthContext } from '../../components/others/AuthContext';
import Cookies from "js-cookie";

const Header = () => {
    const [file, setFile] = useState({});

    const { logoutAdmin } = useContext(AuthContext);

    const [updateId, setUpdateId] = useState('');
    const [updateUsername, setUpdateUsername] = useState('');
    const [updateRole, setUpdateRole] = useState('');
    const [authenticated, setAuthenticated] = useState(false);
    

    useEffect(() => {
        const id = Cookies.get('id');
        const username = Cookies.get('username');
        const role = Cookies.get('role');
        const authenticated = Cookies.get('authenticated');
        setUpdateId(id);
        setUpdateUsername(username);
        setUpdateRole(role);
        setAuthenticated(authenticated);

    }, []);
 
    
    useEffect(() => {

        const handleProfilDropdownClick = () => {
        const profilDropdown = document.querySelector('.Profil_dropdown');
        if (profilDropdown) {
            const dropdown = profilDropdown.querySelector('.Dropdown');
            if (dropdown.classList.contains('hidden')) {
            dropdown.classList.remove('hidden');
            } else {
            dropdown.classList.add('hidden');
            }
        }
        };
    
        const handleSideToggleClick = () => {
        const side = document.querySelector('.Side');
        const nav = document.querySelector('.Nav');
        const slideOverlay = document.querySelector('.Side_overlay');
        if (side.classList.contains('block')) {
            nav.classList.remove('max-w-[calc(100%-260px)]');
            side.classList.remove('block');
            side.classList.remove('mml:block');
            side.classList.add('hidden');
        } else {
            nav.classList.add('max-w-[calc(100%-260px)]');
            side.classList.remove('hidden');
            side.classList.add('mml:block');
            side.classList.add('block');
        }
        };
    
        const handleDocumentClick = (event) => {
        const target = event.target;
        const inputLink = target.closest('.Input_link');
        if (inputLink) {
            const parent = inputLink.parentNode;
            const children = parent.children;
            const child = children[1];
            if (child != null && child.classList.contains('max-h-0')) {
            const divs = document.querySelectorAll('li > a + div');
            for (let i = 0; i < divs.length; i++) {
                divs[i].classList.add('max-h-0');
            }
            setTimeout(function () {
                child.classList.add('transition-all', 'duration-300');
            }, 0);
            child.classList.remove('max-h-0');
            child.classList.add('max-h-auto');
            } else if (child != null) {
            child.classList.remove('transition-all', 'duration-300');
            child.classList.add('max-h-0');
            }
        }
    
        const buttonRelative = Array.from(document.querySelectorAll('.relative > button'));
        const isButtonClicked = buttonRelative.some(function (button) {
            return button.contains(event.target);
        });
    
        if (!isButtonClicked) {
            buttonRelative.forEach(function (button) {
            const parent = button.parentNode;
            const children = parent.children;
            const divChild = children[1];
            divChild.classList.add('hidden');
            });
        }
        };
    
        document.addEventListener('click', handleDocumentClick);
    
        const profilDropdown = document.querySelector('.Profil_dropdown');
        if (profilDropdown) {
            profilDropdown.addEventListener('click', handleProfilDropdownClick);
        }
    
        const sideToggle = document.querySelector('.Side_toggle');
        if (sideToggle) {
        sideToggle.addEventListener('click', handleSideToggleClick);
        }

        
        return () => {
            document.removeEventListener('click', handleDocumentClick);
            if (profilDropdown) {
                profilDropdown.removeEventListener('click', handleProfilDropdownClick);
            }
            if (sideToggle) {
                sideToggle.removeEventListener('click', handleSideToggleClick);
            }
        };
    }, []);


    const handleFormSubmit = (event) =>{
        event.preventDefault();
        console.log("ICI")
    }

    const handleChangeInput = (e) => {      
        if(e.target.files.length > 0){
            let file_name = e.target.files[0].name;
            // Si le fichier est un fichier texte ou docx
            if(fileType(file_name) === "doc"){
                document.querySelector('.file_name').innerText = file_name;
            }else {
                document.querySelector('.file_name').innerText = 'Just a file text or docx';
            }
        }    
    }

    const handleLogoutAdmin = () => {
        logoutAdmin();
        window.location.href = "/";
    }


    return (
        <>
            <div className="w-full h-full ">
                <div className="Side hidden mml:block max- top-0  bg-gray-200 text-blue-100 w-64 fixed inset-y-0 left-0 transform transition duration-200 ease-in-out z-10">

            
                    <Head />

                    <nav className="px-4 pt-4 scroller overflow-y-auto max-h-[calc(100vh-64px)] ">
                        <ul className="flex flex-col space-y-2">
            
                            <li className="text-sm text-gray-500 ">
                                <a href="/admin"
                                    className="Input_link flex items-center w-full py-1 px-2 rounded relative hover:text-white hover:bg-gray-700 ">
                                    <div className="pr-2">
                                        <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                                        </svg>
                                    </div>
                                    <div>Dashboard </div>
                                </a>
                            </li>
            
                            <div className="section border-b pt-4 mb-4 text-xs text-gray-600 border-gray-700 pb-1 pl-3">
                                Work
                            </div>
            
                            <li className="text-sm text-gray-500 ">
                                <a href={`/`}
                                    className="Input_link flex items-center w-full py-1 px-2 rounded relative hover:text-white hover:bg-gray-700 ">
                                    <div className="pr-2">
                                        <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
                                        </svg>
                                    </div>
                                    <div>Tasks </div>
                                </a>
                            </li>
            
                            <li className="text-sm text-gray-500 ">
                                <a href={`/`}
                                    className=" Input_link flex items-center w-full py-1 px-2 rounded relative hover:text-white hover:bg-gray-700 ">
                                    <div className="pr-2">
                                        <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                    </div>
                                    <div>Errors & Bugs </div>
                                </a>
                            </li>
            
                            <div className="section border-b pt-4 mb-4 text-xs text-gray-600 border-gray-700 pb-1 pl-3">
                                Managment
                            </div>
            
                            <li className="text-sm text-gray-500 ">
                                <section
                                    className="Input_link flex items-center w-full py-1 px-2 rounded relative hover:text-white hover:bg-gray-700 cursor-pointer">
                                    <div className="pr-2">
                                        <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                                        </svg>
                                    </div>
                                    <div>Team</div>
                                    <div className="absolute right-1.5 transition-transform duration-300">
                                        <svg className=" h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                d="M19 9l-7 7-7-7" />
                                        </svg>
                                    </div>
                                </section>
                                {updateRole && updateRole !== 'undefined' ? (
                                    <div className="pl-4 pr-2 overflow-hidden transition-all transform translate duration-300 max-h-0 ">
                                        <ul className="flex flex-col mt-2 pl-2 text-gray-500 border-l border-gray-700 space-y-1 text-xs">
                                            <li className="text-sm text-gray-500 ">
                                                <a href="/admin/team/list"
                                                    className="flex items-center w-full py-1 px-2 rounded relative hover:text-white hover:bg-gray-700">
                                                    <div> Users List </div>
                                                </a>
                                            </li>
                                            <li className="text-sm text-gray-500 ">
                                                <a href="/admin/team/create"
                                                    className="flex items-center w-full py-1 px-2 rounded relative hover:text-white hover:bg-gray-700">
                                                    <div> Create User </div>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                ):(
                                    <></>
                                )}
                            </li>
            
                            <li className="text-sm text-gray-500 ">
                                <section
                                    className="Input_link flex items-center w-full py-1 px-2 rounded relative hover:text-white hover:bg-gray-700 cursor-pointer">
                                    <div className="pr-2">
                                        <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z" />
                                        </svg>
                                    </div>
                                    <div>Projects</div>
                                    <div className="absolute right-1.5 transition-transform duration-300">
                                        <svg className=" h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                d="M19 9l-7 7-7-7" />
                                        </svg>
                                    </div>
                                </section>
                                <div className="pl-4 pr-2 overflow-hidden transition-all transform translate duration-300 max-h-0 ">
                                    <ul className="flex flex-col mt-2 pl-2 text-gray-500 border-l border-gray-700 space-y-1 text-xs">
                                        <li className="text-sm text-gray-500 ">
                                            <a href="/admin/projects/list"
                                                className="flex items-center w-full py-1 px-2 rounded relative hover:text-white hover:bg-gray-700">
                                                <div>List </div>
                                            </a>
                                        </li>
                                        <li className="text-sm text-gray-500 ">
                                            <a href="/admin/projects/create"
                                                className="flex items-center w-full py-1 px-2 rounded relative hover:text-white hover:bg-gray-700">
                                                <div> Create Project </div>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </li>
            
            
                            <li className="text-sm text-gray-500 ">
                                <section
                                    className="Input_link flex items-center w-full py-1 px-2 rounded relative hover:text-white hover:bg-gray-700 cursor-pointer">
                                    <div className="pr-2">
                                        <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
                                        </svg>
                                    </div>
                                    <div>Rubriques</div>
                                    <div className="absolute right-1.5 transition-transform duration-300">
                                        <svg className=" h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                d="M19 9l-7 7-7-7" />
                                        </svg>
                                    </div>
                                </section>
            
            
                                <div className="pl-4 pr-2 overflow-hidden transition-all transform translate duration-300 max-h-0 ">
                                    <ul className="flex flex-col mt-2 pl-2 text-gray-500 border-l border-gray-700 space-y-1 text-xs">
                                        <li className="text-sm text-gray-500 ">
                                            <a href="/admin/all_rubriques/list"
                                                className="flex items-center w-full py-1 px-2 rounded relative hover:text-white hover:bg-gray-700">
                                                <div>List </div>
                                            </a>
                                        </li>
                                        <li className="text-sm text-gray-500 ">
                                            <a href="/admin/rubriques/list"
                                                className="flex items-center w-full py-1 px-2 rounded relative hover:text-white hover:bg-gray-700">
                                                <div>Rubriques List </div>
                                            </a>
                                        </li>
                                        <li className="text-sm text-gray-500 ">
                                            <a href="/admin/sous_rubriques/list"
                                                className="flex items-center w-full py-1 px-2 rounded relative hover:text-white hover:bg-gray-700">
                                                <div>Sous-Rubriques List </div>
                                            </a>
                                        </li>
                                        <li className="text-sm text-gray-500 ">
                                            <a href="/admin/rubriques/create"
                                                className="flex items-center w-full py-1 px-2 rounded relative hover:text-white hover:bg-gray-700">
                                                <div> Create Rubriques </div>
                                            </a>
                                        </li>
                                        <li className="text-sm text-gray-500 ">
                                            <a href="/admin/sous_rubriques/create"
                                                className="flex items-center w-full py-1 px-2 rounded relative hover:text-white hover:bg-gray-700">
                                                <div> Create Sous Rubriques </div>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </nav>
                    
                    <div className="h-full ">
                        <form className="w-[90%] mx-auto h-full flex flex-col items-center justify-center" onSubmit={handleFormSubmit}>
                            <div className="parent-container p-4 max-w-[100%] w-full cursor-pointer rounded-xl bg-gray-100 border-dashed border-2 border-gray-400 flex justify-center items-center relative">
                                <input type="file" id="input-file" className="absolute inset-0 opacity-0 cursor-pointer" onChange={handleChangeInput}/>
                                <div className="w-64 h-20 flex justify-center items-center">
                                    <div className="mr-2">
                                        <svg className="upload-icon bi bi-cloud-arrow-up-fill" xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" viewBox="0 0 16 16">
                                        <path d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2zm2.354 5.146a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2z"/>
                                        </svg>
                                    </div>
                                    <div>
                                        <p className="text-gray-500 mb-1 text-lg file_name">Upload your fie</p>
                                        <p className="text-xs text-gray-500"><span className="text-blue-500">choose</span> or drag and drop</p>
                                    </div>
                                </div>
                            </div>
                            <button type="submit" className="my-4 p-4 block uppercase border border-green-200 hover:border-green-400 text-gray-400 hover:text-black rounded-xl">
                                Enregistrer 
                            </button>
                        </form>
                    </div>

                </div>
                <div className="Side_overlay hidden absolute   inset-0 bg-black opacity-20 "></div>

                <div className="flex-1 flex-col flex absolute right-0 left-0" style={{zIndex:"11"}}>
                    <nav className="shadow-xl sticky w-full top-0 text-black z-30 flex justify-end">
                        <div className="Nav w-[98%] mml:max-w-[calc(100%-260px)]">
                            <div className="flex items-stretch justify-between h-16">
                                <div className="flex items-center">
                                    <div className="Side_toggle text-gray-800 cursor-pointer block">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                                            stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                d="M4 6h16M4 12h16M4 18h7" />
                                        </svg>
                                    </div>
            
                                    <div className="block">
                                        <form action="" className="app-search" method="GET">
                                            <div className="relative group ">
                                                <input type="text"
                                                    className="form-input rounded-md bg-gray-200 text-sm text-gray-800 pl-10 py-1.5 ml-5 border-transparent border-none outline-none focus:ring-0 focus:text-gray-800 transition-all duration-300 ease-in-out focus:w-60 w-48"
                                                    placeholder="Search..." autoComplete="off" />
                                                <span
                                                    className="absolute left-44 bottom-2 text-gray-600 transition-all duration-300 ease-in-out group-focus-within:left-8">
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none"
                                                        viewBox="0 0 24 24" stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                                    </svg>
                                                </span>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="flex items-stretch">
                                    <div className="ml-4 flex md:ml-6 ">
                                        <div className="relative flex justify-center items-center mr-4">
                                            <div className="block hover:bg-gray-800 hover:text-white p-1 rounded-full text-gray-600">
                                                <span className="sr-only">View notifications</span>
                                                <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none"
                                                    viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                        d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                                                </svg>
                                            </div>
                                        </div>
            
                                        <div className="Profil_dropdown relative hover:bg-gray-50 px-4 text-gray-400 hover:text-gray-800 text-sm cursor-pointer">
                                            <div className="flex items-center min-h-full">
                                                <div className="max-w-xs rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                                                    id="user-menu-button" aria-expanded="false" aria-haspopup="true">
                                                    <span className="sr-only">Open user menu</span>
                                                    <img className="h-8 w-8 rounded-full"
                                                        src=""
                                                        alt="" 
                                                        onError={handleImageError} 
                                                    />
                                                </div>
                                                <div className="hidden flex-col ml-4 sm:flex">
                                                    <span>{updateUsername}</span>
                                                    <span>Admin</span>
                                                </div>
                                            </div>
                                            <div className="Dropdown hidden origin-top-right absolute right-0 mt-0 min-w-full rounded-b-md shadow py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none w-[150px] sm:w-auto">
                                                <a href={`/admin/team/edit/${updateId}`} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                                    role="menuitem" tabIndex="-1" id="user-menu-item-0">My Profile</a>
            
                                                <button onClick={handleLogoutAdmin} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                                    role="menuitem" tabIndex="-1" id="user-menu-item-1">Sign
                                                    out</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </>
    );
}

export default Header;
