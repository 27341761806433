// FONCTION UTILITAIRE
export const toggleHidden = (element) => {
    element.classList.toggle("hidden");
  }
  
export const toggleElement = (selectorOrElement) => {
    const elements =
      typeof selectorOrElement === "string"
        ? document.querySelectorAll(selectorOrElement)
        : [selectorOrElement];
  
    elements.forEach((element) => {
      if (element.classList.contains("hidden")) {
        element.classList.remove("hidden");
      } else {
        element.classList.add("hidden");
      }
    });
  }
  
export const toggleClass = (selectorOrElement, className) => {
    const elements =
      typeof selectorOrElement === "string"
        ? document.querySelectorAll(selectorOrElement)
        : [selectorOrElement];
  
    elements.forEach((element) => {
      element.classList.toggle(className);
    });
}

export const SplitUrl = (currentUrl) => {
      // Récuperer l'id de la page et le nom de la page du style: /rubriques/education_1 ou /rubriques/education-1 id = 1, name = education
      // si l'url est /rubriques/education_1, on remplace le _ par un -
      if (currentUrl.includes("_")) {
        currentUrl = currentUrl.replace("_", "-");
      }
      const id = currentUrl.split("/")[4].split("-")[1];
      const name = currentUrl.split("/")[4].split("-")[0];
      return {id, name};
}

export const addCustomStyleSheet = ( href ) => {
  const header = document.querySelector('head');
  const link = document.createElement('link');
  link.rel = 'stylesheet';
  link.href = href;
  header.insertBefore(link, header.firstChild);
}

export const titleToSlug = (title) => {
  if(title === undefined) return "";
  // Remplacer les espaces par des tirets et suppression des caractères spéciaux
  let slug = title.replace(/\s+/g, '-').toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");

  // Remplacer les lettres accentuées
  const accent = {
    a: /[àáâãäå]/g,
    ae: /æ/g,
    c: /[ç]/g,
    e: /[èéêë]/g,
    i: /[ìíîï]/g,
    n: /[ñ]/g,
    o: /[òóôõö]/g,
    oe: /œ/g,
    u: /[ùúûü]/g,
    y: /[ýÿ]/g,
  };

  for (const letter in accent) {
    slug = slug.replace(accent[letter], letter);
  }

  return slug;
}

export const parseToDate = (date, outPut) => {
  // Si la date est égale à undefined ou null, on retourne la date du jour
  if (!date) { return new Date(); } 
  const formats = [
    // 31 Dec(autre lettres) 2022
    { format: /^(\d{1,2})[- \/](\w{3,})[- \/](\d{4})$/, groups: [1, (month) => convertMonthNameToNumber(month), 3]},
  
    // 31 Dec(autre lettres) 22
    { format: /^(\d{1,2})[- \/](\w{3,})[- \/](\d{2})$/, groups: [1, (month) => convertMonthNameToNumber(month), (year) => convertToFourDigitYear(year)] },
  
    // Dec(autre lettres)  31 22
    { format: /^(\w{3,})[- \/](\d{1,2})[- \/](\d{2})$/, groups: [1, (month) => convertMonthNameToNumber(month), (year) => convertToFourDigitYear(year)] },
  
    // 31/12/2022
    { format: /^(\d{1,2})[- \/](\d{1,2})[- \/](\d{4})$/, groups: [1, 2, 3] },

    // 31/12/22
    { format: /^(\d{1,2})[- \/](\d{1,2})[- \/](\d{2})$/, groups: [1, 2, (year) => convertToFourDigitYear(year)] },

    // 2022-12-31
    { format: /^(\d{4})[- \/](\d{1,2})[- \/](\d{1,2})$/, groups: [3, 2, 1] },

    //2023-05-05T03:48:48.099Z
    { format: /^(\d{4})[- \/](\d{1,2})[- \/](\d{1,2})T(\d{1,2}):(\d{1,2}):(\d{1,2}).(\d{1,3})Z$/, groups: [3, 2, 1] },
  ];
  
  for (const { format, groups } of formats) {
    const match = date.match(format);
    if (match) {
      
      const year = typeof groups[2] === 'function' ? groups[2](match[3]) : match[groups[2]];
      const month = typeof groups[1] === 'function' ? groups[1](match[2]) : parseInt(match[groups[1]], 10).toString().padStart(2, '0');
      const day = parseInt(match[groups[0]], 10).toString().padStart(2, '0');

      if (outPut === 'date') return new Date(`${year}-${month}-${day}`);
      if (outPut === undefined || outPut === null){
        return `${day}/${month}/${year}`;
      }else{
        // Formater la date
        if (outPut.toLowerCase() === 'dd/mm/yyyy'.toLowerCase()) return `${day}/${month}/${year}`;
        if (outPut.toLowerCase() === 'dd-mm-yyyy'.toLowerCase()) return `${day}-${month}-${year}`;
        if (outPut.toLowerCase() === 'dd/mm/yy'.toLowerCase()) return `${day}/${month}/${year.slice(-2)}`;
        if (outPut.toLowerCase() === 'dd-mm..-yyyy'.toLowerCase()) return `${day}-${convertMonthNumberToName(month)}-${year}`;
        if (outPut.toLowerCase() === 'mm/dd/yyyy'.toLowerCase()) return `${month}/${day}/${year}`;
        if (outPut.toLowerCase() === 'mm-dd-yyyy'.toLowerCase()) return `${month}-${day}-${year}`;
        if (outPut.toLowerCase() === 'yyyy-mm-dd'.toLowerCase()) return `${year}-${month}-${day}`;
        if (outPut.toLowerCase() === 'yyyy/mm/dd'.toLowerCase()) return `${year}/${month}/${day}`;
        if (outPut.toLowerCase() === 'yyyy-mm-ddThh:mm:ssZ'.toLowerCase()) return `${year}-${month}-${day}T00:00:00Z`;
      }
    }
  }
  return null;
};

export const fileType = (file) => {
  const extension = file.split('.').pop().toLowerCase();
  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'svg'];
  const videoExtensions = ['mp4', 'avi', 'mov', 'wmv', 'flv', 'webm'];
  const audioExtensions = ['mp3', 'wav', 'ogg', 'wma', 'flac', 'aac'];
  const documentExtensions = ['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'pdf', 'txt', 'rtf', 'odt', 'odp', 'ods'];

  if (imageExtensions.includes(extension)) return 'image';
  if (videoExtensions.includes(extension)) return 'video';
  if (audioExtensions.includes(extension)) return 'audio';
  if (documentExtensions.includes(extension)) return 'doc';

  return 'file';
};

export const getFirstParagraph = (content) => {
  if (typeof content !== 'string') {
    console.error('Expected a string but received:', content);
    return '';
  }
  
  const regex = /<p(.*?)>(.*?)<\/p>/g;
  const paragraphs = content.match(regex);
  if (paragraphs && paragraphs.length > 0) {
    return paragraphs[0];
  }
  return '';
};


export const getFirstParagraphAndDelete = (content) => {
  if (!content) return '';
  
  // Le paragraphe peut avoir des attributs style, class, id, etc...
  const regex = /<p(.*?)>(.*?)<\/p>/g;
  const paragraphs = content.match(regex);
  if (paragraphs && paragraphs.length > 0) {
    return content.replace(paragraphs[0], '');
  }
  return '';
};

export const getContentWithoutFirstParagraph = (content) => {
  // Le paragraphe peut avoir des attributs style, class, id, etc...
  const regex = /<p(.*?)>(.*?)<\/p>/g;
  const paragraphs = content.match(regex);
  if (paragraphs && paragraphs.length > 0) {
    return content.replace(paragraphs[0], '');
  }
  return '';
};


const convertToFourDigitYear = (shortYear) => {
  const currentYear = new Date().getFullYear();
  const shortYearInt = parseInt(shortYear, 10);
  const century = Math.floor(currentYear / 100) * 100;
  const fullYear = century + shortYearInt;
  return fullYear.toString();
};

const convertMonthNameToNumber = (monthName) => {
  if (!monthName) {
    console.log('Month name is required', monthName);
    return monthName;
  }
  const months = {
    jan: '01',
    feb: '02',
    mar: '03',
    apr: '04',
    may: '05',
    jun: '06',
    jul: '07',
    aug: '08',
    sep: '09',
    oct: '10',
    nov: '11',
    dec: '12',
    janvier: '01',
    février: '02',
    mars: '03',
    avril: '04',
    mai: '05',
    juin: '06',
    juillet: '07',
    août: '08',
    septembre: '09',
    octobre: '10',
    novembre: '11',
    décembre: '12',

  };

  const normalizedMonthName = monthName.toLowerCase();
  return months[normalizedMonthName] || monthName;
};

const convertMonthNumberToName = (monthNumber) => {
  if (!monthNumber) {
    console.log('Month number is required', monthNumber);
    return monthNumber;
  }
  const months = {
    1: 'janvier',
    2: 'février',
    3: 'mars',
    4: 'avril',
    5: 'mai',
    6: 'juin',
    7: 'juillet',
    8: 'août',
    9: 'septembre',
    10: 'octobre',
    11: 'novembre',
    12: 'décembre',
  };

  // monthNumber is a string, so we need to convert it to a number
  const normalizedMonthNumber = parseInt(monthNumber, 10);
  return months[normalizedMonthNumber] || monthNumber;
};


export const deleteDoubleItem = (array) => {
  return array.filter((item, index) => array.indexOf(item) === index);
}

/*        =====================================           */
