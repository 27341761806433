import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import HomeRoute from './HomeRoute';
import RubriquesRoute from './RubriquesRoute';
import SousRubriquesRoute from './SousRubriquesRoute';
import ArticlesRoute from './ArticlesRoute';
import NotFoundPage from './NotFoundPage';
import UsersRoute from './UsersRoute';
import AdminHomeRoute from './AdminHomeRoute.js';



function App() {
  
  return (
    <div className='overflow-hidden'>
      <Routes>
            <Route path="/users/*" element={<UsersRoute />} />
            <Route path="/rubriques/*" element={<RubriquesRoute />} />
            <Route path="/sousrubriques/*" element={<SousRubriquesRoute />} />
            <Route path="/articles/*" element={<ArticlesRoute />} />
            <Route path="/admin/*" element={<AdminHomeRoute />} />
            <Route path="/" element={<HomeRoute />} />
            <Route path='*' element={<NotFoundPage />} />
            <Route path='/notfound' element={<NotFoundPage />} />
        {/* Autres routes */}
      </Routes>
    </div>
  );
}

export default App;
