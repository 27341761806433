import React, { useEffect } from "react";

import { SousRubrique } from "./sousrubrique"
import { CardsImgRight } from "../../cards/cards";
import { getAllRubriques } from "../../../controllers/rubriqueControllers";
import { getAllWithCondition } from "../../../controllers/AllWithCondition";

/**
 * 
 * @param {string} nameSousRubrique
 * @returns
 * @description SousRubriques Permet de lister les sous-rubriques d'une rubrique ou si nameSousRubrique est vide, de lister toutes les SousRubriques, Elle fait appel à sousrubrique.js qui permet de lister les articles d'une sous-rubrique (un teaser des quelques derniers articles)
 * 
 * 
 */
export const SousRubriques = ({ nameSousRubrique }) => {
    const [isLoading, setIsLoading] = React.useState(true);
    const [updatedSousRubriques, setUpdatedSousRubriques] = React.useState([]);
    let isRubriques = false;

    // Vérifier si on a sous_rubriques ou rubriques dans l'url
    const splitUrl = window.location.href.split("/");
    if (splitUrl.includes("sousrubriques") || splitUrl.includes("rubriques")) {
        isRubriques = true;
    }  


    useEffect(() => {
        if((nameSousRubrique === "" || nameSousRubrique === null || nameSousRubrique === undefined) && !isRubriques) {
            getAllRubriques()
            .then((data) => {
                if (data) {
                    setUpdatedSousRubriques(data);
                    setIsLoading(false);
                }
            })
        } else{
            const rubriqueOptions = {
                condition : { name: nameSousRubrique },
                populate: { path: 'id_file', select: { title: 1, created_at: 1, updated_at: 1, is_active: 1, is_top: 1, is_public: 1, entity: 1, id_entity: 1 } },
                limit: 1,
                skip: 0,
                projection: { name: 1, description: 1, slug: 1, created_at: 1, updated_at: 1, is_active: 1 },
                sort: { created_at: -1 },
            }
         
            const sousRubriqueOptions = {
                populate: { path: 'id_file', select: { title: 1, created_at: 1, updated_at: 1, is_active: 1, is_top: 1, is_public: 1, entity: 1, id_entity: 1 } },
                limit: 10,
                skip: 0,
                projection: { name: 1, description: 1, slug: 1, created_at: 1, updated_at: 1, is_active: 1, id_parent_categories: 1},
                sort: { created_at: -1 },
            };
        
            const articleOptions = "null"
        
            const fileOptions = "null"
        
            let formData = new FormData();
            formData.append('rubriqueOptions', JSON.stringify(rubriqueOptions));
            formData.append('sousRubriqueOptions', JSON.stringify(sousRubriqueOptions));
            formData.append('articleOptions', JSON.stringify(articleOptions));
            formData.append('fileOptions', JSON.stringify(fileOptions));
        
            if(nameSousRubrique !== undefined && nameSousRubrique !== null && nameSousRubrique !== ""){
                getAllWithCondition(formData)
                .then((data) => {
                    if (data) {
                        setUpdatedSousRubriques(data.sousRubriques);
                        setIsLoading(false);
                    }
                })
            }
                
        }
    }, [nameSousRubrique, isRubriques]);

    return (
        isLoading  ? (
            <>
              <>* * *</>
            </>
        ) : (
            updatedSousRubriques && updatedSousRubriques.length > 0 ? (
                <>
                {updatedSousRubriques.map((item, index) => (
                    <div className="PB_sousrubriques" key={index}>
                        {item.is_active && item.id_parent_categories.length > 0 ? (
                            <SousRubrique sousrubrique={item.name} />
                            ) : (
                                <></>
                            )}
                    </div>
                ))}
                </>
            ) : (
                <></>
            )
        )
    );
}


/**
* @param [array] articles 
* @returns 
* @description Listing permet de lister les articles d'une sous-rubrique tous les articles d'une sous-rubrique en dessous du teaser principal
*/
export const Listing = ({ articles }) => {
    return (
        articles && articles.length > 0 ? (
            <>
                <div className="Listing_article w-full mml:w-[70%]">
                    {articles.map((article, index) => (
                        <CardsImgRight data={article} />              
                    ))}
                </div>
            </>
        ) : (
            <></>
        )
    );
};

