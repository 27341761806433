import React from 'react';

const LayoutUser = ({ children }) => {
  return (
    <>
    <div className="Header fixed left-0 right-0">
        <div className="Header_center">
            <div className="Header_logo self-center pl-0 mb-O">
              <a href="/" className="w-full flex items-center justify-center py-4">
                <div className="logo_laronde h-full w-min bg-laronde bg-contain bg-center bg-no-repeat inline-block text-black text-[40px] font-extrabold min-w-[160px] min-h-[60px]"></div>
                <span className="only h-[1px] hidden p-0 absolute w-[1px]">La Ronde</span>
              </a>
            </div>
        </div>
    </div>
      <div className="content">
        {children}
      </div>
      <div className="Footer fixed left-0 right-0 bottom-0">
        <div className="Footer_center text-center pb-8">
          copyrigth 2023 - La Ronde
        </div>
      </div>
    </>  
  );
};

export default LayoutUser;
