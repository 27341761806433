import React from "react";
import { ArticleTeaserTop, ArticleTeaserMiddle, ArticleTeaserBottom } from "../../cards/articleTeaser";

const PHContent = () => {
    return (
        <div className="PH_content w-full mml:w-[65%] mb-[20px] px-15px max-w-full">
            <ArticleTeaserTop />
            <ArticleTeaserMiddle />
            <ArticleTeaserBottom />
        </div>
    );
}

export default PHContent;
