

export const applyCss = (composant, style) => {
    for (const cssClass of style) {
        composant.classList.add(cssClass);
    }
};

export const applyStyle = ( composant, {style} ) => {
    /**
     * css et react
     * {
     * composant: <div>...</div>,
     * style: {
     * backgroundColor: "red",
     * margin: "2px",
     * fontSize: "2em",
     * ...
     * }
     * }
     * 
     * 
     * result : composant.style.backgroundColor = "red";
     *         composant.style.margin = "2px";
     *        composant.style.fontSize = "2em";
     *      ...
     */

    for (const [key, value] of Object.entries(style)) {
        composant.style[key] = value;
    }

    // Exemple de l'utilisation de la fonction
    // applyStyle(composant, {style: {backgroundColor: "red", margin: "2px", fontSize: "2em"}});

    return composant;

}