import React from "react";

import onerror from '../assets/LaR.png'

export const handleImageError = (event) => {
    event.target.src = onerror; 
    event.target.alt = 'Image not found'; 
  };


export default handleImageError;