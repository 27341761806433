import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import MyRoutes from './routes';

import './styles/output.css';


const App = () => {
  useEffect(() => {
    // Si l'url contient le mot 'admin', on importe les fichiers CSS correspondants
    if (!window.location.href.includes('admin')) {
      Promise.all([
        import('./styles/output_your.css'),
        import('./styles/output.css'),
      ]).then(() => {
      })
    }
  }, []);

  return (
    <BrowserRouter>
      <MyRoutes />
    </BrowserRouter>
  );
};

export default App;
