const models = require('../models/Models');
const Services = require('../services/Services');

const EntityName = 'categories';

export const createSousRubrique = async (formeData) => {
  try {
    console.log("------------- sousRubriqueControllers.js -----------------");
    const sousRubrique = await Services.createEntity(EntityName, formeData);

    for(const pair of formeData.entries()){
      console.log(pair[0]+ ', '+ pair[1] + ", " + typeof pair[1]);
    }

    if (sousRubrique) {
      return sousRubrique;
    } else {
      console.log('Erreur lors de la création de l\'sousRubrique');
      return { 
        error: "Erreur lors de la création de l'sousRubrique"
      };
    }
  } catch (error) {
    console.log('Erreur lors de la création de l\'sousRubrique :', error);
    return {
      error: "Erreur lors de la création de l'sousRubrique"
    };
  }
};

export const getSousRubrique = async (sousRubriqueId) => {
  try {
    const sousRubrique = await Services.getEntity(EntityName, sousRubriqueId);
    if (sousRubrique) {
      return sousRubrique;
    } else {
      console.log('Erreur lors de la récupération de l\'sousRubrique');
      return {};
    }
  } catch (error) {
      console.log('Erreur lors de la récupération de l\'sousRubrique :', error);
    return {};
  }
};

export const getLimiteSousRubrique = async (limit, tri) => {
  try {
    const sousRubriques = await Services.getLimitedEntities(EntityName, limit, tri);
    if (sousRubriques && sousRubriques.length > 0) {
      return sousRubriques;
    } else {
      console.log('Erreur lors de la récupération des sousRubriques');
      return {};
    }
  } catch (error) {
    console.log('Erreur lors de la récupération des sousRubriques :', error);
    return {};
  }
};

export const updateSousRubrique = async (formData) => {
  try {
    const sousRubrique = await Services.updateEntity(EntityName, formData);
    if (sousRubrique) {
      return sousRubrique;
    } else {
      console.log('Erreur lors de la mise à jour de l\'sousRubrique');
      return {};
    }
  } catch (error) {
    console.log('Erreur lors de la mise à jour de l\'sousRubrique :', error);
    return {};
  }
};
  
export const deleteSousRubrique = async (id) => {
  try {
    const sousRubrique = await Services.deleteEntity(EntityName, id);
    console.log(sousRubrique);
    if (sousRubrique) {
      return sousRubrique;
    } else {
      console.log('Erreur lors de la suppression de l\'sousRubrique');
      return {};
    }
  } catch (error) {
      console.log('Erreur lors de la suppression de l\'sousRubrique :', error);
    return {};
  }
};
  

export const getAllSousRubriques = async() => {
  try{
    const sousRubriques = await Services.getAllEntities(EntityName);
    if (sousRubriques && sousRubriques.length > 0) {
      return sousRubriques;
    } else {
      console.log('Erreur lors de la récupération des sousRubriques');
      return {};
    }
  }catch(error){
    console.log('Erreur lors de la récupération des sousRubriques :', error);
  }
};

export const deleteImgSousRubrique = async (id, title, p) => {
  try{
    // Envoyer les données vers le services
    const response = await Services.deleteImgEntity(id, title, p);
    //Effectuez le traitement souhaité avec la response ici
    if(response){
      return response;
    } else {
      return {};
    }
  } catch (error){
    console.error("Une erreur s\'est produite lors de la suppression de l'image ", error);
  }
};

export const sendImgSousRubrique = async (file) => {
  try {
    // Envoyer les données vers le services
    const response = await Services.sendImgEntity(file);
    // Effectuez le traitement souhaité avec le fichier ici
    if (response) {
      return response;
    }else{
      return {};
    }
  } catch (error) {
    console.error('Une erreur s\'est produite lors du traitement de l\'image', error);
  }
};

export const updateImgSousRubrique = async (files) => {
  try{
    // Envoyer les données vers le services
    const response = await Services.updateImgEntity(files);
    //Effectuez le traitement souhaité avec la response ici
    if(response){
      return response;
    } else {
      return {};
    }
  } catch (error){
    console.error("Une erreur s\'est produite lors de la mise à jour de l'image ", error);
  }
};
  

// Ajoutez d'autres fonctions pour gérer les opérations liées aux sousRubriques
// par exemple : updateSousRubrique, deleteSousRubrique, getAllSousRubriques, etc.
