import React, { useEffect } from "react";
import Layout from "../views/Layout";
import { Popup_bea } from "../components/others/popUp";





const NotFoundPage = () => {

    // UseEffect pour afficher le popup seulement si la page est chargée
    useEffect(() => {

        Popup_bea("Page not found", "Vous allez être redirigé vers la page d'accueil.", "danger", ".Page_main")
        setTimeout(() => {
            window.location.href = "/";
        }, 6000);

    }, []);    

      
      return (
        <Layout>
        <div className="Page z-1 relative">
          <div className="Page_main md:max-w-[720px] mml:max-w-[991px] px-0 mx-auto mt-[2rem] bg-white">
          <div className="h-[300px] p-20 text-[30px] font-bold">
            <h1>Page not found</h1>
            <p>The page you are looking for does not exist.</p>
            {/* Autres éléments de conception ou de contenu pour la page 404 */}
            </div>
          </div>
        </div>
        </Layout>
      );
};

export default NotFoundPage;
