/* eslint-disable react/jsx-pascal-case */
import React from "react";

import PHSlide from "./PHSlide";
import PB_content from "./PB_content";

const PageBodyHome = () => {
    return (
        <div className="Page_body">
            <div className="Body_wrapper flex flex-wrap flex-row">
                <PB_content />
                <PHSlide />
            </div>
        </div>
    );
}

export default PageBodyHome;