import React, { useEffect, useState } from "react";


import { CardsTextThinTwo } from "../../cards/cards";
import { getAllWithCondition } from "../../../controllers/AllWithCondition";
import { TeaserRubrique, TeaserGridVideo, TeaserRubriqueVideo, TeaserGridPodcast, TeaserRubriquePodcast } from "../../cards/articleTeaser";

export const Rubrique = ({ rubrique }) => {

  const [isLoading, setIsLoading] = useState(true);
  const [updatedFiles, setUpdatedFiles] = useState([]);
  const [updatedRubrique, setUpdatedRubrique] = useState([]);
  const [updatedArticles, setUpdatedArticles] = useState([]);
  const [updatedSousRubriques, setUpdateSousdRubriques] = useState([]);



  useEffect(() => {

    const rubriqueOptions = {
        condition : { name: rubrique },
        populate: { path: 'id_file', select: { title: 1, created_at: 1, updated_at: 1, is_active: 1, is_top: 1, is_public: 1, entity: 1, id_entity: 1 } },
        limit: 1,
        skip: 0,
        projection: { name: 1, description: 1, slug: 1, created_at: 1, updated_at: 1, is_active: 1 },
        sort: { created_at: -1 },
    }
 
    const sousRubriqueOptions = {
        populate: { path: 'id_file', select: { title: 1, created_at: 1, updated_at: 1, is_active: 1, is_top: 1, is_public: 1, entity: 1, id_entity: 1 } },
        limit: 10,
        skip: 0,
        projection: { name: 1, description: 1, slug: 1, created_at: 1, updated_at: 1, is_active: 1 },
        sort: { created_at: -1 },
    };

    const articleOptions = {
        populate: { path: 'id_categories', select: { name: 1, description: 1, slug: 1, is_active: 1, id_parent_categories: 1 } },
        limit: 10,
        skip: 0,
        projection: { title: 1, content: 1, slug: 1, created_at: 1, updated_at: 1, is_active: 1 },
        sort: { created_at: -1 },
    };

    const fileOptions = {
        limit: 1,
        skip: 0,
        projection: { title: 1, created_at: 1, updated_at: 1, is_active: 1, is_top: 1, is_public: 1, entity: 1, id_entity: 1 },
        sort: { created_at: -1 },
        with: "articles"
    }

    let formData = new FormData();
    formData.append('rubriqueOptions', JSON.stringify(rubriqueOptions));
    formData.append('sousRubriqueOptions', JSON.stringify(sousRubriqueOptions));
    formData.append('articleOptions', JSON.stringify(articleOptions));
    formData.append('fileOptions', JSON.stringify(fileOptions));

    getAllWithCondition(formData)
        .then((data) => {
            if (data) {
                setUpdatedFiles(data.files);
                setUpdatedArticles(data.articles);
                setUpdatedRubrique(data.rubriques);
                setUpdateSousdRubriques(data.sousRubriques);
                setIsLoading(false);
            }
        }
        ).finally(() => {
            setIsLoading(false);
        });
  }, []);


  return (
      isLoading ? (
        <>
          <p></p>
        </>
      ) : (
        <>
          <div className="PB_rubrique pt-[3.2rem] mb-16 border-t-4 border-t-gray-800">
            <a
              href={`/rubriques/${updatedRubrique[0].slug}`}
              className="PB_rubrique_title relative uppercase font-semibold tracking-normal mb-5"
            >
              <h4 className="text-[2rem]">{rubrique}</h4>
            </a>
            <div className="PB_rubrique_souscats">
              {updatedSousRubriques && updatedSousRubriques.length > 0 ? (
                <div className="Souscats_wrapper flex m-0 mb-6">
                    {updatedSousRubriques.map((item, index) => (
                      <div key={index}>
                          <ListSousRubriques data={item} />
                      </div>
                    ))}
                </div>
              ) : (
                <></>
              )}
            </div>
            <RubriqueItems articles={updatedArticles} />
          </div>
        </>  
      )
  );
};

export const RubriqueVideo = ({ rubrique }) => {
  return (
    <div className="PB_rubrique pt-[3.2rem] mb-16 border-t-4 border-t-gray-800">
      <a
        href="/"
        className="PB_rubrique_title relative uppercase font-semibold tracking-normal mb-5"
      >
        <h4 className="text-[2rem]">Video</h4>
      </a>
      <div className="PB_rubrique_souscats">
        <div className="Souscats_wrapper flex m-0 mb-6">
          <ListSousRubriques />
          <ListSousRubriques />
        </div>
      </div>
      <RubriqueItemsVideo />
    </div>
  );
};

export const RubriquePodcast = ({ rubrique }) => {
  return (
    <div className="PB_rubrique pt-[3.2rem] mb-16 border-t-4 border-t-gray-800">
      <a
        href="/"
        className="PB_rubrique_title relative uppercase font-semibold tracking-normal mb-5"
      >
        <h4 className="text-[2rem]">Politique</h4>
      </a>
      <div className="PB_rubrique_souscats">
        <div className="Souscats_wrapper flex m-0 mb-6">
          <ListSousRubriques />
          <ListSousRubriques />
        </div>
      </div>
      <RubriqueItemsPodcast />
    </div>
  );
};




export const ListSousRubriques = ({ data }) => {
  return (
    <div className="Souscat_textual items-end inline-flex">
    <a
      href={data.slug ? `/sousrubriques/${data.slug}` : `/sousrubriques/${data.name}` }
      className="Link_textual tracking-wider text-[1.3rem] leading-6 uppercase text-gray-500"
    >
      {data.name}
    </a>
  </div>
  );
};

export const RubriqueItems = ({ articles }) => {
  

  return (
    <>
      {articles && articles.length > 0 ? (
            <div className="Rubrique_items">
              <div className="RI_wrapper block sm:flex">
                <TeaserRubrique article={articles[0]} />
                  <div className="Teaser_grid w-full sm:w-[50%]">
                    <div className="cards flex flex-wrap">
                      {articles.slice(1, articles.length).map((item, index) => (
                        <CardsTextThinTwo data={item} />
                      ))}
                    </div>
                </div>
              </div>
          </div>
      ) : (
        <></>
      )}
    </>
  );
}

export const RubriqueItemsVideo = ({ rubriques }) => {
  return (
    <div className="Rubrique_items">
      <div className="RI_wrapper block sm:flex">
        <TeaserRubriqueVideo />
        <TeaserGridVideo />
      </div>
    </div>
  );
}

export const RubriqueItemsPodcast = ({ rubriques }) => {
  return (
    <div className="Rubrique_items">
      <div className="RI_wrapper block sm:flex">
        <TeaserRubriquePodcast />
        <TeaserGridPodcast />
      </div>
    </div>
  );
}

