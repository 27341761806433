import React, { useEffect, useState } from "react";

import { TeaserSousRubrique } from "../../cards/articleTeaser";
import { getAllWithCondition } from "../../../controllers/AllWithCondition";
import { CardsImgLeft } from "../../cards/cards";

/**
 * 
 * @param {string} sousrubrique 
 * @returns 
 * @description SousRubrique permet de teaser quelques articles d'une sous-rubrique, en fonction de son nom (sousrubrique). Elle fait appel à SousRubriquesItems qui permet de lister les articles de la sous-rubrique.
 */
export const SousRubrique = ({ sousrubrique }) => {
    const [link, setLink] = useState("");

    const [isLoading, setIsLoading] = useState(true);
    const [updatedArticles, setUpdatedArticles] = useState([]);
  
    useEffect(() => {
  
        const rubriqueOptions = "null"
     
        const sousRubriqueOptions = {
            condition: { name: sousrubrique, is_active: true  },
            populate: { path: 'id_file', select: { title: 1, created_at: 1, updated_at: 1, is_active: 1, is_top: 1, is_public: 1, entity: 1, id_entity: 1 } },
            limit: 1,
            skip: 0,
            projection: { name: 1, description: 1, slug: 1, created_at: 1, updated_at: 1, is_active: 1 },
            sort: { created_at: -1 },
        };
    
        const articleOptions = {
            populate: { path: 'id_categories', select: { name: 1, description: 1, slug: 1, is_active: 1, id_parent_categories: 1 } },
            limit: 10,
            skip: 0,
            projection: { title: 1, content: 1, slug: 1, created_at: 1, updated_at: 1, is_active: 1 },
            sort: { created_at: -1 },
        };
    
        const fileOptions = {
            limit: 1,
            skip: 0,
            projection: { title: 1, created_at: 1, updated_at: 1, is_active: 1, is_top: 1, is_public: 1, entity: 1, id_entity: 1 },
            sort: { created_at: -1 },
            with: "articles"
        }
    
        let formData = new FormData();
        formData.append('rubriqueOptions', JSON.stringify(rubriqueOptions));
        formData.append('sousRubriqueOptions', JSON.stringify(sousRubriqueOptions));
        formData.append('articleOptions', JSON.stringify(articleOptions));
        formData.append('fileOptions', JSON.stringify(fileOptions));
    
        getAllWithCondition(formData)
            .then((data) => {                
                if (data) {
            
                    setUpdatedArticles(data.articles);
                    
                    setLink(`/sousrubriques/${data.sousRubriques[0].slug ? data.sousRubriques[0].slug : data.sousRubriques[0].name}`);
                    setIsLoading(false);
                }
            })
    }, []);
  


    return (
        isLoading ? (
        <>
            <p></p>
        </>
        ) : (
        <>
            <div className="PB_sousrubrique mb-16 ">
                <a href={`${link}`} className="PB_sousrubrique_title block relative uppercase font-semibold tracking-normal border-t-4 border-t-gray-200">
                    <h4 className="text-[2rem] py-[2rem] inline-block relative">
                        <span className="max-w-fit block">{sousrubrique}</span>
                    </h4>
                </a>
                <SousRubriqueItems articles={updatedArticles} />
            </div>
        </>
        )
    
    );
}


/***
 * 
 */
export const SousRubriqueItems = ( { articles } ) => {
    return (
        <>
            {articles && articles.length > 0 ? (
                    <div className="Sousrubrique_items">
                        <div className="SI_wrapper block sm:flex">
                            <TeaserSousRubrique articles={articles.slice(0, 3)} />
                            <div className="Teaser_grid w-full sm:w-[60%]">
                                <div className="cards flex flex-wrap">
                                    {articles.slice(3, 7).map((article, index) => (
                                        <CardsImgLeft data={article} />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
            ) : (
                <></>
            )}
        </>
    );
}