import React, { useEffect, useState } from "react";
import { SlideWidgetRubriques, PlusLus } from "../../cards/cards";
import { getAllWithCondition } from "../../../controllers/AllWithCondition";

const PHSlide = () => {

    const [updatedArticles, setUpdatedArticles] = useState([]);
  
  
    useEffect(() => {
        // Récuperer l'url de la page
        const currentUrl = window.location.href;
        let rubrique = null;

        // Split l'url pour récupérer le slug ou le nom de la page
        const splitUrl = currentUrl.split("/");
        if (splitUrl.length > 0) {
            rubrique = splitUrl[splitUrl.length - 1];
        }
        if (rubrique === null || rubrique === "") {
            window.location.href = "/notfound";
        }
  
      const rubriqueOptions = 'null'
   
      const sousRubriqueOptions = 'null'

      const fileOptions = 'null'

  
      const articleOptions = {
            condition: { is_active: true},
            populate: { path: 'id_categories', select: { name: 1, description: 1, slug: 1, is_active: 1, id_parent_categories: 1 } },
            limit: 5,
            skip: 0,
            projection: { title: 1, content: 1, slug: 1, created_at: 1, updated_at: 1, is_active: 1 },
            sort: { created_at: -1 },
      };
  

      let formData = new FormData();
      formData.append('rubriqueOptions', JSON.stringify(rubriqueOptions));
      formData.append('sousRubriqueOptions', JSON.stringify(sousRubriqueOptions));
      formData.append('articleOptions', JSON.stringify(articleOptions));
      formData.append('fileOptions', JSON.stringify(fileOptions));
  
      getAllWithCondition(formData)
          .then((data) => {
              if (data) {
                  setUpdatedArticles(data.articles);
              }
          }
          )
    }, []);

    return (
        <div className="PH_side justify-center flex flex-col md:flex-row items-center mml:flex-col mml:self-start  w-full mml:w-[35%] px-10">
            <SlideWidgetRubriques />
            <PlusLus data={updatedArticles}/>
        </div>
    );
}

export default PHSlide;