import React, { useContext, useEffect, useRef, useState } from 'react';

import { AuthContext } from '../others/AuthContext';

const HeaderTop = () => {
  const [isArticle, setIsArticle] = useState(document.title === 'Article');
  const { authenticated, logout } = useContext(AuthContext);

  const headerRef = useRef(null);

  const handleLogout = () => {
    // Appeler la fonction de logout pour supprimer la session
    logout();
  };

  useEffect(() => {
    const handleScroll = () => {
      const header = headerRef.current;

      if (window.scrollY > 300 && window.innerWidth > 1025) {
        header.classList.add('Scroll');
        if (isArticle) {
          header.querySelector('.Header_center').style.marginLeft = '5.6rem';
        }
      } else if (window.scrollY < 400) {
        header.classList.remove('Scroll');
        if (isArticle) {
          header.querySelector('.Header_center').style.marginLeft = '0rem';
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isArticle]);

  return (
    <>
      <div id="Header" ref={headerRef} className="bg-white fixed lg:relative top-0 z-10 left-0 right-0">
        <div className="Header_lang_switcher hidden uppercase lg:flex h-[4.6rem] justify-center items-center w-full">
          <a href="/" className="mr-[0.8rem] text-[1rem] font-bold no-underline">Français</a>
          <div className="separator mr-[0.8rem] bg-gray-300 h-[35%] w-[0.1rem]"></div>
          <a href="/" className="text-gray-400 text-[1rem] font-bold no-underline">English</a>
        </div>
        <header className="Header max-w-[115rem] h-[6rem] lg:h-[9.6rem] llg:pr-0 lg:pr-[4.8rem] lg:p-0 flex flex-row items-center relative py-[1.5rem] pl-0 pr-[1.2rem] lg:my-0 lg:mx-auto">
          <div className="Header_left w-[5.6rem] lg:w-full llg:pl-0 lg:pl-[4.8rem] pl-0 self-end justify-start">
            <a href="/" className="bea hover:text-black hover:underline hidden lg:flex items-center self-end bg-lr bg-no-repeat bg-contain bg-center flex-col h-[5.4rem] w-[8rem] border-[0.1rem] border-solid border-gray-200 pl-[7rem] no-underline leading-none text-[1.2rem]"> 
              <p className="ml-[9rem] h-[5.4rem] leading-[1.6rem] pt-[1rem]">Consulter<br />Le&nbsp;Journal</p>
            </a>
            <button className="w-[5.6rem] lg:w-0 lg:hidden flex cursor-pointer justify-center border-0">
              <span className="Moible_icon bg-contain bg-no-repeat w-[2.4rem] text-[2rem]">
                <i className="fa-sharp fa-solid fa-bars-staggered w-full h-full"></i>
                <span className="only self-center border-0 h-[1px] w-[1px] p-0 hidden whitespace-nowrap">Navigation</span>
              </span>
            </button>
          </div>
          <div className="Header_center flex self-start">
            <div className="Header_logo self-center pl-0 mb-O">
              <a href="/" className="h-[3rem] w-[13.8rem] lg:h-[6.1rem] lg:w-[29rem] flex items-center justify-center">
                <div className="bea hover:opacity-50 logo_laronde h-full w-full bg-laronde bg-contain bg-center bg-no-repeat inline-block text-black text-[60px] font-extrabold "></div>
                <span className="only h-[1px] hidden p-0 absolute w-[1px]">La Ronde</span>
              </a>
            </div>
          </div>
          <div className="Header_right self-center w-full justify-end pr-0">
            <div className="Header_actions text-[1.4rem] mb-0 lg:mb-[2.8rem] flex justify-end items-center self-center font-bold">
              {authenticated ? (
                <button className="bea bg-yellow-400 text-[#2a303b] pt-[0.9rem] px-[1.6rem] pb-[0.7rem] h-[3rem] items-center border-0 cursor-pointer flex justify-center text-[1.2rem] max-w-full font-semibold hover:bg-yellow-50 hover:border hover:border-yellow-400" onClick={handleLogout}>Déconnexion</button>
              ) : (
                <>
                  <a href="/users/connexion" className="Header_connexion bea pl-2 no-underline flex self-center px-0 hover:border hover:border-black">
                  <span className="icon_connexion self-center w-[2.4rem] h-[2.4rem] bg-center bg-no-repeat bg-contain shrink-0">
                    <i className="fa-sharp fa-solid fa-right-to-bracket"></i>
                    <span className="ip_con only self-center border-0 h-[1px] w-[1px] p-0 hidden whitespace-nowrap">Se connecter</span>
                  </span>
                  <span className="login_info self-center hidden sm:block lg:hidden llg:block ml-[0.4rem]">Se connecter</span>
                  </a>
                  <a href="/users/abonnement" className="icon_premium bg-yellow-400 text-[#2a303b] pt-[0.9rem] px-[1.6rem] pb-[0.7rem] h-[3rem] items-center border-0 cursor-pointer flex justify-center text-[1.2rem] max-w-full font-semibold hover:bg-yellow-50 hover:border hover:border-yellow-400">
                    S'abonner
                    <span className="ip_price hidden md:block">&nbsp;</span>
                  </a>
                </>
              )}
            </div>
          </div>
        </header>
      </div>
    </>
  );
};

export default HeaderTop;
