import React, { useEffect } from "react";

import { Rubrique } from "./rubrique"
import { getAllRubriques } from "../../../controllers/rubriqueControllers";

const Rubriques = () => {
    const [isLoading, setIsLoading] = React.useState(true);
    const [updatedRubriques, setUpdatedRubriques] = React.useState([]);

    useEffect(() => {
        getAllRubriques()
            .then((data) => {
                if (data) {
                    setUpdatedRubriques(data);
                    setIsLoading(false);
                }
            }).finally(() => {
                setIsLoading(false);
            });
    }, []);

    return (
        isLoading ? (
          <>
            <p>* * *</p>
          </>
        ) : (
          updatedRubriques && updatedRubriques.length > 0 ? (
            <>
              {updatedRubriques.map((item, index) => (
                <div className="PB_rubriques my-6" key={index}>
                    {item.is_active && item.id_parent_categories.length === 0 ? (
                        <Rubrique rubrique={item.name} />
                    ) : (
                        <></>
                    )}
                </div>
              ))}
            </>
          ) : (
            <></>
          )
        )
    );
      
}

export default Rubriques;