const models = require('../models/Models');
const Services = require('../services/Services');

const EntityName = 'articles';

export const createArticle = async (formData) => {

  for(let pair of formData.entries()) {
    console.log(pair[0]+ ', '+ pair[1]); 
  }

  try {
    const article = await Services.createEntity(EntityName, formData);
    if (article) {
      return article;
    } else {
      console.log('Erreur lors de la création de l\'article');
      return {
        error: 'Erreur lors de la création de l\'article',
      };
    }
  } catch (error) {
    console.log('Erreur lors de la création de l\'article :', error);
    return {
      error: 'Erreur lors de la création de l\'article',
    };
  }

};

export const deleteArticle = async (idArticle) => {
  try {


    const { article } = await Services.deleteEntity(EntityName, idArticle);
    return article;
  } catch (error) {
    console.log('Erreur lors de la suppression de l\'article :', error);
    return {};
  }

};

export const updateArticle = async (formData) => {
  try {
    const article = await Services.updateEntity(EntityName, formData);

    if (article) {
      return article;
    } else {
      console.log('Erreur lors de la mise à jour de l\'article');
      return {
        error: 'Erreur lors de la mise à jour de l\'article',
      };
    }
  } catch (error) {
    console.log('Erreur lors de la mise à jour de l\'article :', error);
    return {
      error: 'Erreur lors de la mise à jour de l\'article',
    };
  }
};


export const getArticle = async (articleId) => {
  try {
    const article = await Services.getEntity(EntityName, articleId);
    console.log(article)
    if (article && article._id) {
      return article;
    } else {
      console.log('Erreur lors de la récupération de l\'article');
      return {};
    }
  } catch (error) {
      console.log('Erreur lors de la récupération de l\'article :', error);
    return {};
  }
};

export const getLimiteArticle = async (limit, tri) => {
  try {
    const articles = await Services.getLimitedEntities(EntityName, limit, tri);
    if (articles && articles.length > 0) {
      return articles;
    } else {
      console.log('Erreur lors de la récupération des articles');
      return {};
    }
  } catch (error) {
    console.log('Erreur lors de la récupération des articles :', error);
    return {};
  }
};

export const getPageArticles = async ({page, limit, tri}) => {
  try {
    const articles = await Services.getPageEntities(EntityName, page, limit, tri);
    // si article contient un attribut error alors on a une erreur
    if (articles && articles.error) {
      console.log('Erreur lors de la récupération des articles');
      if(articles.error === "Erreur les nombres de page est dépassé"){
        console.log('Erreur les nombres de page est dépassé');
      }
      return {};
    } else if (articles && articles.length > 0) {
      return articles;
    } else {
      console.log('Erreur lors de la récupération des articles');
      return {};
    }
  } catch (error) {
    console.log('Erreur lors de la récupération des articles :', error);
    return {};
  }
};

export const getAllArticles = async() => {
  try{
    const articles = await Services.getAllEntities(EntityName);
    if (articles && articles.length > 0) {
      return articles;
    } else {
      console.log('Erreur lors de la récupération des articles');
      return {};
    }
  }catch(error){
    console.log('Erreur lors de la récupération des articles :', error);
  }
};

export const sendImgArticle = async ( file ) => {
  try {
    // Envoyer les données vers le services
    const response = await Services.sendImgEntity(file);
    // Effectuez le traitement souhaité avec le fichier ici
    if (response) {
      return response;
    }else{
      return {};
    }
  } catch (error) {
    console.error('Une erreur s\'est produite lors du traitement de l\'image', error);
  }
};

export const updateImgArticle = async (files) => {
  try{
    // Envoyer les données vers le services
    const response = await Services.updateImgEntity(files);
    //Effectuez le traitement souhaité avec la response ici
    if(response){
      return response;
    } else {
      return {};
    }
  } catch (error){
    console.error("Une erreur s\'est produite lors de la mise à jour de l'image ", error);
  }
};

export const deleteImgArticle = async ( id, title, p ) => {
  try{
    // Envoyer les données vers le services
    const response = await Services.deleteImgEntity(id, title, p);
    //Effectuez le traitement souhaité avec la response ici
    if(response){
      return response;
    } else {
      return {};
    }
  } catch (error){
    console.error("Une erreur s\'est produite lors de la suppression de l'image ", error);
  }
}

  
  

// Ajoutez d'autres fonctions pour gérer les opérations liées aux articles
// par exemple : updateArticle, deleteArticle, getAllArticles, etc.
