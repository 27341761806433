import React, { useEffect, useState } from "react";

import ImageError from "../../utils/handleImageError";
import { sliceString } from "../../utils/stringUtils";

import { getAllWithCondition } from "../../controllers/AllWithCondition";
import { CardsTextThin, CardsTextThinTwo, CardsTextBold, CardsImgOne, CardsImgTitle, CardsVideo, CardsVideoTwo, CardsAudio, CardsAudioTwo, CardsImgJustTitle, CardsText, CardsImgLeft, CardsHeader, CardsTextThinSec } from "./cards";
import { getImgBy, getImgForAffiche } from "../../controllers/fileController";
import { API_NAME } from "../../utils/constants";

export const ArticleTeaserTop = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [updatedArticle, setUpdatedArticle] = useState({});
    const [updatedFile, setUpdatedFile] = useState({});
    const [linkFile, setLinkFile] = useState("");

    const [updatedArticles, setUpdatedArticles] = useState({});

    const [updatedRubriques, setUpdatedRubriques] = useState({});


    useEffect (() => {
        // Récupérer le dernier article insérer avec is_active = true
        const rubriqueOptions = "null"
        const sousRubriqueOptions = "null"
        const articleOptions = {
            condition: { is_active: true },
            populate: { path: 'id_categories', select: { name: 1, description: 1, slug: 1, is_active: 1, id_parent_categories: 1 } },
            limit: 1,
            skip: 0,
            projection: { title: 1, content: 1, slug: 1, created_at: 1, updated_at: 1, is_active: 1 },
            sort: { created_at: -1 },
        };
        const fileOptions = {
            limit: 1,
            skip: 0,
            projection: { title: 1, created_at: 1, updated_at: 1, is_active: 1, is_top: 1, is_public: 1, entity: 1, id_entity: 1 },
            sort: { created_at: -1 },
            with: "articles"
        }

        let formData = new FormData();
        formData.append('rubriqueOptions', JSON.stringify(rubriqueOptions));
        formData.append('sousRubriqueOptions', JSON.stringify(sousRubriqueOptions));
        formData.append('articleOptions', JSON.stringify(articleOptions));
        formData.append('fileOptions', JSON.stringify(fileOptions));


        getAllWithCondition(formData)
        .then((data) => {
            if (data) {
                if (data.articles && data.articles.length > 0) {
                    if(data.articles[0].id_categories.length > 0){
                        const myRubriques = data.articles[0].id_categories
                        // Parcourir l'objet rubriques
                        myRubriques.forEach((item) => {
                            if(item.id_parent_categories.length > 0) {
                                // Ajouter dans le tableau des rubriques si elle n'y est pas déjà
                                setUpdatedRubriques(item);
                            }
                        });
                    }
                    setUpdatedArticle(data.articles[0]);
                    getImgBy(data.articles[0]._id)
                    .then((data) => {
                      if (data && data.length > 0) {
                        data.forEach((item) => {
                          if (item.is_top) {
                            getImgForAffiche(item.id_entity, item.title)
                            .then((res) => {
                              if (res) {
                                console.log(res);
                                console.log(`${API_NAME}/${res.filePath}`);
                                  setLinkFile(`${API_NAME}/${res.filePath}`);
                                }
                            })
                          }
                        });
                      }
                    })
                    setIsLoading(false);
    
                    const articleOptions = {
                        condition: { is_active: true, id_categories: updatedRubriques._id },
                        populate: { path: 'id_categories', select: { name: 1, description: 1, slug: 1, is_active: 1, id_parent_categories: 1 } },
                        limit: 2,
                        skip: 0,
                        projection: { title: 1, content: 1, slug: 1, created_at: 1, updated_at: 1, is_active: 1 },
                        sort: { created_at: 1 },
                    };
                    const fileOptions = "null"
    
                    let formData = new FormData();
                    formData.append('rubriqueOptions', JSON.stringify(rubriqueOptions));
                    formData.append('sousRubriqueOptions', JSON.stringify(sousRubriqueOptions));
                    formData.append('articleOptions', JSON.stringify(articleOptions));
                    formData.append('fileOptions', JSON.stringify(fileOptions));
    
                    getAllWithCondition(formData)
                    .then((data) => {
                        if (data) {
                            setUpdatedArticles(data.articles);
                            setIsLoading(false);
                        }
                    })
                }
            }

        })

        
    }, []);
    
    
    return (
        isLoading ? (
            <></>
        ) : (
            <div className="Article_teaser mb-[30px] block">
                <a href={`/articles/${updatedArticle.slug ? updatedArticle.slug : updatedArticle.title}`} className="no-underline">
                    <div className="Teaser_title text-[34px] font-bold tracking-normal leading-none text-black">{updatedArticle.title}</div>
                    <div className="Tisear_image my-[10px] mx-0 overflow-hidden max-w-[640px] max-h-[320px] flex justify-center items-center">
                        <img src={linkFile} alt="" onError={ImageError} width="640" height="320"/>                                
                    </div>
                    <span className="Tisear_tag text-[18px]">
                        <span className="legend font-extrabold uppercase mr-4">{updatedRubriques.name}</span>
                        {updatedArticle.is_premium ? (
                            <span className="badge before:py-[2px] before:content-['LR'] border border-solid border-gray-300">&nbsp;Abonné&nbsp;</span>
                        ) : (
                            <></>
                        )}
                    </span>
                    <p className="Teaser_content text-[18px] leading-tight my-4">{sliceString(updatedArticle.content, 0, 300)}</p>
                </a>
                <div className="Teaser_list flex justify-start items-start text-2xl">
                    {updatedArticles && updatedArticles.length > 0 ? (
                        <>
                            {updatedArticles.map((item, index) => (
                                <CardsTextThin data={item} key={index} />
                            ))}
                        </>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        )
    );
}

export const ArticleTeaserMiddle = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [updatedArticles, setUpdatedArticles] = useState({});
    const [updatedFiles, setUpdatedFiles] = useState({});

    useEffect (() => {
        // Récupérer le dernier article insérer avec is_active = true
        const rubriqueOptions = "null"
        const sousRubriqueOptions = "null"
        const articleOptions = {
            condition: { is_active: true },
            populate: { path: 'id_categories', select: { name: 1, description: 1, slug: 1, is_active: 1, id_parent_categories: 1 } },
            limit: 3,
            skip: 0,
            projection: { title: 1, content: 1, slug: 1, created_at: 1, updated_at: 1, is_active: 1 },
            sort: { created_at: -1 },
        };
        const fileOptions = {
            limit: 1,
            skip: 0,
            projection: { title: 1, created_at: 1, updated_at: 1, is_active: 1, is_top: 1, is_public: 1, entity: 1, id_entity: 1 },
            sort: { created_at: -1 },
            with: "articles"
        }

        let formData = new FormData();
        formData.append('rubriqueOptions', JSON.stringify(rubriqueOptions));
        formData.append('sousRubriqueOptions', JSON.stringify(sousRubriqueOptions));
        formData.append('articleOptions', JSON.stringify(articleOptions));
        formData.append('fileOptions', JSON.stringify(fileOptions));


        getAllWithCondition(formData)
        .then((data) => {
            if (data) {
                setUpdatedArticles(data.articles);
                setUpdatedFiles(data.files);
                setIsLoading(false);
            }

        }).finally(() => {
            setIsLoading(false);
        });

        
    }, []);

    return (
        <>
            {isLoading ? (
                <></>
            ) : (
                <>
                    {updatedArticles && updatedArticles.length > 0 ? (
                        <div className="Articles_teaser border-t-4 border-solid border-gray-300">
                            <div className="cards pt-[30px] flex flex-wrap">
                                {updatedArticles.slice(1, 3).map((item, index) => (
                                    <CardsImgOne data={item} file={updatedFiles.filter((file) => file.id_entity === item._id)}  key={index} />
                                ))}
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                </>
            )}
        </>
    );
}

export const ArticleTeaserBottom = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [updatedArticles, setUpdatedArticles] = useState({});

    useEffect (() => {
        // Récupérer le dernier article insérer avec is_active = true
        const rubriqueOptions = "null"
        const sousRubriqueOptions = "null"
        const articleOptions = {
            condition: { is_active: true },
            populate: { path: 'id_categories', select: { name: 1, description: 1, slug: 1, is_active: 1, id_parent_categories: 1 } },
            limit: 7,
            skip: 0,
            projection: { title: 1, content: 1, slug: 1, created_at: 1, updated_at: 1, is_active: 1 },
            sort: { created_at: -1 },
        };
        const fileOptions = "null"

        let formData = new FormData();
        formData.append('rubriqueOptions', JSON.stringify(rubriqueOptions));
        formData.append('sousRubriqueOptions', JSON.stringify(sousRubriqueOptions));
        formData.append('articleOptions', JSON.stringify(articleOptions));
        formData.append('fileOptions', JSON.stringify(fileOptions));


        getAllWithCondition(formData)
        .then((data) => {
            if (data) {
                setUpdatedArticles(data.articles);
                setIsLoading(false);
            }

        })  
    }, []);

    return (

        <>
            {isLoading ? (
                <></>
            ) : (
                <>
                    {updatedArticles && updatedArticles.length > 0 ? (
                        <div className="Articles_teaser border-t-4 border-solid border-gray-300">
                            <div className="Articles_teaser_sec">
                                {updatedArticles.slice(3, 7).map((item) => (
                                    <CardsTextBold data={item} />
                                ))}
                            </div>
                        </div>   
                    ) : (
                        <></>
                    )}
                </>
            )}
        </>
        
    );
}

export const TeaserRubrique = ({article}) => {
    return (
        <div className="Teaser_rubrique w-full sm:w-[50%]">
            <div className="card w-full relative inline-block px-[15px] mb-[30px]">
                <CardsImgTitle data={article} />
            </div>
        </div>
    );
} 

export const TeaserGrid = ({articles}) => {
    return (
        <div className="Teaser_grid w-full sm:w-[50%]">
            <div className="cards flex flex-wrap">
                <CardsTextThinTwo />
                <CardsTextThinTwo />
                <CardsTextThinTwo />
                <CardsTextThinTwo />
            </div>
        </div>
    );
}

export const TeaserSousRubrique = ({articles}) => {
    return (
        articles && articles.length > 0 ? (
            <div className="Teaser_rubrique w-full sm:w-[40%]">
            <div className="cards flex flex-wrap">
                <CardsImgJustTitle data={articles[0]} />
                {articles.slice(1, 3).map((article, index) => (
                    <CardsText data={article} key={index} />
                ))}
            </div>
        </div>
        ) : (
            <></>
        )
    );
}

export const TeaserGridSousRubrique = ({articles}) => {
    return (
        <div className="Teaser_grid w-full sm:w-[60%]">
            <div className="cards flex flex-wrap">
                <CardsImgLeft />
                <CardsImgLeft />
                <CardsImgLeft />
            </div>
        </div>
    );
}

export const TeaserRubriqueVideo = ({article}) => {
    return (
        <div className="Teaser_rubrique w-full sm:w-[50%]">
            <div className="card w-full relative inline-block px-[15px] mb-[30px]">
                <CardsVideo data={article} />
            </div>
        </div>
    );
} 

export const TeaserGridVideo = ({articles}) => {
    return (
        <div className="Teaser_grid w-full sm:w-[50%]">
            <div className="cards flex flex-wrap">
                <CardsVideoTwo />
                <CardsVideoTwo />
                <CardsVideoTwo />
            </div>
        </div>
    );
}

export const TeaserRubriquePodcast = ({article}) => {
    return (
        <div className="Teaser_rubrique w-full sm:w-[50%]">
            <div className="card w-full relative inline-block px-[15px] mb-[30px]">
                <CardsAudio data={article} />
            </div>
        </div>
    );
} 

export const TeaserGridPodcast = ({articles}) => {
    return (
        <div className="Teaser_grid w-full sm:w-[50%]">
            <div className="cards flex flex-wrap">
                <CardsAudioTwo />
                <CardsAudioTwo />
                <CardsAudioTwo />
            </div>
        </div>
    );
}

export const TeaserRubriqueHeader = ({articles}) => {
    return (
        articles ? (
            <div className="Articles_teaser">
                <div className="cards pt-[30px] flex flex-wrap">
                   {articles.map((article, index) => (
                        <>
                            {article.is_active ? (
                                <CardsHeader data={article} />
                            ) : (
                                <></>
                            )}   
                        </>
                    ))}
                </div>
            </div>
        ) : (
            <></>
        )
    );
}

export const TeaserRubriqueHeaderSec = ({ articles }) => {
    return (
        articles ? (
            <div className="Articles_teaser_sec">
            <div className="cards pt-[30px] flex flex-wrap">
                {articles.map((article, index) => (
                    <>
                                    {article.is_active ? (
                        <CardsTextThinSec data={article} />
                    ) : (
                        <></>
                    )}
                    </>
                ))}
            </div>
        </div>
        ) : (
            <></>
        )
    );
}
