const models = require('../models/Models');
const Services = require('../services/Services');



// Contrôleur pour envoyer des requêtes à la base de données avec des conditions,
// des options de tri, de pagination et de projection, et retourner les données,
// le nombre total de documents et le nombre total de documents filtrés
// pour toutes les entités
// En paramètre, il faut envoyer un objet avec les propriétés suivantes:
// - entityName: le nom de l'entité
// - condition: la condition de la requête
// - sort: le tri de la requête
// - limit: la limite de la requête
// - skip: le nombre de documents à sauter pour la requête
// - projection: la projection de la requête
// - populate: le populate de la requête

export const getAllWithCondition = async (formDataCondition) => {
    try {

    
        // Récupérer les données
        const data = await Services.getAllWithCondition(formDataCondition);
    
        // Retourner les données, le nombre total de documents et le nombre total de documents filtrés
        return data
    } catch (error) {
        console.log('Erreur lors de la récupération des données :', error);
        return {
            error: 'Erreur lors de la récupération des données',
        };
    }
}
