const models = require('../models/Models');
const Services = require('../services/Services');

const EntityName = 'users/admins';

export const createAdminer = async (formData) => {
  try {
    const adminer = await Services.createEntity(EntityName, formData);
    if (adminer) {
      return adminer;
    } else {
      console.log('Erreur lors de la création de l\'adminer');
      return {
        error: 'Erreur lors de la création de l\'adminer',
      };
    }
  } catch (error) {
    console.log('Erreur lors de la création de l\'adminer :', error);
    return {
      error: 'Erreur lors de la création de l\'adminer',
    };
  }

};

export const deleteAdminer = async (idAdminer) => {
  try {
    const { success } = await Services.deleteEntity(EntityName, idAdminer);
    return success;
  } catch (error) {
    console.log('Erreur lors de la suppression de l\'adminer :', error);
    return { 
      error: 'Erreur lors de la suppression de l\'adminer',
    };
  }

};

export const togleActiveAdminer = async (idAdminer) => {
  try {
    const data = await Services.togleActiveAdminer(EntityName, idAdminer);
    return data;
  } catch (error) {
    console.log('Erreur lors du changement d\'etat de l\'adminer :', error);
    return {
      error: "Erreur administration"
    };
  }
}

export const updateAdminer = async (formData) => {
  try {

    
    const adminer = await Services.updateEntity(EntityName, formData);

    if (adminer) {
      return adminer;
    } else {
      console.log('Erreur lors de la mise à jour de l\'adminer');
      return {
        error: 'Erreur lors de la mise à jour de l\'adminer',
      };
    }
  } catch (error) {
    console.log('Erreur lors de la mise à jour de l\'adminer :', error);
    return {
      error: 'Erreur lors de la mise à jour de l\'adminer',
    };
  }
};


export const getAdminer = async (adminerId) => {
  try {
    const adminer = await Services.getEntity(EntityName, adminerId);
    if (adminer && adminer._id) {
      return adminer;
    } else {
      console.log('Erreur lors de la récupération de l\'adminer');
      return {};
    }
  } catch (error) {
      console.log('Erreur lors de la récupération de l\'adminer :', error);
    return {};
  }
};

export const getLimiteAdminer = async (limit, tri) => {
  try {
    const adminers = await Services.getLimitedEntities(EntityName, limit, tri);
    if (adminers && adminers.length > 0) {
      return adminers;
    } else {
      console.log('Erreur lors de la récupération des adminers');
      return {};
    }
  } catch (error) {
    console.log('Erreur lors de la récupération des adminers :', error);
    return {};
  }
};

export const getPageAdminers = async ({page, limit, tri}) => {
  try {
    const adminers = await Services.getPageEntities(EntityName, page, limit, tri);
    // si adminer contient un attribut error alors on a une erreur
    if (adminers && adminers.error) {
      console.log('Erreur lors de la récupération des adminers');
      if(adminers.error === "Erreur les nombres de page est dépassé"){
        console.log('Erreur les nombres de page est dépassé');
      }
      return {};
    } else if (adminers && adminers.length > 0) {
      return adminers;
    } else {
      console.log('Erreur lors de la récupération des adminers');
      return {};
    }
  } catch (error) {
    console.log('Erreur lors de la récupération des adminers :', error);
    return {};
  }
};

export const getAllAdminers = async() => {
  try{
    const adminers = await Services.getAllEntities(EntityName);
    if (adminers) {
      return adminers;
    } else {
      console.log('Erreur lors de la récupération des adminers');
      return {};
    }
  }catch(error){
    console.log('Erreur lors de la récupération des adminers :', error);
  }
};

export const sendImgAdminer = async ( file ) => {
  try {
    // Envoyer les données vers le services
    const response = await Services.sendImgEntity(file);
    // Effectuez le traitement souhaité avec le fichier ici
    if (response) {
      return response;
    }else{
      return {};
    }
  } catch (error) {
    console.error('Une erreur s\'est produite lors du traitement de l\'image', error);
  }
};

export const updateImgAdminer = async (files) => {
  try{
    // Envoyer les données vers le services
    const response = await Services.updateImgEntity(files);
    //Effectuez le traitement souhaité avec la response ici
    if(response){
      return response;
    } else {
      return {};
    }
  } catch (error){
    console.error("Une erreur s\'est produite lors de la mise à jour de l'image ", error);
  }
};

export const deleteImgAdminer = async ( id, title, p ) => {
  try{
    // Envoyer les données vers le services
    const response = await Services.deleteImgEntity(id, title, p);
    //Effectuez le traitement souhaité avec la response ici
    if(response){
      return response;
    } else {
      return {};
    }
  } catch (error){
    console.error("Une erreur s\'est produite lors de la suppression de l'image ", error);
  }
}

  

  

// Ajoutez d'autres fonctions pour gérer les opérations liées aux adminers
// par exemple : updateAdminer, deleteAdminer, getAllAdminers, etc.
