import React, { useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";


export const Input = ({ label, value, name, type, onChange, active}) => {
  return (
    <div className="flex flex-col mb-4">
      <label
        className="mb-2 uppercase font-bold text-lg text-grey-darkest"
        htmlFor={name}
      >
        {label}
      </label>
      <input
        className="border py-2 px-3 text-grey-darkest"
        type={type}
        name={name}
        id={name}
        value={value}
        onChange={onChange}
        disabled={active}
      />
    </div>
  );
};

export const Textarea = ({ label, value, name, onChange }) => {
  return (
    <div className="flex flex-col mb-4">
      <label
        className="mb-2 uppercase font-bold text-lg text-grey-darkest"
        htmlFor={name}
      >
        {label}
      </label>
      <textarea
        className="border py-2 px-3 text-grey-darkest"
        name={name}
        id={name}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export const Select = ({ label, value, name, options, onChange }) => {
  return (
    <div className="flex flex-col mb-4">
      <label className="mb-2 uppercase font-bold text-lg text-grey-darkest" htmlFor={name}>
        {label}
      </label>
      <select
        className="select_scroll border py-2 px-3 text-grey-darkest h-[200px] overflow-auto"
        name={name}
        id={name}
        onChange={onChange}
        value={value}
        multiple
      >
        {options.map((option, index) => (
          <option key={index} value={option._id} 
          className={` bea text-[1.3rem] p-4 cursor-pointer ${
            value === option._id ? ' bg-gray-500 text-white ' : ''
          }`}>
            {option.name}
          </option>
        ))}
      </select>
    </div>
  );
};


export const SelectCategories = ({ categories, selectedCategories, name, onChangeAllRubriques }) => {

  return (
    <div className="flex flex-col mb-4">
      <label className="mb-2 uppercase font-bold text-lg text-grey-darkest">
        Catégories
      </label>
      <select
        className="select_scroll border py-2 px-3 text-grey-darkest h-[300px] overflow-auto"
        name={name}
        id={name}
        onChange={onChangeAllRubriques}
        value={selectedCategories}
        multiple
      >
        {categories.map((category, index) => (
          <React.Fragment key={index}>
            <option 
              value={category.id}
              className={`bea text-[1.3rem] p-2 cursor-pointer font-bold uppercase ${
                selectedCategories === category._id ? ' bg-gray-500 text-white ' : ''
              }`}>
              {category.name}
            </option>
            {category.children_categories.map((subOption, subIndex) => (
              <option 
              key={subIndex} 
              value={subOption._id}
              className={`bea text-[1rem] p-2 pl-12 cursor-pointer ${
                selectedCategories === subOption._id ? ' bg-gray-500 text-white ' : ''
              }`}>
                {subOption.name}
              </option>
            ))}
          </React.Fragment>
        ))}
      </select>
    </div>
  );
};


export const Checkbox = ({ label, value, name, onChange }) => {
  return (
    <div className="flex flex-col mb-4">
      <label
        className="mb-2 uppercase font-bold text-lg text-grey-darkest"
        htmlFor={name}
      >
        {label}
      </label>
      <input
        className="border py-2 px-3 text-grey-darkest"
        type="checkbox"
        name={name}
        id={name}
        defaultChecked={value === true}
        defaultValue={value}
        onChange={onChange}
      />
    </div>
  );
};

export const Radio = ({ label, value, name, options, onChange }) => {
  return (
    <>
      <label
        className="mb-2 uppercase font-bold text-lg text-grey-darkest"
        htmlFor={name}
      >
        {label}
      </label>
      <div className="flex flex-row mb-4">
        {options.map((option, index) => {
          const checkedInput = option === "true" ? true : false;
          return (
            <div key={index} className="mr-4">
              <input
                className="border py-2 px-3 text-grey-darkest"
                type="radio"
                name={name}
                id={`${name}_${index}`}
                value={option}
                defaultChecked={checkedInput === value}
                onChange={onChange}
              />
              <label className="px-3 text-grey-darkest" htmlFor={`${name}_${index}`}>
                {option}
              </label>
            </div>
          );
        })}
      </div>
    </>
  );
};


export const MyEditor = ({ content, onChange }) => {
  const editorRef = useRef(null);
  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };

  const getEditorContent = () => {
    if (editorRef.current) {
      return editorRef.current.getContent( { format: 'html' } );
    }
    return '';
  };

  return (
    <>
      <Editor
        apiKey="69we6qw0qrnvvvtn0lfxzzym84x727ndtoeme8y4ykz6cghu"
        onInit={(evt, editor) => (editorRef.current = editor)}
        initialValue={content}
        init={{
          height: 500,
          menu: {
            tools: { title: "Tools", items: "code wordcount typography" },
          },
          plugins: [
            "advlist",
            "anchor",
            "autolink",
            "charmap",
            "code",
            "fullscreen",
            "help",
            "image",
            "insertdatetime",
            "link",
            "lists",
            "media",
            "preview",
            "searchreplace",
            "table",
            "visualblocks",
            "advtemplate",
            "searchreplace",
          ],
          toolbar:
            "inserttemplate undo redo | styles | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image" |
            "searchreplace",
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:16px }",
          typography_default_lang: "en-US",
          typography_langs: ["en-US", "es", "de"],
          typography_rules: [
            "common/punctuation/quote",
            "en-US/dash/main",
            "common/nbsp/afterParagraphMark",
            "common/nbsp/afterSectionMark",
            "common/nbsp/afterShortWord",
            "common/nbsp/beforeShortLastNumber",
            "common/nbsp/beforeShortLastWord",
            "common/nbsp/dpi",
            "common/punctuation/apostrophe",
            "common/space/delBeforePunctuation",
            "common/space/afterComma",
            "common/space/afterColon",
            "common/space/afterExclamationMark",
            "common/space/afterQuestionMark",
            "common/space/afterSemicolon",
            "common/space/beforeBracket",
            "common/space/bracket",
            "common/space/delBeforeDot",
            "common/space/squareBracket",
            "common/number/mathSigns",
            "common/number/times",
            "common/number/fraction",
            "common/symbols/arrow",
            "common/symbols/cf",
            "common/symbols/copy",
            "common/punctuation/delDoublePunctuation",
            "common/punctuation/hellip",
          ],
          typography_ignore: ["code"],
        }}
        onEditorChange={onChange}
      />
      {/* <button onClick={log} className="my-8 p-4 uppercase border border-yellow-400">Log editor content</button> */}
    </>
  );
};
