import React from 'react';
import { Route, Routes } from 'react-router-dom';
import {Erreur, RubriqueViews, RubriquesList} from '../views/RubriqueViews.js';



const RubriquesRoute = () => {
  return (
    <Routes>
        <Route exact path="/Erreur" element={<Erreur />} />
        <Route exact path="/*" element={<RubriqueViews />} />
        <Route exact path="/" element={<RubriquesList />} />
    </Routes>
  );
};

export default RubriquesRoute;
